import { useSelector } from "./redux";
import { useDispatch } from "react-redux";
import { getL1Images } from "@redux-actions/";
import { useEffect, useRef } from "react";

const useFetchL1 = (l1Ids: string[]) => {
  const L1Images: any[] = useSelector((state) => state.cms.L1Images);
  const isLoadingL1Image = useSelector((state) => state.cms.isLoadingL1Image);
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)
  const dispatch = useDispatch();
  const loadedIdsRef = useRef<Set<string>>(new Set());

  useEffect(() => {
    if (!isLoadingL1Image && isAuthenticated) {
      const channels = l1Ids?.filter(
        (id) => !L1Images[id]?.img && !loadedIdsRef?.current?.has(id)
      );

      if (channels?.length > 0) {
        channels.forEach((id) => loadedIdsRef?.current?.add(id));
        dispatch(getL1Images(channels));
      }
    }
  }, [l1Ids, isLoadingL1Image, isAuthenticated, L1Images, dispatch]);
};

export default useFetchL1;
