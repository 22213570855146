import React, { useEffect, useState, useMemo } from 'react';

// https://github.com/streamich/react-use/blob/master/docs/createBreakpoint.md

export enum defaultBreakpoints {
  desktop = 'desktop',
  tablet = 'tablet',
  phone = 'phone',
}

const createBreakpoint =
  (breakpoints: { [name: string]: number } = { laptopL: 1440, laptop: 1024, tablet: 768, XS: 0 }) =>
    () => {
      const [screen, setScreen] = useState(0);

      useEffect(() => {
        const setSideScreen = (): void => {
          setScreen(window.innerWidth);
        };
        setSideScreen();
        window.addEventListener('resize', setSideScreen);
        return () => {
          window.removeEventListener('resize', setSideScreen);
        };
      });
      const sortedBreakpoints = useMemo(
        () => Object.entries(breakpoints).sort((a, b) => (a[1] >= b[1] ? 1 : -1)),
        [breakpoints]
      );
      const result = sortedBreakpoints.reduce((acc, [name, width]) => {
        if(screen > 0) {
          if (screen >= width) {
            return name;
          } else {
            return acc;
          }
        } 
        
      }, sortedBreakpoints[0][0]);

      const isPortrait = window.matchMedia('(orientation: portrait)').matches;
      switch (result) {
        case 'L':
          return defaultBreakpoints.desktop;
        case 'M':
          return defaultBreakpoints.tablet;
        case 'S':
          return isPortrait ? defaultBreakpoints.tablet : defaultBreakpoints.phone;
        case 'XS':
          return defaultBreakpoints.phone;
        default:
          return defaultBreakpoints.desktop
      }
    };

export const useBreakpoint = createBreakpoint({ L: 1200, M: 920, S: 768, XS: 0 });

export type TUseBreakpointBooleanReturn ={
  isDesktop: boolean,
  isDesktopOrTabletLandscape: boolean,
  isTabletPortrait: boolean,
  isTablet: boolean,
  isPhoneOrTabletPortrait: boolean,
  isPhone: boolean,
  isPortrait: boolean,
  heightHeader: number
}

export const useBreakpointBoolean = (): TUseBreakpointBooleanReturn => {
  const breakpoint = useBreakpoint();
  const isPortrait = window.matchMedia('(orientation: portrait)').matches;

  // css variable defined into _variables.css
  const heightHeader   = parseInt(getComputedStyle(document.body).getPropertyValue('--scroll-margin-top'));

  return {
    isDesktop: breakpoint === defaultBreakpoints.desktop,
    isDesktopOrTabletLandscape:
        breakpoint === defaultBreakpoints.desktop ||
        (!isPortrait && breakpoint === defaultBreakpoints.tablet),
    isTablet: breakpoint === defaultBreakpoints.tablet,
    isTabletPortrait: (isPortrait && breakpoint === defaultBreakpoints.tablet),
    isPhoneOrTabletPortrait:
        breakpoint === defaultBreakpoints.phone ||
        (isPortrait && breakpoint === defaultBreakpoints.tablet),
    isPhone: breakpoint === defaultBreakpoints.phone,
    isPortrait: isPortrait,
    heightHeader: heightHeader
  };
};
export const useBreakpointBooleanRef = () => {
  const breakpoint = useBreakpoint();
  const [breakpointState, _setBreakpointState] = useState(null);
  const breakpointRef = React.useRef<{
    isDesktop: boolean;
    isTablet: boolean;
    isPhone: boolean;
  }>(breakpointState);

  const setBreakpoint = data => {
    breakpointRef.current = data;
    _setBreakpointState(data);
  };

  useEffect(() => {
    const breakpointTemp = {
      isDesktop: breakpoint === defaultBreakpoints.desktop,
      isTablet: breakpoint === defaultBreakpoints.tablet,
      isPhone: breakpoint === defaultBreakpoints.phone,
    };
    setBreakpoint(breakpointTemp);
  }, [breakpoint]);

  return breakpointRef;
};
