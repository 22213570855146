import React, { Component } from 'react';
import {
  isLearningPath,
  isVirtualClassroom,
  isVideoOrPodcast,
  printDurationHourFormat,
  printHTML,
  getCourseCompatibility,
  scrollCourseDetailToTop,
  getSubtitlesCodes,
  isMultiActivity,
  isLivestreamCourse,
  getCoursesWithinLP,
  scrollToSessions,
  isPoweredByEcpu,
  isRecognizedByABO,
  getParentCourse,
  isOnboardingRelatedMandatory,
  isCourseVisible,
  isNewLivestream,
} from '../../utility/Api';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import DisplayTopDesk from './DisplayTopDesk';
import DisplayTopMobile from './DisplayTopMobile';
import Modal from '../UI/Modal';
import { withTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import Header from '@components/Header';
import RecommendedSection from './RecommendedSection';
import ParentCoursesCarousel from './ParentCoursesCarousel';
import DetailCourseActivities from './DetailCourseActivities';
import AnimationSlide, { animationSlideToTop } from '@components/animations/AnimationSlide';
import { USER_URLS } from '@components/link-utils';
import { HEADER_LEVEL, MODAL_TYPES, queryParams } from '@utility/const';
import { getBlockingLps, isLpBlocked } from '@utility/LPUtility';
import {
  canCourseBeAccessed,
  isPriceable,
} from '@utility/ecommerceUtility';
import HelmetMetaTags from '@components/HelmetMetaTags';
import { getLevelLabel } from '@utility/levelUtility';
import { sendCourseOverviewUtagData } from '@utility/analytics-utils';
import CardActivitySkeleton from '@components/skeletons/CardActivitySkeleton';
import { checkUpcomingOrPastEvent } from '@hooks/useUpcomingEvents';
import Footer from '@components/Footer';
import RelatedMandatoryCarousel from './RelatedMandatoryCarousel';
import ModalContentLanguage from '@components/UI/Modals/ContentLanguage/ModalContentLanguage';
import { isCourseWithVersioning } from '@utility/CourseUtility';
import { GET_COUNTRY_LANGUAGES_MAPPING } from '../../config.js';
import axios from 'axios';
import { getHeaders } from '@utility/Api';

class CourseOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowSummary: false,
      language: props.currentCourse?.languageToPrint,
      languagesOptions: [],
      subtitlesOptions: [],
      comingSoonLangOptions: [],
      selectedSubtitle: null,
      childCourse: null,
      lpCourses: [],
      onboardingRMCourses: [],
      liveLang: '',
      courseParentDetail: {},
      // CONTENT LANGUAGE
      isLoadingCountryLanguagesMapping: false,
      isLoadedCountryLanguagesMapping: null,
      countryLanguageMapping: null,
      defaultSelection: null,
      chipsMap: null,
      // setContentChips: null,
    };
  }

  componentDidMount = () => {

    const {
      currentCourse,
      isLoadingDetails,
      getCourseDetails,
      languages,
      coursesMap,
      getCourseSummary
    } = this.props;

    if (currentCourse && !currentCourse.isStatic) {

      // IF CONTENT LANGUAGE
      if(!isVirtualClassroom(currentCourse) && isCourseWithVersioning(coursesMap,currentCourse?.courseIdMaster)) {
        if(!this.state.isLoadingCountryLanguagesMapping && !this.state.isLoadedCountryLanguagesMapping) {
          this.fetchCountryLanguagesMapping(currentCourse?.courseIdMaster);
        }
      }

      if (
        canCourseBeAccessed(currentCourse) &&
        !isLearningPath(currentCourse) &&
        !isLoadingDetails[currentCourse.courseIdMaster]
      ) {
        getCourseDetails(currentCourse);
      }

      if (currentCourse.totalLanguages && languages) {
        this.setLanguagesOptions();
      }
      if (currentCourse.courseIdMaster && coursesMap) {
        this.setCourseParentDetail();
      }
      if (currentCourse.comingSoonLanguages && languages) {
        this.setComingSoonLanguagesOptions();
      }
      if (currentCourse.tags && languages) {
        this.setSubtitlesOptions(true);
      }
      if (isLearningPath(currentCourse)) {
        this.getCoursesWithinLP();
      }
      if (isOnboardingRelatedMandatory(this.props.currentCourse)) {
        this.getOnboardingRMCourses();
      }
     // console.log("**** iS LOADING?", this.props.isLoadingGetUserRelevantSessionById)
      if((isVirtualClassroom(currentCourse) || isLearningPath(currentCourse) || isMultiActivity(currentCourse)) && !isLoadingGetUserRelevantSessionById){
        //console.log("**** COURSE OVERVIEW IS CALLING GETUSERRELEVANTSESSIONS", currentCourse.courseId)
        this.getUserRelevantSessionsCourse(currentCourse.courseId);
      }
      //get course summary
      if(currentCourse) getCourseSummary([currentCourse], coursesMap)
    }
  };

  componentDidUpdate = (prevProps, prevState) => {

    const {
      currentCourse,
      isLoadingGetUserRelevantSessionById,
      isLoadingDetails,
      getCourseDetails,
      userProfile,
      getUserRelevantSessionsCourse,
      labelL1Map,
      typeModal,
      isUserCompletedAnalytics,
      analyticsCourseId,
      setAnalyticsCourseId,
      coursesMap,
      showIframe
    } = this.props;

    if (currentCourse && !currentCourse.isStatic) {

      if(prevProps.currentCourse !== currentCourse && prevState.isLoadedCountryLanguagesMapping !== null) {
        this.setIsLoadedCountryLanguagesMapping(false);
      }
      // HANDLE RE-FETCH ON IFRAME CLOSE
      if(isCourseWithVersioning(coursesMap,currentCourse?.courseIdMaster)) {
        if(!this.state.isLoadingCountryLanguagesMapping && 
          ((prevProps.showIframe && !showIframe) 
          || (!this.state.isLoadedCountryLanguagesMapping && prevState.isLoadedCountryLanguagesMapping))
        ) {
          this.fetchCountryLanguagesMapping(currentCourse?.courseIdMaster);
        }
      }

      if (prevProps.currentCourse !== currentCourse || prevProps.isLoadingGetUserRelevantSessionById !== isLoadingGetUserRelevantSessionById) {
        if (prevProps.currentCourse?.courseIdMaster !== currentCourse.courseIdMaster) {
          if (canCourseBeAccessed(currentCourse) &&
            !isLearningPath(currentCourse) &&
            !isLoadingDetails[currentCourse.courseIdMaster]
            // !isCourseWithVersioning(coursesMap,currentCourse?.courseIdMaster) //prevent unnecessary call when course is with versioning
          ) {
            getCourseDetails(currentCourse);
          }
          if (((isVirtualClassroom(currentCourse) || isLearningPath(currentCourse) || isMultiActivity(currentCourse))
            && !isLoadingGetUserRelevantSessionById
          ) || userProfile?.preferredLang !== prevProps.userProfile?.preferredLang) {
            getUserRelevantSessionsCourse(currentCourse.courseId)
          }
          //scroll to top
          scrollCourseDetailToTop('auto');

          this.setCourseParentDetail();
          sessionStorage.setItem('newCourseIdMaster', this.props.currentCourse.courseIdMaster)
        }

        // IF CONTENT LANGUAGE
        // FIRST COUNTRY LANGUAGE CALL
        if(isCourseWithVersioning(coursesMap,currentCourse?.courseIdMaster)) {
          if(!this.state.isLoadingCountryLanguagesMapping && 
            (!this.state.isLoadedCountryLanguagesMapping && prevState.isLoadedCountryLanguagesMapping === null)
          ) {
            this.fetchCountryLanguagesMapping(currentCourse?.courseIdMaster);
          }
        }
        // IF not content language leave as-is in setting language
        if(!isCourseWithVersioning(coursesMap,currentCourse?.courseIdMaster)) {
          this.setLanguagesOptions();
        }

        this.setComingSoonLanguagesOptions();
        this.setSubtitlesOptions(prevProps.currentCourse?.courseId !== currentCourse.courseId);
        this.setLiveLang();
        
        if (this.props.scrollToCurrentCourseSessions) {
          setTimeout(() => {
            const hasScrolled = scrollToSessions(true);
            if (hasScrolled) {
              this.props.resetScrollToCurrentCourseSessions();
            }
          }, 100);
        }
      }

      if (userProfile?.preferredLang !== prevProps.userProfile.preferredLang) {
        let foundMatch = false;
        this.props.currentCourse.childCourses.forEach((child) => {

          if (child.language === this.props.userProfile?.preferredLang) {
            this.props.setChildCourse(child.languageToPrint);
            foundMatch = true;
            return;
          }

        });

        // If no match is found: look for english child, if no english child use courseParentDetail as last fallback
        if (!foundMatch) {
          const enFallback = currentCourse?.childCourses.find(child => child.language === "en")
          if (enFallback) {
            this.props.setChildCourse(enFallback.languageToPrint);
          } else {
            this.state.courseParentDetail.languageToPrint && this.props.setChildCourse(this.state.courseParentDetail.languageToPrint);
          }
        }
      }

      if (Object?.keys(labelL1Map)?.length > 1 && typeModal !== MODAL_TYPES.notificationsRightModal) {
        if (
          userProfile &&
          isUserCompletedAnalytics &&
          Object.keys(labelL1Map).length > 1
        ) {
          if (analyticsCourseId !== currentCourse?.courseId) {
            this.sendCourseOverviewUtagData();
            setAnalyticsCourseId(currentCourse?.courseId)
          }
        }
      }

      if ((prevProps.currentCourse !== currentCourse ||  prevProps.coursesMap !== coursesMap) &&
        isLearningPath(currentCourse)
      ) {
        this.getCoursesWithinLP();
      }

      if ((prevProps.currentCourse !== currentCourse || prevProps.coursesMap !== coursesMap) &&
        isOnboardingRelatedMandatory(currentCourse)
      ) {
        this.getOnboardingRMCourses();
      }
    }
  };

  sendCourseOverviewUtagData = () => {
    sendCourseOverviewUtagData(
      this.props.currentCourse,
      this.props.userProfile,
      'overlay',
      this.props.labelL1Map
    );
  }

  getCoursesWithinLP = () => {
    let lpCourses = getCoursesWithinLP(this.props.currentCourse, this.props.coursesMap);

    this.setState({
      lpCourses,
    });
  };

  getOnboardingRMCourses = () => {
    let onboardingRMCoursesTemp = [];

    if (this.props.coursesMap) {
      for (let course of Object.values(this.props.coursesMap)) {
        if (isOnboardingRelatedMandatory(course) && isCourseVisible(course)) {
            onboardingRMCoursesTemp.push(course);
        }
      }
    }
    
    let onboardingRMCourses = onboardingRMCoursesTemp?.filter(rmCourse => rmCourse.courseId !== this.props.currentCourse.courseId);

    this.setState({
      onboardingRMCourses,
    });
  };

  setComingSoonLanguagesOptions = () => {
    if (!isVirtualClassroom(this.props.currentCourse)) {
      let comingSoonLangOptions = [];
      if (this.props.currentCourse.comingSoonLanguages) {
        this.props.currentCourse.comingSoonLanguages.map(csLang => {
          this.props.languages.map(lang => {
            if (lang.code === csLang.value) {
              let comingSoonLang = { ...csLang, ...lang };
              comingSoonLangOptions.push(comingSoonLang);
            }
          });
        });
      }

      comingSoonLangOptions = orderBy(comingSoonLangOptions, ['label']);
      this.setState({
        comingSoonLangOptions,
      });
    } else {
      this.setState({
        comingSoonLangOptions: [],
      });
    }
  };


  setCourseParentDetail = () => {
    this.setState({
      courseParentDetail: getParentCourse(this.props.currentCourse.courseIdMaster, this.props.coursesMap)
    });
  }

  setLanguagesOptions = () => {

    const currentCourse = this.props.currentCourse;
    
    if(!isVirtualClassroom(currentCourse)) {
      let languagesOptions = []
      let languageToPrint = currentCourse.languageToPrint;
      let language = currentCourse.languageToPrint;

      if (currentCourse.totalLanguages) {
        currentCourse.totalLanguages.map(langCourse => {
          this.props.languages.map(lang => {
            if (lang.code === langCourse.value) {
              let langToPrint = { ...langCourse, ...lang };
              languagesOptions.push(langToPrint);
            }
            if (languageToPrint && lang.code === languageToPrint.value) {
              language = { ...languageToPrint, ...lang };
            }
          });
        });
      }
      
      //if the language is not set and there is just one language option => open the child course with that language
      if (!language && languagesOptions && languagesOptions.length === 1) {
        this.setLanguage(languagesOptions[0]);
      }

      languagesOptions = orderBy(languagesOptions, ['label']);

      this.setState({
        languagesOptions,
        language,
      });
    }    
  };

  setLiveLang = () => {
    let liveLang = '';
    this.props.languages.map(lang => {
      if (lang.code === this.props.currentCourse.language) {
        liveLang = lang.description;
      }
    });
    this.setState({
      liveLang,
    });
  };

  isNotSameLanguage = () => {
    if (this.state.languagesOptions.length > 1 || this.state.language === null) return true;

    return this.state.languagesOptions[0].code === this.state.language.code ? false : true;
  };

  setSubtitlesOptions = (refreshSelectedSubtitle = false) => {
    if (isVideoOrPodcast(this.props.currentCourse) || isMultiActivity(this.props.currentCourse)) {
      let subtitlesOptions = [];
      let subtitlesCodes = getSubtitlesCodes(this.props.currentCourse);
      let selectedSubtitle = this.state.selectedSubtitle;

      subtitlesCodes.map(subCourse => {
        this.props.languages.map(lang => {
          if (lang.code === subCourse) {
            let langToPrint = { code: subCourse, ...lang };
            subtitlesOptions.push(langToPrint);

            if (subCourse === this.props.currentCourse.language) {
              selectedSubtitle = langToPrint;
            }
          }
        });
      });

      subtitlesOptions = orderBy(subtitlesOptions, ['label']);

      this.setState({
        subtitlesOptions,
        selectedSubtitle: refreshSelectedSubtitle ? null : selectedSubtitle,
      });
    } else {
      this.setState({
        subtitlesOptions: [],
        selectedSubtitle: null,
      });
    }
  };

  setSubtitle = subtitle => {
    let newSelectedSubtitle = subtitle;
    if (this.state.selectedSubtitle?.code === subtitle.code) {
      newSelectedSubtitle = null;
    }

    this.setState({
      selectedSubtitle: newSelectedSubtitle,
    });
  };

  stop = e => e.stopPropagation();

  setLanguage = language => {

    const currentCourse = this.props.currentCourse;
    const coursesMap = this.props.coursesMap;
    const childCourses = currentCourse?.childCourses;
    
    this.setState({
      language,
    });

    if(childCourses.length > 0) {
      childCourses.forEach(childCourse => {
        if(childCourse?.childId === language?.courseId && childCourse?.courseSummary === undefined) {
          this.props.getCourseSummary([currentCourse], coursesMap, childCourse)
        }
      })
    }
    if(isCourseWithVersioning(coursesMap,currentCourse?.courseIdMaster)) {
      this.props.getChildCourseVersioned(currentCourse, language.courseId, coursesMap);
    } else {
      this.props.getChildCourse(currentCourse, null, language.code, coursesMap);
    }
    this.props.setChildCourse(language);
  };

  checkClose = e => {
    this.props.toggleModal(false);
  };

  showAllSummary = () =>
    this.setState(prevState => {
      return { isShowSummary: !prevState.isShowSummary };
    });

  toggleEvent = stateName =>
    this.setState(prevState => {
      return { [stateName]: !prevState[stateName] };
    });

  isSingleActivity = () => {
    let { courseDetails } = this.props.currentCourse;

    if (
      courseDetails.length === 1 &&
      courseDetails[0].content.length === 1 &&
      courseDetails[0].content[0].type !== 'facetoface'
    )
      return true;
    else if (courseDetails.length === 2) {
      let isCustomCert = false,
        isUniqueActivity = false;

      courseDetails.map(activity => {
        if (activity.sectionName === 'completed') isCustomCert = true;
        else if (
          activity.sectionName !== 'completed' &&
          activity.content.length === 1 &&
          activity.content[0].type !== 'facetoface'
        )
          isUniqueActivity = true;
      });

      if (isCustomCert && isUniqueActivity) return true;
    }

    return false;
  };

  getChips = course => {
    let chips = [];

    //L2 LIST
    if (course?.level2) {
      course.level2.forEach(sectionId => {
        const index = chips.findIndex(chip => chip.key === sectionId);
        if (index < 0 && this.props.labelL2Map?.[sectionId]) {
          chips.push(this.props.labelL2Map[sectionId]);
        }
      });
    }

    //ORDER TAGS
    chips = orderBy(chips, ['order']);

    return chips;
  };

  handleClickImage = e => {
    const courseDetails = this.props.currentCourse.courseDetails;
    if (isVideoOrPodcast(this.props.currentCourse) && courseDetails && courseDetails.length > 0) {
      e.stopPropagation();
      this.props.openVideo(courseDetails, this.props.currentCourse);
    }
  };

  // CONTENT LANGUAGE
  setIsLoadingCountryLanguagesMapping = () => {
    this.setState({ 
      isLoadingCountryLanguagesMapping: true,
      isLoadedCountryLanguagesMapping: false 
    });
  }

  setResponseCountryLanguagesMapping = (res) => {
    this.setContentLanguageChips(res.chipsMap);
    this.setState({ 
      countryLanguageMapping: res.countryLanguageMapping,
      defaultSelection: res.defaultSelection ,
      chipsMap: res.chipsMap
    });
  }

  setIsLoadedCountryLanguagesMapping = (isLoaded) => {
    this.setState({ 
      isLoadingCountryLanguagesMapping: false,
      isLoadedCountryLanguagesMapping: isLoaded 
    });
  }

  setContentLanguageChips = (chipsMap) => {
    
    const currentCourse = this.props.currentCourse;
    const languages = this.props.languages
    let languagesOptions = []
    let languageToPrint = currentCourse.languageToPrint;
    let language = currentCourse.languageToPrint;

    if(!isVirtualClassroom(currentCourse)) {
      if (currentCourse.totalLanguages) {
        currentCourse.totalLanguages.map(langCourse => {
          languages.map(lang => {
            if (lang.code === langCourse.value && chipsMap[lang.code] === langCourse.courseId) {
              let langToPrint = { ...langCourse, ...lang };
              languagesOptions.push(langToPrint);
            }
            if (languageToPrint && lang.code === languageToPrint.value) {
              language = { ...languageToPrint, ...lang };
            }
          });
        });
      }

      //if the language is not set and there is just one language option => open the child course with that language
      if (!language && languagesOptions && languagesOptions.length === 1) {
        this.setLanguage(languagesOptions[0]);
      }

      languagesOptions = orderBy(languagesOptions, ['label']);

      this.setState({
        languagesOptions,
        language,
      });
    }
  }

  // Function to fetch country-languages mapping
  fetchCountryLanguagesMapping = async (courseIdMaster) => {

    this.setIsLoadingCountryLanguagesMapping();
    
    const url = GET_COUNTRY_LANGUAGES_MAPPING?.replace('{courseId}', courseIdMaster);

    axios({ url, method: 'GET', headers: getHeaders() })
      .then((response) => {
        if (response && response.data) {
          this.setResponseCountryLanguagesMapping(response.data);
        }
      })
      .catch(err => {
        console.error('❌ Error during getCountryLanguagesMapping request', err);
        dispatch(showError(err));
      })
      .finally(() => {
        this.setIsLoadedCountryLanguagesMapping(true);
      })

  };
  // ----------------------------

  toggleSectionChip = sectionId => {
    //go to PLP filtered by section
    const isSubcatalogue = this.props.history.location.pathname === USER_URLS.SUBCATALOGUE.URL;
    this.props.history.push(
      USER_URLS.SUBCATALOGUE.URL + '?' + queryParams.FILTER_SECTION + '=' + encodeURI(sectionId)
    );
    if (isSubcatalogue) {
      this.props.history.go();
    }
  };

  handleExpiringClick = course => {
    if (isPriceable(course)) {
      this.props.setShowModalContentExpiration(true);
    }
  };

  render() {
    const { lang, currentCourse, showCourseModal, coursesMap } = this.props;
    const {
      language,
      languagesOptions,
      selectedSubtitle,
      comingSoonLangOptions,
      subtitlesOptions,
      lpCourses,
      onboardingRMCourses,
      liveLang,
    } = this.state;
    let displayTopProps = {};

    if (currentCourse) {

      const {
        courseSummary,
        courseFullName,
        courseDetails,
        duration,
        credits,
        isStatic,
        totalLearningPathDuration,
      } = currentCourse;

      const totalLPDuration = totalLearningPathDuration !== 0 
        ? totalLearningPathDuration 
        : duration;
      const showLaunchButton = isStatic || isLearningPath(currentCourse) || courseDetails;

      let languagesChips = null;
      if (languagesOptions.length > 0 && this.isNotSameLanguage()) {
        languagesChips = languagesOptions;
      }
      let comingSoonLanguagesChips = null;
      if (comingSoonLangOptions.length > 0) {
        console.log('CSLANG', comingSoonLangOptions);
        comingSoonLanguagesChips = comingSoonLangOptions;
        this.props.currentCourse.comingSoonLanguages = comingSoonLanguagesChips;
      }

      let subtitlesChips = null;
      if (subtitlesOptions.length > 0) {
        subtitlesChips = subtitlesOptions;
      }

      const translate = isStatic ? this.props.t : null;
      const isPastEvent = checkUpcomingOrPastEvent(currentCourse, this.props.userProfile)?.[0]?.isPastEvent;

      displayTopProps = {
        course: { ...currentCourse, isPastEvent },
        compatibility: getCourseCompatibility(currentCourse),
        courseFullName: courseFullName,
        duration: printDurationHourFormat(totalLPDuration, lang, translate, false, false, currentCourse),
        credits: credits,
        chips: this.getChips(currentCourse),
        summary: printHTML(courseSummary),
        publicRating: currentCourse.rating,
        mentorRating: 0, //out of scope for now
        showLaunchButton: showLaunchButton,
        languages: languagesChips,
        isLoadingLanguages: this.state.isLoadingCountryLanguagesMapping,
        comingSoonLanguages: comingSoonLanguagesChips,
        selectedLanguage: language,
        setLanguage: this.setLanguage,
        subtitles: subtitlesChips,
        selectedSubtitle: selectedSubtitle,
        setSubtitle: this.setSubtitle,
        toggleSectionChip: this.toggleSectionChip,
        complexityName: getLevelLabel(currentCourse.complexityId, this.props.labelComplexityMap),
        toggleModalCourseById: this.props.toggleModalCourseById,
        isCurrentLpBlocked: isLpBlocked(currentCourse, coursesMap),
        blockingLps: getBlockingLps(currentCourse, coursesMap),
        isLiveStream: isLivestreamCourse(this.props.currentCourse),
        handleExpiringClick: this.handleExpiringClick,
        plan: this.props.plan,
        ecpu: isPoweredByEcpu(this.props.currentCourse),
        abo: isRecognizedByABO(this.props.currentCourse),
        isLoadingCourse: this.props.isLoadingCourse,
        isMultiActivity: isMultiActivity(currentCourse),
        coursesMap: this.props.coursesMap,
        levels: {
          l0: this.props.currentCourse.level0,
          l1: this.props.currentCourse.catalogTypes?.[0],
          l2: this.props.currentCourse.level2?.[0],
        },
        liveLang: liveLang,
      };
    }

    return (
      <AnimatePresence>
        <motion.div
          key={showCourseModal && currentCourse ? 'open' : 'close'}
          id="course_overview_wrapper"
        >
          {showCourseModal && currentCourse && (
            <>
              <HelmetMetaTags
                description={
                  showCourseModal ? currentCourse?.courseSummary?.replace(/<[^>]*>/g, '') : ''
                }
              />
              <Modal
                show={true}
                close={this.checkClose}
                animateSlide={true}
                hideX={true}
                className="modal__course-overview fillAvailable"
                focusTrapOptions={{
                  initialFocus: false,
                }}
              >
                <div
                  className="course-overview"
                  key={showCourseModal && currentCourse ? currentCourse.courseIdMaster : null}
                >
                  <Header level={HEADER_LEVEL.THREE} forceNotTransparent={true} />
                  <div className={`course-overview__content ${isNewLivestream(this.props.currentCourse) ? 'new-livestream' : ''}`}>
                    <AnimationSlide {...animationSlideToTop}>
                      <DisplayTopMobile {...displayTopProps} />
                      <DisplayTopDesk {...displayTopProps} />
                    </AnimationSlide>
                    {((this.props.isLoadingDetails[this.props.currentCourse.courseIdMaster] && this.props.isLoadingGetUserRelevantSessionById &&
                      !this.props.currentCourse.courseDetails &&
                      !isLearningPath(currentCourse)) ||
                      (this.props.currentCourse.courseDetails &&
                        !this.isSingleActivity() &&
                        !isLearningPath(currentCourse)) ||
                      (!!displayTopProps.isLiveStream && !isLearningPath(currentCourse))) && (
                        <AnimationSlide {...animationSlideToTop}>
                          <DetailCourseActivities
                            lang={lang}
                            course={currentCourse}
                            isLoadingDetails={
                              this.props.isLoadingDetails[this.props.currentCourse.courseIdMaster]  && this.props.isLoadingGetUserRelevantSessionById
                            }
                            subtitle={selectedSubtitle}
                            openActivity={this.props.openActivity}
                            isLoadingCourse
                          />
                        </AnimationSlide>
                      )}
                    {isLearningPath(currentCourse) && lpCourses?.length > 0 && (
                      <section className="course-overview__lp" aria-label={lang.LECTURES_IN_COURSE}>
                        <p className="course-overview__lp__courses-num">
                          {/* LEON-1057 {lang.LECTURES_IN_COURSE?.replace('{num}', lpCourses.length)} */}
                        </p>
                        <AnimationSlide {...animationSlideToTop}>
                          <DetailCourseActivities
                            lang={lang}
                            course={currentCourse}
                            isLoadingDetails={
                              this.props.isLoadingDetails[this.props.currentCourse.courseIdMaster] && this.props.isLoadingGetUserRelevantSessionById
                            }
                            subtitle={selectedSubtitle}
                            openActivity={this.props.openActivity}
                            coursesMap={this.props.coursesMap}
                          />
                        </AnimationSlide>
                      </section>
                    )}

                    {isLearningPath(currentCourse) && lpCourses.length === 0 && (
                      <div className="top-desk__multiactivity-skeleton">
                        {[...Array(3)].map((e, i) => (
                          <CardActivitySkeleton key={i} />
                        ))}
                      </div>
                    )}
                  </div>
                  {<ParentCoursesCarousel
                      props={displayTopProps}       
                      stringTitle={displayTopProps.isMultiActivity ? lang.COURSE_PART_OF_LABEL : lang.LESSON_PART_OF_LABEL}
                      stringDescription={displayTopProps.isMultiActivity ? lang.COURSE_PART_OF_LABEL_DESCR : lang.LESSON_PART_OF_LABEL_DESCR}
                    />}

                  {isOnboardingRelatedMandatory(currentCourse) && onboardingRMCourses?.length > 0 && (
                    <div className='related-mandatory-section'>
                      <RelatedMandatoryCarousel course={currentCourse} isPDP={true}/>
                    </div>
                  )}

                  {this.props.isAuthenticated && (
                    <RecommendedSection course={currentCourse} coursesMap={this.props.coursesMap} />
                  )}
                  <Footer></Footer>
                </div>
                {/* MODAL CONTENT LANGUAGE */}
                {this?.props?.modalsV3Open && this?.props?.modalsV3Open?.contentLanguage &&
                  <ModalContentLanguage 
                    courseIdMaster={currentCourse?.courseIdMaster}
                    setChipLanguage={this?.setLanguage}
                    parentClassName={'modal__course-overview'}
                    countryLanguageMapping={this.state.countryLanguageMapping}
                    defaultSelection={this.state.defaultSelection}
                    isLoadingCountryLanguagesMapping={this.state.isLoadingCountryLanguagesMapping}
                    isLoadedCountryLanguagesMapping={this.state.isLoadedCountryLanguagesMapping}
                  />
                }
              </Modal>
              
            </>
          )}
        </motion.div>
      </AnimatePresence>
    );
  }
}

const mapStateToProps = state => {
  return {
    coursesMap: state.course.coursesMap,
    isLoadingWishlist: state.course.isLoadingWishlist,
    currentCourse: state.course.currentCourse,
    userProfile: state.user.userProfile,
    isUserCompleted: state.user.isUserCompleted,
    isUserCompletedAnalytics: state?.user?.isUserCompletedAnalytics,
    lang: state.utils.lang,
    isLoadingDetails: state.course.isLoadingDetails,
    languages: state.utils.languages,
    showCourseModal: state.course.showCourseModal,
    isAuthenticated: state.authentication.isAuthenticated,
    scrollToCurrentCourseSessions: state.course.scrollToCurrentCourseSessions,
    plan: state.ecommerce.plan,
    isLoadingCourse: state.course.isLoadingCourse,
    labelL1Map: state.utils.labelL1Map,
    labelL2Map: state.utils.labelL2Map,
    labelComplexityMap: state.utils.labelComplexityMap,
    isLoadingGetUserRelevantSessionById : state.utils.isLoadingGetUserRelevantSessionsById,
    analyticsCourseId: state.utils.analyticsCourseId,
    typeModal: state.utils.typeModal,
    modalsV3Open: state.utils.modalsV3Open,
    showIframe: state.utils.showIframe
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCourseDetails: (courseId, parentId) =>
      dispatch(actions.getCourseDetails(courseId, parentId)),
      getUserRelevantSessionsCourse : (courseId) => dispatch(actions.getUserRelevantSessionsById(courseId)),
    getCourseCompletion: () => dispatch(actions.getCourseCompletion()),
    getCourseCompletionById: courseId => dispatch(actions.getCourseCompletionById(courseId)),
    setChildCourse: lang => dispatch(actions.setChildCourse(lang)),
    openSubCourseInLP: course => dispatch(actions.openSubCourseInLP(course)),
    toggleModal: (value, course = null) => dispatch(actions.toggleModal(value, course)),
    openVideo: (courseDetails, course) => dispatch(actions.openVideo(courseDetails, course)),
    openConfirmBookingModal: (courseId, sessionId, isSessionSignUp) =>
      dispatch(actions.openConfirmBookingModal(courseId, sessionId, isSessionSignUp)),
    openActivity: (activity, course, subtitle) =>
      dispatch(actions.openActivity(activity, course, subtitle)),
    resetScrollToCurrentCourseSessions: () =>
      dispatch(actions.resetScrollToCurrentCourseSessions()),
    toggleModalCourseById: (...args) => dispatch(actions.toggleModalCourseById(...args)),
    setShowModalContentExpiration: show => dispatch(actions.setShowModalContentExpiration(show)),
    getChildCourse: (course, childId, language, coursesMap) =>
      dispatch(actions.getChildCourse(course, childId, language, coursesMap)),
    getChildCourseVersioned: (course, childId, coursesMap) =>
      dispatch(actions.getChildCourseVersioned(course, childId, coursesMap)),
    getCourseSummary: (courses, coursesMap, childCourse) =>
      dispatch(actions.getCourseSummary(courses, coursesMap, childCourse)),
    setAnalyticsCourseId: (courseId) => dispatch(actions.setAnalyticsCourseId(courseId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CourseOverview));
