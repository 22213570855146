import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Course, l2SectionCourse, LangMap, CourseLanguage, CoursesMap} from '@model/CoursesClass';
import CardController from '@components/CardV3';
import Chip from '@components/UI/Chip';
import { defaultRatingItems } from '@components/UI/RadioButtonGroup';
import RadioButtonGroup from '@components/UI/RadioButtonGroup';
import ChipsList from '@components/ChipsList';
import {
  canShowRating,
  getChipLanguageForNewLivestream,
  isAnEvent,
  isCompletedCourse,
  isCourseExpiring,
  isLpEnrolled,
  isMobile,
  isNewLivestream,
  isOnboardingRelatedMandatory,
  isOverdueCourse,
  printDate,
  showBoxCompleteEnroll,
  showBoxContinueEnroll,
} from '@utility/Api';
import clsx from '@utility/clsx';
import EcpuIcon from '@images/svg-icons/ecpu.svg';
import AboIcon from '@images/svg-icons/Logo_ABO_official.svg';
import ExpiredDateOrLabel from './ExpiredDateOrLabel';
import ComingSoonList from '@components/ComingSoonList';
import CardActivitySkeleton from '@components/skeletons/CardActivitySkeleton';
import ActivitiesList from '@components/UI/ActivitiesList';
import ActivitiesListLP from '@components/UI/ActivityListLP';
import { canCourseBeAccessed } from '@utility/ecommerceUtility';
import ButtonV2 from '@components/UI/ButtonV2';
import { enrollLp, toggleModalV3 } from '@redux-actions/index';
import { useSelector } from '@hooks/redux';
import ModalShare from '@components/UI/ModalShare';
import ShareContent from './ShareContent';
import { CoverHarvard, isCourseHarvard } from '@components/Harvard/Harvard';
import TextSkeleton from '@components/skeletons/TextSkeleton';
import { useBreakpoint, useBreakpointBoolean } from '@hooks/createBreakpoint';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { camelCase } from 'lodash';
import { getOnboardingC6Course } from '@utility/onBoardingCourseUtility';
import InformationBox from '@components/UI/Box/InformationBox';

type Props = {
  lang: LangMap;
  course: Course;
  compatibility: number;
  courseFullName: string;
  duration: string;
  credits: number;
  chips: Array<l2SectionCourse>;
  summary: { __html: string };
  publicRating: number;
  mentorRating: number;
  showLaunchButton: boolean;
  languages: Array<CourseLanguage>;
  isLoadingLanguages?: boolean;
  selectedLanguage: CourseLanguage;
  setLanguage: (language: CourseLanguage) => void;
  subtitles: Array<CourseLanguage>;
  selectedSubtitle: CourseLanguage;
  setSubtitle: (language: CourseLanguage) => void;
  toggleSectionChip: (sectionId) => void;
  toggleModalCourseById: (
    courseId: string,
    redirectTo404?: boolean,
    forceOnBoarding?: boolean
  ) => void;
  isCurrentLpBlocked: boolean;
  blockingLps: Course[];
  isLiveStream: boolean;
  handleExpiringClick: (course: Course) => void;
  ecpu: boolean;
  abo: boolean;
  isLoadingCourse: { [courseId: string]: boolean };
  isMultiActivity: boolean;
  coursesMap?: Array<Course>;
  levels: { l0: string; l1: string; l2: string };
};


const DisplayTopMobile = (props: Props): ReactElement => {
  const dispatch = useDispatch();

  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const lang = useSelector(state => state.utils.lang);
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const isLoadingDetails = useSelector(state => state.course.isLoadingDetails);
  const {isPortrait,isPhone,isTablet,isDesktop} = useBreakpointBoolean();
  const userProfile = useSelector(state => state.user.userProfile);
  const isLpEnrolling = useSelector(state => state.course.isLpEnrolling);
  const labelComplexityMap = useSelector(state => state.utils.labelComplexityMap);
  const isLoadingCourseSummary = useSelector(state => state.course.isLoadingCourseSummary);
  const hasOnboardingC6Course = getOnboardingC6Course(coursesMap);
  const showOnboardingRMCarousel = useSelector(state => state.utils.showOnboardingRMCarousel);
    const languages = useSelector((state) => state.utils.languages);
  //console.log("COURSE TEST CHILD REMOVE", props.course)

  return (
    <section className="top-mobile__wrapper" aria-label={props.courseFullName}>
      {showShareModal && (
        <ModalShare show={true} close={() => setShowShareModal(false)}>
          <ShareContent course={props.course} />
        </ModalShare>
      )}
      <CardController
        course={props.course}
        variant="overview"
        showLaunchButton={props.showLaunchButton}
        subtitle={props.selectedSubtitle}
        isParentLpBlocked={props.isCurrentLpBlocked}
        blockingLps={props.blockingLps}
        setShowShareModal={setShowShareModal}
        noLazyLoadImage={true}
      />

      <div className={"top-mobile " + (isCourseHarvard(props.course) ? 'harvard-top' : '')}>
        <div className="duration-credits-row">
          <div className="duration-credits-container">
            <span className="text-14 text-duration">{props.duration}</span>
            {props.credits && !isAnEvent(props.course) ? (
              <span className="text-14 text-credits">
                {props.credits +
                  ' ' +
                  (props.credits === 1 ? lang.CREDIT_LABEL : lang.CREDITS_LABEL)}
              </span>
            ) : null}
          </div>
          {/*canCourseBeAccessed(props.course) &&
            isLpEnrolled(props.course) &&
            !isCompletedCourse(props.course) &&
            !isLpEnrolling &&
            !isAnEvent(props.course) && (
              <div>
                <ButtonV2
                  variant="text-btn-no-arrow"
                  small
                  className={'disenroll-btn'}
                  handleClick={() => dispatch(enrollLp(false, props.course.courseIdMaster))}
                >
                  {lang.DISENROLL_LABEL}
                </ButtonV2>
              </div>
            )*/}
        </div>
        <div>
          {props.course.expectedCompletion && !props.course.isPastEvent ? (
            <span
              className={clsx('text-14 text-expected-date', {
                overdue: !!isOverdueCourse(props.course),
                expiring: !!isCourseExpiring(props.course),
              })}
            >
              {lang.DUE_DATE_LABEL
                ? lang.DUE_DATE_LABEL + ': ' + printDate(props.course.expectedCompletion, lang)
                : null}
            </span>
          ) : null}
        </div>
        {(!props.isLiveStream || (props.isLiveStream && props.course.isPastEvent)) && (
          <ExpiredDateOrLabel
            lang={lang}
            course={props.course}
            userProfile={userProfile}
            handleExpiringClick={props.handleExpiringClick}
          />
        )}
        {props.chips && !isAnEvent(props.course) && (
          <div className="chips">
            {props.chips.map(chip => (
              <Chip
                label={chip.label}
                variant="transparent"
                key={chip.key}
                toggle={() => {buildClickDataTrackingObject("breadcrumb_"+camelCase(chip.label));props.toggleSectionChip(chip.key)}}
              />
            ))}
          </div>
        )}

{showBoxContinueEnroll(props.course, coursesMap) && !showBoxCompleteEnroll(props.course, coursesMap) &&
            <InformationBox text={lang.BOX_CONTINUE_ENROLLMENT}/>
          }
          {showBoxCompleteEnroll(props.course, coursesMap) &&
            <InformationBox text={lang.BOX_COMPLETE_ENROLLMENT}/>
          }

        {(props.summary || props.ecpu || props.abo) && (
          <div className={'summary'}>

            {props.summary && !props.isLoadingCourse?.[props.course.courseIdMaster] && !isLoadingCourseSummary?.[props.course.courseId] ?
            <>
            <div dangerouslySetInnerHTML={props.summary}></div>

            {/* Related Mandatory Label */}
            {showOnboardingRMCarousel && !!hasOnboardingC6Course && isOnboardingRelatedMandatory(props.course) && (
              <div className="onboarding-tab-overview__related-mandatory-content-container pdp">
                <div className="onboarding-tab-overview__related-mandatory-content">
                  <p className="onboarding-tab-overview__related-mandatory-content-text">
                    {lang?.ONBOARDING_PDP_RELATED_MANDATORY_CONTENT}
                  </p>
                </div>
              </div>
            )}
            </>

            :            (isTablet? <>
            <TextSkeleton width={500} height={16} borderRadius={5} className='skeleton-text-desk'/>
            <TextSkeleton width={500} height={16} borderRadius={5} className='skeleton-text-desk'/>
            <TextSkeleton width={500} height={16} borderRadius={5} className='skeleton-text-desk'/>
            </>
            :
            <>
            <TextSkeleton width={300} height={16} borderRadius={5} className='skeleton-text-desk'/>
            <TextSkeleton width={300} height={16} borderRadius={5} className='skeleton-text-desk'/>
            <TextSkeleton width={300} height={16} borderRadius={5} className='skeleton-text-desk'/>
            <TextSkeleton width={300} height={16} borderRadius={5} className='skeleton-text-desk'/>
            </>)
            }

            {props.ecpu && (
              <div className={'powered-by-ecpu-container'}>
                <span>{lang.POWERED_BY}</span>
                <EcpuIcon />
              </div>
            )}
            {props.abo && (
              <div className={'powered-by-ecpu-container'}>
                <span>{lang.RECOGNIZED_BY}</span>
                <AboIcon />
              </div>
            )}
          </div>
        )}
        {isCourseHarvard(props.course) && (
          <div className="top-desk-info_cover-harvard">
            <CoverHarvard type={props.course.typeHarvard} />
          </div>
        )}

        <ActivitiesList course={props.course} />
        <ActivitiesListLP course={props.course} coursesMap={props.coursesMap} />
      </div>

      {props.languages?.length > 0 && !isCourseHarvard(props.course) && (
        <section className="languages-section" aria-label={lang.COURSE_AVAILABLE_LANGUAGES}>
          <span className="text-languages">{lang.COURSE_AVAILABLE_LANGUAGES}</span>
          <ChipsList
            items={props.languages}
            selectedItem={props.selectedLanguage}
            setItem={props.setLanguage}
            id="languages-mobile"
            isSelected={(item, selected) =>
              item && selected
                ? item.code === selected.code && item.courseId === selected.courseId
                : false
            }
            disabled={props.isLoadingCourse?.[props.course.courseIdMaster]}
            loading={props.isLoadingLanguages}
          />
        </section>
      )}
      {isNewLivestream(props.course) && getChipLanguageForNewLivestream(props.course, languages) && (
              <section className="languages-section newLivestream-mobile" aria-label={lang.COURSE_AVAILABLE_LANGUAGES}>
                <span className="text-languages">{lang.COURSE_AVAILABLE_LANGUAGES}</span>
                <Chip label={getChipLanguageForNewLivestream(props.course,languages)} checked={true} className="cursor-default" />
              </section>
            )}
      {props.subtitles?.length > 0 && (
        <section
          className="languages-section"
          aria-label={lang.COURSE_AVAILABLE_SUBTITLES_LANGUAGES}
        >
          <span className="text-languages">{lang.COURSE_AVAILABLE_SUBTITLES_LANGUAGES}</span>
          <ChipsList
            items={props.subtitles}
            selectedItem={props.selectedSubtitle}
            setItem={props.setSubtitle}
            id={props.selectedLanguage?.code + '_subtitles'}
            isSelected={(item, selected) =>
              item && selected
                ? item.code === selected.code && item.courseId === selected.courseId
                : false
            }
          />
        </section>
      )}
      {props.course.comingSoonLanguages?.length > 0 && (
        <ComingSoonList
          items={props.course.comingSoonLanguages}
          availableLanguages={props.course.totalLanguages}
        />
      )}
      {props.course &&
        !props.course.isStatic &&
        props?.publicRating > 0 /*|| props?.mentorRating > 0*/ && (
          <div className="top-mobile">
            {canShowRating(props.course, props.publicRating) && (
              <div className="rating">
                <span className="text-rating">{lang.AUDIENCE_RATING}</span>
                <RadioButtonGroup
                  items={defaultRatingItems}
                  checked={props.publicRating}
                  handleOnClick={() => {}}
                  isRating={true}
                  fontSize="14px"
                  hideLabel
                  readOnly
                />
              </div>
            )}
          </div>
        )}

      {isLoadingDetails?.[props.course.courseIdMaster] && props.isMultiActivity && (
        <div className="top-mobile__multiactivity-skeleton">
          {[...Array(3)].map((e, i) => (
            <CardActivitySkeleton key={i} />
          ))}
        </div>
      )}
    </section>
  );
};

export default DisplayTopMobile;
