import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalV3 from '../ModalV3';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { courseStarted, openActivity, toggleModalV3 } from '@redux-actions/index';
import ButtonV2 from '@components/UI/ButtonV2';
import '@css/partials_v3/ui/modals/_contentLanguage.scss';
import Select from '@components/UI/Modals/ContentLanguage/Select';
import { ErrorObj, ErrorTypes } from '../../../../types/UI/ErrorMessageV2';
import useGetCountriesAndLanguages from '@hooks/contentLanguage/useGetCountriesAndLanguages';
import { GetOverviewResponse, ModalContentLanguageProps } from 'src/types/ContentLanguage/contentLanguage';
import { RootState } from '../../../../store/store';
import { 
    fetchChildInfoWithOverviewApiCall, 
    findMasterChildByLang, 
    formatLanguageAsCourseLanguage, 
    getActivityInfoFromOverviewApiResponse, 
    getChildIdByKeyAndLanguage, 
    getCourseChildFromParentCourse, 
    getNextActivityFromOverviewApiResponse, 
    openIframeWithContent, 
    openIframeWithContentForVideoOrPodcast
} from '@utility/ContentLanguage/contentLanguageUtility';
import { AxiosResponse } from 'axios';
import { addSubtitleParam, isMultiActivity, isVideoOrPodcast } from '@utility/Api';

const ModalContentLanguage: FC<ModalContentLanguageProps> = ({ 
    courseIdMaster,
    countryLanguageMapping,
    defaultSelection,
    isLoadingCountryLanguagesMapping,
    isLoadedCountryLanguagesMapping,
    setChipLanguage,
    parentClassName
}) => {

    const dispatch = useDispatch();
    const { isPhone } = useBreakpointBoolean();
    // NEEDED IN OPEN ACTIVITY FUNCTION
    const [windowRef, setWindowRef] = useState(null);

    const {
        modalsV3Open,
        lang,
        coursesMap
    } = useSelector((state: RootState) => ({
        modalsV3Open: state.utils.modalsV3Open,
        lang: state.utils.lang,
        coursesMap: state.course.coursesMap
    }));
    
    const [error,setError] = React.useState<ErrorObj | null>(null);
    const [isLoadingChildInfo, setIsLoadingChildInfo] = useState(false);

    const {
        selectedCountry,
        setSelectedCountry,
        selectedLanguage,
        setSelectedLanguage,
        countryOptions,
        languageOptions,
        // countryLanguageMapping,
    } = useGetCountriesAndLanguages(
        // courseIdMaster,
        countryLanguageMapping,
        defaultSelection
    )

    // Modal Cancel Button
    const CancelButton = () => {

        const handleClick = () => {
            dispatch(toggleModalV3("contentLanguage", false));
        }

        return (
            <ButtonV2
                className="cancel__button"
                handleClick={handleClick}
                small={!isPhone}
                variant={`${isPhone ? 'text-btn-no-arrow' : 'tertiary'}`}
            >
                {lang.CONTENT_LANGUAGE_MODAL_CANCEL}
            </ButtonV2>
        )
    }

    // Modal Confirm Button
    const ConfirmButton = () => {

        // FUNCTION to handle click on modal CONFIRM button
        const handleClick = () => {

            if(!selectedCountry) {
                setError({type: ErrorTypes.FIELD_REQUIRED, message: lang.ERROR_REQUIRED})
                return
            }
            if(!selectedLanguage) {
                setError({type: ErrorTypes.FIELD_REQUIRED, message: lang.ERROR_REQUIRED})
                return
            }
            // OVERVIEW api call to specific child course in order to start the correct content
            if (countryLanguageMapping && Object.keys(countryLanguageMapping).length > 0) {
                const childId = getChildIdByKeyAndLanguage(
                    countryLanguageMapping,
                    selectedCountry?.value,
                    selectedLanguage?.value
                )
                // call overview su childId e aprire contenuto con directUrl ottenuto da questo
                setIsLoadingChildInfo(true)
                
                const courseLanguage = formatLanguageAsCourseLanguage(
                    selectedLanguage,
                    childId,
                    childId === courseIdMaster,
                    courseIdMaster
                )
                // This because there are only chips of masterChild languages
                // So we cannot select chip language by course language but by masterchild language
                // const masterChild = findMasterChildByLang(countryLanguageMapping,selectedLanguage?.value)
                // const chipLanguage = masterChild.childId === childId 
                //     ? courseLanguage
                //     : formatLanguageAsCourseLanguage(selectedLanguage,masterChild.childId,masterChild.childId === courseIdMaster,courseIdMaster)

                fetchChildInfoWithOverviewApiCall(childId)
                    .then((response: AxiosResponse<GetOverviewResponse>) => {
                        
                        const parentCourse = coursesMap[courseIdMaster]
                        if(isMultiActivity(parentCourse)) {
                            const nextActivity = getNextActivityFromOverviewApiResponse(response.data);
                            if(nextActivity) {
                                const subtitle = addSubtitleParam(nextActivity.url, courseLanguage?.code)
                                dispatch(openActivity(nextActivity, parentCourse, subtitle))
                            }
                        } else {
                            // const handleOnboardingHP = isCourseOnBoarding(parentCourse);
                            const activityInfo = getActivityInfoFromOverviewApiResponse(response.data, parentCourse);
                            if (isVideoOrPodcast(parentCourse)) {
                                openIframeWithContentForVideoOrPodcast(
                                    dispatch,
                                    parentCourse,
                                    courseLanguage,
                                    activityInfo
                                )
                            } else {
                                openIframeWithContent(
                                    dispatch,
                                    parentCourse,
                                    courseLanguage,
                                    activityInfo,
                                    windowRef,
                                    setWindowRef
                                )
                            }

                            if (parentCourse.userCourseStatus === 'available') courseStarted(parentCourse.courseIdMaster);
                        }
                        // CHANGE language chip selection
                        // setChipLanguage(chipLanguage)
                        setChipLanguage(courseLanguage)
                        dispatch(toggleModalV3("contentLanguage", false));
                    })
                    .catch(err => console.error(`❌ ${err}`))
                    .finally(() => setIsLoadingChildInfo(false))
            }
        }

        return (
            <ButtonV2
                className="confirm__button"
                handleClick={handleClick}
                small={!isPhone}
                loading={isLoadingChildInfo}
            >
                {lang.CONTENT_LANGUAGE_MODAL_CONFIRM}
            </ButtonV2>
        )
    }

    return (
        <ModalV3
            show={modalsV3Open && modalsV3Open.contentLanguage}
            className={`content-language`}
            title={lang.CONTENT_LANGUAGE_MODAL_TITLE}
            buttons={[<CancelButton />, <ConfirmButton />]}
            parentClassName={parentClassName}
        >
            {/* DESCRIPTION */}
            <p className={`content-language__description`}>
                {lang.CONTENT_LANGUAGE_MODAL_DESCRIPTION}
            </p>
            {/* DROPDOWN COUNTRY */}
            <div className={`content-language__dropdown`}>
                <Select
                    id={`select-country`}
                    name={`country`}
                    value={selectedCountry}
                    options={countryOptions ?? []}
                    label={lang?.CONTENT_LANGUAGE_MODAL_DROPDOWN_COUNTRY_LABEL}
                    placeholder={lang.CONTENT_LANGUAGE_MODAL_DROPDOWN_COUNTRY_PLACEHOLDER}
                    ariaLabel={lang.CONTENT_LANGUAGE_MODAL_DROPDOWN_COUNTRY_PLACEHOLDER}
                    onChange={setSelectedCountry}
                    error={error && !selectedCountry ? error : null}
                    setError={setError}
                    isLoading={isLoadingCountryLanguagesMapping}
                    disabled={!isLoadedCountryLanguagesMapping}
                    tabIndex={"-1"}
                />
            </div>
            {/* DROPDOWN LANGUAGE */}
            <div className={`content-language__dropdown`}>
                <Select
                    id={`select-language`}
                    name={`language`}
                    value={selectedLanguage}
                    options={languageOptions ?? []}
                    label={lang?.CONTENT_LANGUAGE_MODAL_DROPDOWN_LANGUAGES_LABEL}
                    placeholder={lang.CONTENT_LANGUAGE_MODAL_DROPDOWN_LANGUAGES_PLACEHOLDER}
                    ariaLabel={lang.CONTENT_LANGUAGE_MODAL_DROPDOWN_LANGUAGES_PLACEHOLDER}
                    onChange={setSelectedLanguage}
                    disabled={!selectedCountry}
                    error={error && selectedCountry && !selectedLanguage ? error : null}
                    setError={setError}
                    isLoading={isLoadingCountryLanguagesMapping}
                />
            </div>
        </ModalV3>
    )
}

export default ModalContentLanguage;