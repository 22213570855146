import { Dispatch } from "react";
import { 
    handleCloseIframe,
    toggleIframePopup, 
    toggleModalCourseById, 
    toggleModalV3, 
    videoLessonSubscribe 
} from "@redux-actions/index";
import { 
    AllLanguagesLanguage, 
    CountryLanguagesMapping, 
    CountryTranslations, 
    DropdownOption, 
    GetOverviewResponse 
} from "src/types/ContentLanguage/contentLanguage";
import * as urlList from '../../config';
import { 
    addSubtitleParam, 
    areAllActivitiesCompleted, 
    getFirstActivity, 
    getHeaders, 
    isActivityBlocked, 
    isActivityCompleted, 
    isMobile, 
    isMultiActivity, 
    isPathAbsolute, 
    isPdfCourse, 
    isStartedCourse, 
    isVideoCourse, 
    isVideoOrPodcast 
} from "@utility/Api";
import axios from "axios";
import { 
    ActivityInfo, 
    Course, 
    CourseActivity, 
    CourseDetail, 
    CourseLanguage, 
    LangMap 
} from "@model/CoursesClass";
import { courseStatus, TAGS } from "@utility/const";
import { getVideoProgressCookie } from "@utility/VideoTracking/videoTrackingUtility";

/**
 * Funtion that perform the opening of the Content Language Modal (it follows the standard steps needded to open modals V3 in Leonardo)
 * @param dispatch from useDispatch redux in order to perform the redux action that trigger the modal open
 */
export const openContentLanguageModal = (dispatch: Dispatch<any>) => {
    dispatch(toggleModalV3("contentLanguage", true));
}

/**
 * 
 * @param lang map of all labels based on user platform language
 * @param course course that I'm evaluating and for what I want to check the status
 * @returns LAUNCH_LABEL ("Start") if course is not started yet, otherwise CONTINUE_LABEL ("Continue") if course is already started
 */
export const handleOpenContentLanguageModalButtonLabel = (
    lang: LangMap,
    course: Course
): string => {

    if(isMultiActivity(course)) {
        if(isStartedCourse(course) && !areAllActivitiesCompleted(course.courseDetails)) {
            return lang.CONTINUE_LABEL
        }
        return lang.LAUNCH_LABEL;
    }

    if (course.userCourseStatus === courseStatus.ENROLLED && !isPdfCourse(course)) {
        return lang.CONTINUE_LABEL;
    }
    
    return lang.LAUNCH_LABEL
}

/**
 * Function to return array of availables countries in DropdownOption format ([{ value: 'us', label: 'United States' }]) 
 * @param courseCountries all countries code available (express in 'us', 'ca', etc) for a specific courseIdMaster
 * @param countryTranslations list of all country labels available in the app based from a specific language (got from the /label/country/labels/{language} API call)
 * @returns array of options for the Country Select component as DropdownOption type 
 */
export const getCountriesOptionsFromCountryCode = (courseCountries: string[], countryTranslations: CountryTranslations) => {

    const options = courseCountries.map((countryCode: string) => {
        return {
            value: countryCode,
            label: countryTranslations?.[countryCode],
        }
    });
    return options;
}

/**
 * Function to return array of availables languages in DropdownOption format ([{ value: 'us', label: 'United States' }]) 
 * @param countryLanguages all languages available (express in 'en', 'it', etc) for a specific country
 * @param allLanguages list of all languages available in the app (got from the /label/allLanguages API call)
 * @returns array of options for the Language Select component as DropdownOption type
 */
export const getLanguagesOptionsFromCountry = (countryLanguages: string[], allLanguages: AllLanguagesLanguage[]) => {

    const options = countryLanguages?.map((countryLanguage: string) => {
        const language = allLanguages.find((lang: AllLanguagesLanguage) => lang.code === countryLanguage);
        return {
            value: language?.code,
            label: language?.label,
        }
    });
    return options;
}

/**
 * Function to performthe overview API call in order to retrieve data to open the iframe and start the content
 * @param courseId courseId of a specific course
 * @returns an AxiosPromise related to the specific API call
 */
export const fetchChildInfoWithOverviewApiCall = async (courseId: string) => {

    const url = urlList.COURSE_OVERVIEW_URL;

    return axios({ 
        url, 
        method: 'GET', 
        headers: getHeaders() ,
        params: {
            courseId: courseId,
        },
    })
}

/**
 * Function to perform the opening of the iframe for Video or Podcast with the content in country-language selected
 * @param dispatch from useDispatch redux
 * @param course related to the content
 * @param courseLanguage language of the content
 * @param activityInfo info related to the content
 * @returns nothing, it opens the iframe
 */
export const openIframeWithContentForVideoOrPodcast = (
    dispatch: Dispatch<any>,
    course: Course,
    courseLanguage: CourseLanguage,
    activityInfo: ActivityInfo
): void => {

    if(!course) return 

    dispatch(toggleIframePopup(
        true,
        course,
        addSubtitleParam(activityInfo.url, courseLanguage?.code),
        activityInfo.urlToCheck,
        true,
        null,
        activityInfo.moduleId,
        courseLanguage?.code ?? course?.language
    ));
    // IF isVideo && status 'available' Add API call to enroll the video lesson
    if (isVideoCourse(course) && course.userCourseStatus === 'available') {
        dispatch(videoLessonSubscribe(course.courseId));
    }
}

/**
  * Function to perform the opening of the iframe for content that are not Video or Podcast with the content in country-language selected
 * @param dispatch from useDispatch redux
 * @param course related to the content
 * @param courseLanguage language of the content
 * @param activityInfo info related to the content
 * @param windowRef ref to the window
 * @param setWindowRef setter for the windowRef
 * @returns 
 */
export const openIframeWithContent = (
    dispatch: Dispatch<any>,
    course: Course,
    courseLanguage: CourseLanguage,
    activityInfo: ActivityInfo,
    windowRef: any,
    setWindowRef: (ref: any) => void
): void => {

    if(!course) return
    
    const iOS = isMobile.iOS() && !('MSStream' in window);
    const type = activityInfo.type

    if ((iOS && type && type.toLowerCase() === 'resource') ||
        (type && type.toLowerCase() === 'url')) {
        console.error('windowRef', { windowRef });
        if (windowRef) {
            windowRef.location = activityInfo.url;
            console.error('window opened by windowRef');
            setWindowRef(null);
        } else {
            //open course in another tab
            const newWindow = window.open(activityInfo.url);
            if (!newWindow) {
                //if the new window is not opened --> open course detail
                console.error('window not opened');
                dispatch(toggleModalCourseById(course.courseIdMaster));
            } else {
                dispatch(handleCloseIframe(course));
            }
        }
    } else {
        dispatch(toggleIframePopup(
            true, 
            course, 
            activityInfo.url, 
            null, 
            false, 
            null, 
            activityInfo.moduleId, 
            courseLanguage?.code ?? course?.language
        ));
    }
}

/**
 * Function to retrieve and format activity info based on the Overview response API call
 * @param overviewResponse response from the /overview API call
 * @param course reference Course
 * @returns ActivityInfo needed for the content displayed in the iframe
 */
export const getActivityInfoFromOverviewApiResponse = (
    overviewResponse: GetOverviewResponse,
    course: Course
): ActivityInfo => {

    if(!overviewResponse) return null

    let url:string = '';
    let urlToCheck:string | null = null;
    let type: string | null = null;
    let moduleId: string = '';
    let videoProgress: number | null = null;
    let videoLastUpdate: number | null = null;

    if (!course) {
        return {
            url,
            urlToCheck,
            type,
        };
    }

    const courseDetail: CourseDetail = overviewResponse?.activities?.[0]
    const courseActivity = courseDetail?.content?.[0]

    if (courseDetail.sectionName !== 'completed') {
        if (isVideoOrPodcast(course) &&
            courseActivity?.tags?.length > 0 &&
            courseActivity?.tags.includes(TAGS.publishDirect)
        ) {
            url = formatVideoUrl(courseActivity, course)
            urlToCheck = courseActivity.url
            videoProgress = courseActivity?.videoProgress
            videoLastUpdate = courseActivity?.videoLastUpdate
        } else { 
            url = courseActivity.url
        }

        type = courseActivity.type;
        moduleId = String(courseActivity?.moduleId);
    }

    //prevent relative path
    if (!isPathAbsolute(url)) {
        url = '';
    }

    return {
        url,
        urlToCheck,
        type,
        moduleId,
    };
}

/**
 * Function to retrieve and format activity info based on the Overview response API call
 * @param overviewResponse response from the /overview API call
 * @param course parent Course
 * @returns ActivityInfo needed for the content displayed in the iframe
 */
export const getNextActivityFromOverviewApiResponse = (
    overviewResponse: GetOverviewResponse,
    noFallback: boolean = false,
    getCompleted: boolean = false,
    moduleId: string = ''
): CourseActivity | null => {
    
    if(!overviewResponse) return null

    const courseDetails: [CourseDetail] = overviewResponse?.activities
    const courseDetailsWithActivitiesFilteredAndSorted = courseDetails
        .filter((courseDetail: CourseDetail) => courseDetail.sectionName !== 'completed')
        .sort((a: CourseDetail, b: CourseDetail) => a.sectionPosition - b.sectionPosition)

    const activity: CourseActivity = courseDetailsWithActivitiesFilteredAndSorted.flatMap(
        (courseDetail: CourseDetail) => courseDetail?.content?.filter((activity: CourseActivity) => {
            return (
                (!isActivityCompleted(activity) || getCompleted) &&
                !isActivityBlocked(activity, courseDetail) &&
                activity.moduleId !== Number(moduleId)
            );
        })
    )?.[0];

    if (!noFallback && !activity) {
        //if all activities are completed
        return getFirstActivityByCourseDetails(courseDetails
            .sort((a: CourseDetail, b: CourseDetail) => a.sectionPosition - b.sectionPosition));
    }

    return activity;
}

const getFirstActivityByCourseDetails = (courseDetails: CourseDetail[]) => {
  if (!courseDetails || courseDetails.length === 0) {
    return null;
  }

  if (courseDetails?.length > 0) {
    const content = courseDetails[0]?.content

    if (content?.length > 0) {
      return content[0];
    }
  }

  return null;
};

/**
 * Function to format the video that will be displayed in the iframe
 * @param courseActivity of the related course
 * @param course related Course
 * @returns the video url string formatted
 */
const formatVideoUrl = (courseActivity: CourseActivity, course: Course): string => {

    const videoUrl = courseActivity?.directUrl
    const cookieVideoProgress = getVideoProgressCookie(course?.courseId)
    const videoCurrentTime = cookieVideoProgress && cookieVideoProgress.lessonId
        ? cookieVideoProgress.currentTime
        : courseActivity?.videoCurrentTime

    if (!videoCurrentTime || videoCurrentTime === 0) return videoUrl

    return videoUrl.includes('?')
        ? `${videoUrl}&start=${videoCurrentTime}`
        : `${videoUrl}?start=${videoCurrentTime}`

}

/**
 * Function to get the child courseId from the countryLanguagesMapping object 
 * @param countryLanguageMapping object where there's the association between country and languages
 * @param country code of the country
 * @param language code of the language
 * @returns the courseId of the child that have the country-language content
 */
export const getChildIdByKeyAndLanguage = (
    countryLanguageMapping: CountryLanguagesMapping, 
    country: string, 
    language: string
): string | null => {
    // Check if the country exists in the countrLanguagesMapping object
    if (countryLanguageMapping[country]) {
        // Find the specific country object with the specified language (there could be more languages for a single country)
        const countryObj = countryLanguageMapping[country].find(item => item.language === language);
        // Return the childId if found, else null
        return countryObj ? countryObj.childId : null; 
    }
    // Return null if no country exists in the countryLanguageMapping object
    return null; 
}

/**
 * Function useful to format a language in DropdownOption type to a language
 * usebale to perform the automatic chip selection in PDP
 * @param selectedLanguage language in DropdownOption format
 * @param courseId courseId of the course
 * @param isParent check if I'm directly managing the parent course
 * @param parentId reference to the parent course
 * @returns language in a format valid to perform a chip selection without clicking directly the chip
 */
export const formatLanguageAsCourseLanguage = (
    selectedLanguage: DropdownOption,
    courseId: string,
    isParent: boolean,
    parentId: string
): CourseLanguage => {

    const language: CourseLanguage = {
        code: selectedLanguage.value,
        courseId: courseId,
        description: selectedLanguage.label,
        isParent: isParent,
        label: selectedLanguage.label,
        parentId: parentId,
        value: selectedLanguage.label
    }
    return language
}

export const findMasterChildByLang = (mapping: CountryLanguagesMapping, lang: string) => {
    for (const country in mapping) {
        const items = mapping[country];
        const masterChildItem = items.find(item => item.language === lang && item.masterChild === true);

        if (masterChildItem) {
            return masterChildItem;
        }
    }
    return null;
};

/**
 * Function to get specific child course based on parent course and child courseId
 * @param parentCourse the course parent
 * @param childId of a specific parents' child course 
 * @returns child Course if found otherwise null
 */
export const getCourseChildFromParentCourse = (
    parentCourse: Course,
    childId: string
): Course | null => {
    if(parentCourse 
        && parentCourse.childCourses 
        && parentCourse.childCourses.length > 0
        && parentCourse.childCourses[childId]
    ) {
        console.log("🚀 ~ parentCourse.childCourses :", parentCourse.childCourses )
        console.log("🚀 ~ parentCourse.childCourses[childId]:", parentCourse.childCourses[childId])

        return parentCourse.childCourses[childId]
    }
    return null
}
