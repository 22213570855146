import { OptionTypeBase } from "react-select";
import { ErrorObj } from "../ErrorMessageV2";

export type SelectProps = {
    className?: string;
    name?: string;
    id?: string;
    value?: any | null;
    options: OptionTypeBase[];
    onChange?: (el: any) => void;
    placeholder?: string;
    ariaLabel?: string;
    label?: string;
    hasSearch?: boolean;
    mandatory?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
    error?: ErrorObj;
    setError?: any;
    inputRef?: React.RefObject<HTMLInputElement>;
    arrow?: any;
    tabIndex?: string;
    variation?: SelectVariations
}

export enum SelectVariations {
    small = 'small',
    big = 'big'
}