import * as actionTypes from '../store/actions/actionTypes';
import React from 'react';
import { L1Element } from '@model/L1Element';
import {
  isCourseMaster,
  isCourseOnBoarding,
  isSupportLessonInOnboarding,
} from './onBoardingCourseUtility';
import { addIsNotYetAvailableAttribute, isLessonNotOptionalForLP } from './LPUtility';
import LeonardoIcon from '@images/logo-app/leonardo.svg';
import {
  sessionStatus,
  ctype,
  courseStatesConst,
  courseStatus,
  SENDER_SYSTEM,
  TAGS,
  COOKIE,
  HEADER,
  BADGE,
  SORTBY,
  DURATION,
  SESSION_SCHEDULE,
  BRAND_TYPE,
  USER_TYPE,
  L0_IDS,
  activityState,
  EXPIRATION_KEYS,
  queryParams,
  FALLBACK_IMAGE_COVER_URL,
  FILTER_COMPLEXITY_CATEGORY,
} from './const';
import { LEADERBOARD_PRESENCE } from '@model/User';
import { getCookie } from './cookie';
import { compareCourses } from '@utility/sortUtility';
import dompurify from 'dompurify';
const Moment = require('moment');
const MomentRange = require('moment-range');

const moment = MomentRange.extendMoment(Moment);
const momentTimezone = require('moment-timezone');

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

/* add info helpful for visualization to the course obj  */
export const enrichCourseInfo = (course, coursesMap, preferredLang) => {
  if (!course) {
    console.trace('enrichCourseInfo null', course);
    return null;
  }

  //add to the children the child contained in the master
  course.childCourses = course.childCourses || [];
  if (course.courseId && course.courseId !== course.courseIdMaster) {
    const indexChildLanguage = course.childCourses.findIndex(a => a.childId === course.courseId);
    const newChild = {
      childId: course.courseId,
      courseFullName: course.courseFullName,
      language: course.language,
      courseSummary: course.courseSummary,
      courseOverviewFile: course.courseOverviewFile,
      tags: course.tags,
      // credits: course.credits,
      duration: course.duration,
      learningPath: course.learningPath,
      liveInfo: course.liveInfo,
      courseSessions: course.courseSessions,
      userRelevantSessions: course.userRelevantSessions,
      vimeoRecNotification: !!course?.vimeoRecNotification ?? false,
      // userCredits: course.userCredits,
      courseStates: course.courseStates,
    };

    if (indexChildLanguage < 0) {
      //add the child included within the parent
      newChild.credits = course.credits;
      newChild.userCredits = course.userCredits;

      course.childCourses.push(newChild);
    } else {
      //update child with parent info (/newuni/bff/course/v2/catalog?courseId=<courseId> returns the child info within the parent --> store it in the child too)
      course.childCourses[indexChildLanguage] = {
        ...course.childCourses[indexChildLanguage],
        ...newChild,
      };
    }
  }

  let childCourse = getCourseToDisplay(course, preferredLang);
  let obj = completedLinksToShow(course);

  let tags = childCourse.tags ? childCourse.tags : [];
  let courseTags = course.tags ? course.tags : [];
  if (
    courseTags &&
    isTagPresent(courseTags, TAGS.isMobileNotOptimized) &&
    !isTagPresent(tags, TAGS.isMobileNotOptimized)
  ) {
    tags.push(TAGS.isMobileNotOptimized);
  }
  if (
    courseTags &&
    isTagPresent(courseTags, TAGS.isSubtitledVideo) &&
    !isTagPresent(tags, TAGS.isSubtitledVideo)
  ) {
    tags.push(TAGS.isSubtitledVideo);
  }
  //check tags for subtitles
  //GET ONLY CHILD SUBTITLES
  // if (courseTags) {
  //   courseTags
  //     .filter(a => a.includes(TAGS.subtitle))
  //     .forEach(subtitleTag => {
  //       if (!tags.includes(subtitleTag)) {
  //         tags.push(subtitleTag);
  //       }
  //     })
  // }
  //check tag hasVideoPreview
  if (courseTags) {
    courseTags
      .filter(a => a.includes(TAGS.hasVideoPreview))
      .forEach(hasVideoPreviewTag => {
        if (!tags.includes(hasVideoPreviewTag)) {
          tags.push(hasVideoPreviewTag);
        }
      });
  }
  if (courseTags && isTagPresent(courseTags, TAGS.ecpu) && !isTagPresent(tags, TAGS.ecpu)) {
    tags.push(TAGS.ecpu);
  }
  if (courseTags && isTagPresent(courseTags, TAGS.abo) && !isTagPresent(tags, TAGS.abo)) {
    tags.push(TAGS.abo);
  }
  if (
    courseTags &&
    isTagPresent(courseTags, TAGS.noStandalone) &&
    !isTagPresent(tags, TAGS.noStandalone)
  ) {
    tags.push(TAGS.noStandalone);
  }

  let newCourse = {
    ...course,
    affinity: course.affinity,
    // courseVisible: course.courseVisible,
    endDate: course.endDate,
    language: childCourse.language,
    // childCourseId: childCourse.courseId,
    courseId: childCourse.childId || course.courseId,
    courseIdMaster: course.courseIdMaster,
    courseFullName: course.courseFullName.toUpperCase(),
    courseSummary: childCourse.courseSummary || course.courseSummary,
    courseOverviewFileParent: course.courseOverviewFile,
    courseOverviewFile: childCourse.courseOverviewFile || course.courseOverviewFile,
    tags: tags,
    // b2bbrand: course.b2bbrand,
    ctype: course.ctype,
    weight: course.weight,
    credits: course.credits,
    duration: course.duration,
    catalogTypes: course.catalogTypes || getKeysFromEnriched(course.catalogTypesEnriched) || [],
    totalLikes: course.totalLikes,
    childCourses: course.childCourses,
    learningPath: childCourse.learningPath || course.learningPath,
    courseSessions: course.courseSessions,
    // parentCourses: course.parentCourses,
    quickLearning: !!course?.quickLearning ?? false,
    // fallback: course.fallback,
    like: course.like,
    wishlist: !!course?.wishlist ?? false,
    isNew: !!course?.isNew ?? false,
    userCourseStatus: course.userCourseStatus,
    courseCompletionDate: course.courseCompletionDate,
    mandatory: !!course?.mandatory ?? false,
    expectedCompletion: course.expectedCompletion,
    highlight: !!course?.highlight ?? false,
    recommended: !!course?.recommended ?? false,
    level2: course.level2,
    userRelevantSessions: course.userRelevantSessions,
    feedbackLinks: obj.feedbackLinks,
    certificateLinks: course.certificateLinks,
    dayLeft: course.dayLeft,
    courseStates: course.courseStates,
    courseDetails: course.courseDetails,
    numActivities: course.numActivities,
    parentLP: [],
    showJoin: course.showJoin,
    showLiveBadge: course.showLiveBadge,
    showLiveCountdown: course.showLiveCountdown,
    startDate: course.startDate,
    userCredits: course.userCredits,
    selection: course.selection,
    teacher: course.teacher,
    userRating: course.userRating,
    rating: course.rating,
    rateNr: course.rateNr,
    complexityId: course.complexityId,
    macroCategory: course.macroCategory,
    level0: course.level0 || course.level0Enriched?.key || '',
    level0List: course.level0List || course.level0EnrichedList?.map(a => a.key) || [],
    blockedby: course.blockedby,
    liveInfo: course.liveInfo,
    vimeoRecNotification: !!course?.vimeoRecNotification ?? false,
    priceable: !!course?.priceable ?? false,
    price: course.price,
    buyed: !!course?.buyed ?? false,
    buyedDate: course.buyedDate,
    levelTag: course.levelTag,
  };

  // GET TOT COMPLETED COURSES FOR LEARNING PATH
  if (!isLearningPath(newCourse)) {
    //CHECK IF IT'S CONTAINED IN LP
    for (let courseId in coursesMap) {
      let LPToCheck = coursesMap[courseId];
      if (isLearningPath(LPToCheck)) {
        LPToCheck.learningPath.map(lpCourse => {
          if (lpCourse.parentId === newCourse.courseIdMaster) {
            if (!newCourse.parentLP.includes(LPToCheck.courseFullName)) {
              newCourse.parentLP.push({
                courseFullName: LPToCheck.courseFullName,
                courseId: LPToCheck.courseIdMaster,
                courseIdChild: LPToCheck.courseId,
                isMaster: isCourseMaster(LPToCheck),
              });
            }
          }
        });
      }
    }
  }
  // GET LANGUAGE LISTS PER COURSE
  newCourse = getLanguagesPerCourse(newCourse);

  //ADD RANDOM IMAGE FOR FALLBACK
  let numberPhoto = Math.round(Math.random() * 3);
  newCourse.fallbackImage = getFallbackImage(numberPhoto + 1);

  // console.log(newCourse);

  return newCourse;
};

/* check if course has ever been started by the user*/
export const getHasBeenStartedCourse = (course) => {
  return course?.userCourseStatus === courseStatus.ENROLLED
    || course?.userCourseStatus === courseStatus.COMPLETED
    || course.courseStates?.includes(courseStatesConst.IN_PROGRESS)
    || course.courseStates?.includes(courseStatesConst.ATTENDED)
};

/* add info helpful for visualization to the course obj  */
export const enrichChildInfo = (course, child) => {
  let newCourse = JSON.parse(JSON.stringify(course));

  let languageToPrint = {
    value: child.language,
    label: child.language,
    courseId: child.childId,
    isParent: false,
    parentId: course.courseIdMaster,
  };

  newCourse = {
    ...newCourse,
    language: child?.language,
    courseId: child?.childId,
    courseFullName: child?.courseFullName || course?.courseFullName,
    courseSummary: child?.courseSummary || course?.courseSummary,
    courseOverviewFile: child?.courseOverviewFile || course?.courseOverviewFile,
    tags: child?.tags || course?.tags,
    courseDetails: child.courseDetails ? child.courseDetails : [],
    numActivities: child.numActivities ? child.numActivities : [],
    languageToPrint,
    teacher: child?.teacher || course?.teacher,
  };

  if (isLearningPath(course)) {
    course.childCourses?.map(childLP => {
      if ((childLP.childId === child.childId) && !!child.learningPath) {
        newCourse.learningPath = childLP.learningPath;
      } else {
        newCourse.learningPath = course.learningPath
      }
    });
  }
  return newCourse;
};

/* CHECK IF KEYS INSIDE OBJ HS VALUES */
export const checkIfObjHasValues = obj => {
  let retVal = false;

  Object.keys(obj).map(key => {
    let field = obj[key];
    if (field === null) return;

    if (typeof field === 'object') {
      if (Object.keys(field).length > 0) retVal = true;
    }

    if (typeof field === 'array') {
      if (field.length > 0) retVal = true;
    }
  });

  return retVal;
};

export const getAvatar = user => {
  if (user.firstName && user.lastName) {
    return user.firstName.substring(0, 1) + user.lastName.substring(0, 1);
  }
};

export const getInitialsFromFullName = fullName => {
  if (typeof fullName === 'string') {
    const fullNameArray = fullName.split(' ');
    if (fullNameArray.length > 2) {
      return fullName.charAt('0');
    }
    if (fullNameArray.length === 1) {
      return fullName.substring(0, 2).toUpperCase();
    }
    const [firstName, lastName] = fullNameArray;
    return getAvatar({ firstName, lastName });
  }
  console.warn('No name found');
  return '';
};

/* changes from arrayName to ARRAY_NAME */
export const fromCamelTo_ = string => {
  return string.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();
};

/* changes from arrayName to Array name */
export const fromCamelToCapitalize = string => {
  let twoWords = string?.replace(/([a-z])([A-Z])/g, '$1 $2');
  return twoWords.charAt(0).toUpperCase() + twoWords.substring(1);
};

/* changes from self_learning_lesson to SELF LEARNING LESSON*/
export const from_ToCapitalized = string => {
  return string.replace(/_/g, ' ').toUpperCase();
};

/* FIRST LETTER CAPITALIZED */
export const toTitleCase = str => {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
};

/* FIRST LETTER CAPITALIZED */
export const toSurnameCase = (str, ifUpperCase) => {
  if (!str) {
    return '';
  }
  if (ifUpperCase && str !== str.toUpperCase()) {
    return str;
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const firstLetterToUpperCase = str => {
  if (!str) {
    return '';
  }

  const arr = str.split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i][0]?.toUpperCase() + arr[i].substr(1).toLowerCase();
  }

  return arr.join(' ');
};

export const compareDates = (a, b, sorting = 'desc') => {
  //check if undefined or null
  if ((!a && !b) || a === b) {
    return 0;
  } else if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  }

  let dateA = new Date(a);
  let dateB = new Date(b);

  if (sorting === 'desc') {
    return dateA > dateB ? -1 : 1;
  } else {
    return dateA > dateB ? 1 : -1;
  }
};
export const comparePrices = (a, b, sorting = 'desc', handleUndefined = true) => {
  //check if undefined or null
  if ((!a && !b) || a === b) {
    return 0;
  } else if (!a) {
    if (handleUndefined) {
      return sorting === 'desc' ? 1 : -1;
    } else {
      return 1;
    }
  } else if (!b) {
    if (handleUndefined) {
      return sorting === 'desc' ? -1 : 1;
    } else {
      return -1;
    }
  }

  if (sorting === 'desc') {
    return a > b ? -1 : 1;
  } else {
    return a > b ? 1 : -1;
  }
};

export const printCtype = (ctypes, lang, translate = null) => {
  if (!ctypes || ctypes.length < 1) {
    console.error('method printCtype(): ctypes is not an array');
    return '';
  }

  if (ctypes.includes(ctype.MULTIACTIVITY)) {
    return translate ? translate('CTYPE_MULTIACTIVITY') : lang.CTYPE_MULTIACTIVITY;
  }

  //remove multiactivity
  ctypes = ctypes.filter(a => a !== ctype.MULTIACTIVITY);
  const ctypeCourse = ctypes[0];

  let keyLabel = '';
  switch (ctypeCourse) {
    case ctype.ONLINE_COURSE:
    case ctype.INTERACTIVE:
      keyLabel = 'CTYPE_INTERACTIVE';
      break;
    case ctype.FACE_TO_FACE:
      keyLabel = 'CTYPE_FACE_TO_FACE';
      break;
    case ctype.VIRTUAL_CLASSROOM:
      keyLabel = 'CTYPE_VIRTUAL_CLASSROOM';
      break;
    case ctype.LEARNING_PATH:
      keyLabel = 'CTYPE_LEARNING_PATH';
      break;
    case ctype.PDF:
      keyLabel = 'CTYPE_PDF';
      break;
    case ctype.EXTERNAL_CONTENT:
      keyLabel = 'CTYPE_EXTERNAL_CONTENT';
      break;
    case ctype.VIDEO:
      keyLabel = 'CTYPE_VIDEO';
      break;
    case ctype.PODCAST:
      keyLabel = 'CTYPE_PODCAST';
      break;
    case ctype.LIVESTREAM:
      keyLabel = 'CTYPE_LIVESTREAM';
      break;
    default:
      // console.log(ctypeCourse);
      return '';
  }

  if (translate) {
    const label = translate(keyLabel);
    return label ? label : ctype;
  } else {
    return lang?.[keyLabel] ? lang[keyLabel] : '';
  }
};

export const getActivityCtype = activity => {
  if (!activity) {
    return '';
  }

  const type = activity.type;
  const tags = activity.tags;

  switch (activity.type) {
    case 'scorm':
      return ctype.INTERACTIVE;
    case 'resource':
      return ctype.PDF;
    case 'facetoface':
      let classroomType = ctype.VIRTUAL_CLASSROOM;

      tags.forEach(tag => {
        if (tag.includes(TAGS.isActivityFaceToFace)) {
          classroomType = ctype.FACE_TO_FACE;
        }
      });

      return classroomType;
    case 'url':
      let activityCtype = type;
      if (tags) {
        activity.tags.forEach(tag => {
          if (tag.includes(TAGS.isActivityVideo)) {
            activityCtype = ctype.VIDEO;
          }

          if (tag.includes(TAGS.isActivityPodcast)) {
            activityCtype = ctype.PODCAST;
          }
        });
      }
      return activityCtype;
    default:
      return type;
  }
};

export const printActivityType = (activity, lang) => {
  if (!activity) {
    return '';
  }

  const type = getActivityCtype(activity);

  switch (type) {
    case ctype.INTERACTIVE:
      return lang.ACTIVITY_SCORM;
    case ctype.PDF:
      return lang.ACTIVITY_PDF;
    case ctype.FACE_TO_FACE:
      return lang.CTYPE_FACE_TO_FACE;
    case ctype.VIRTUAL_CLASSROOM:
      return lang.CTYPE_VIRTUAL_CLASSROOM;
    case 'url':
      return lang.CTYPE_EXTERNAL_CONTENT;
    case ctype.VIDEO:
      return lang.ACTIVITY_VIDEO;
    case ctype.PODCAST:
      return lang.ACTIVITY_AUDIO;
    default:
      return type;
  }
};

/* UPDATE ALL ARRAY WHERE THE NEW COURSE IS CONTAINED */
export const updateCourseArrays = (
  dispatch,
  getState,
  courseIdSubarrayMap,
  newCourse,
  shouldSkipVC = false
) => {
  let id = newCourse.courseIdMaster;

  if (courseIdSubarrayMap[id]) {
    courseIdSubarrayMap[id].map(subArray => {
      let arrayToChange = cloneDeep(getState().course[subArray]);

      if ((subArray === 'virtualClassrooms' || subArray === 'onlineCourses') && !shouldSkipVC) {
        let indexList = [];

        for (let y = 0; y < arrayToChange.length; y++) {
          for (let i = 0; i < arrayToChange[y].length; i++) {
            if (arrayToChange[y][i].courseIdMaster === id) {
              indexList.push({
                indexCourse: i,
                indexArray: y,
              });
              break;
            }
          }
        }

        indexList.forEach(indexs => {
          arrayToChange[indexs.indexArray].splice(indexs.indexCourse, 1, newCourse);
        });
      } else {
        if (arrayToChange.length === 0) {
          arrayToChange.push(newCourse);
        } else {
          let index = null;
          for (let i = 0; i < arrayToChange.length; i++) {
            if (arrayToChange[i].courseIdMaster === id) {
              index = i;
              break;
            }
          }
          if (index !== null) arrayToChange.splice(index, 1, newCourse);
          else {
            if (arrayToChange.length !== 0) arrayToChange.push(newCourse);
          }
        }
      }

      let actionType = 'GET_' + fromCamelTo_(subArray);
      dispatch({
        type: actionTypes[actionType],
        [subArray]: arrayToChange,
        courseIdSubarrayMap,
      });
    });
  }
};

export const deleteFromAllArrays = (courseIdSubarrayMap, id, dispatch, getState) => {
  if (courseIdSubarrayMap[id]) {
    courseIdSubarrayMap[id].map(subArray => {
      let arrayToChange = cloneDeep(getState().course[subArray]);

      if (subArray === 'virtualClassrooms' || subArray === 'onlineCourses') {
        let indexList = [];

        for (let y = 0; y < arrayToChange.length; y++) {
          for (let i = 0; i < arrayToChange[y].length; i++) {
            if (arrayToChange[y][i].courseIdMaster === id) {
              indexList.push({
                indexCourse: i,
                indexArray: y,
              });
              break;
            }
          }
        }

        indexList.forEach(indexs => {
          arrayToChange[indexs.indexArray].splice(indexs.indexCourse, 1);
        });
      } else {
        if (arrayToChange.length !== 0) {
          let index = null;
          for (let i = 0; i < arrayToChange.length; i++) {
            if (arrayToChange[i].courseIdMaster === id) {
              index = i;
              break;
            }
          }
          if (index !== null) arrayToChange.splice(index, 1);
        }
      }

      let actionType = 'GET_' + fromCamelTo_(subArray);
      dispatch({
        type: actionTypes[actionType],
        [subArray]: arrayToChange,
        courseIdSubarrayMap,
      });
    });
  }
};

/* INSERT IN MAP OF ID-ARRAY */
export const insertInMap = (map, id, arrayName) => {
  if (map[id]) {
    let isAlreadyPresent = false;
    map[id].map(name => (name === arrayName ? (isAlreadyPresent = true) : null));
    if (!isAlreadyPresent) map[id].push(arrayName);
  } else {
    map[id] = [arrayName];
  }
  return map;
};

/* DELETE  FROM MAP OF ID-ARRAY */
export const deleteFromMap = (map, id, arrayName) => {
  let names = map[id];

  if (names && names.length > 1) {
    let index = names.findIndex(e => e === arrayName);
    names.splice(index, 1);
  } else if (names && names.length === 1 && names[0] === arrayName) {
    delete map[id];
  } else {
    names = [arrayName];
  }

  return map;
};

/*** FORMAT OPTIONS WHEN ARRAY OF OBJ ***/
export const formatOptions = (optionList, labelProp) => {
  return orderBy(
    optionList.map(item => {
      return {
        ...item,
        label: item[labelProp],
        value: item[labelProp],
      };
    }),
    ['label']
  );
};

/* SCROLL TO TOP */
export const goToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const scrollToTop = () => {
  const wrapper = document.querySelector('html');
  if (wrapper) {
    wrapper.scrollTo({ top: 0 });
  }
};

/* HEADERS */
export const getHeaders = (contentType = 'application/json') => {
  return {
    /* [HEADER.MOODLE_SESSION]: getCookie(COOKIE.MOODLE_SESSION),
    [HEADER.USER_TOKEN]: getCookie(COOKIE.USER_TOKEN), */
    'content-type': contentType ? contentType : 'application/json',
    'strict-transport-security': 'max-age=31536000',
    'Content-Security-Policy': 'policy',
    'X-Frame-Options': 'DENY',
    'X-Content-Type-Options': 'nosniff',
    'Referrer-Policy': 'no-referrer',
    'Feature-Policy': "microphone 'self' camera 'self' geolocation 'self'",
  };
};

export const printDate = (date, lang, yearTwoDigits = false) => {
  if (date) {
    let month = '';
    let day = '';
    let year = '';
    let monthToPrint = '';

    if (date instanceof Date || typeof date == 'number') {
      const dateTemp = new Date(date);
      day = numberTwoDigits(dateTemp.getDate()).toString();
      month = numberTwoDigits(dateTemp.getMonth() + 1).toString();
      year = dateTemp.getFullYear();
    } else {
      month = date.substring(5, 7);
      day = date.substring(8, 10);
      year = date.substring(0, 4);
    }

    if (yearTwoDigits) {
      year = year.slice(2);
    }

    switch (month) {
      case '01':
        monthToPrint = lang.ABBR_JAN_LABEL ? lang.ABBR_JAN_LABEL : '';
        break;
      case '02':
        monthToPrint = lang.ABBR_FEB_LABEL ? lang.ABBR_FEB_LABEL : '';
        break;
      case '03':
        monthToPrint = lang.ABBR_MAR_LABEL ? lang.ABBR_MAR_LABEL : '';
        break;
      case '04':
        monthToPrint = lang.ABBR_APR_LABEL ? lang.ABBR_APR_LABEL : '';
        break;
      case '05':
        monthToPrint = lang.ABBR_MAY_LABEL ? lang.ABBR_MAY_LABEL : '';
        break;
      case '06':
        monthToPrint = lang.ABBR_JUN_LABEL ? lang.ABBR_JUN_LABEL : '';
        break;
      case '07':
        monthToPrint = lang.ABBR_JUL_LABEL ? lang.ABBR_JUL_LABEL : '';
        break;
      case '08':
        monthToPrint = lang.ABBR_AUG_LABEL ? lang.ABBR_AUG_LABEL : '';
        break;
      case '09':
        monthToPrint = lang.ABBR_SEP_LABEL ? lang.ABBR_SEP_LABEL : '';
        break;
      case '10':
        monthToPrint = lang.ABBR_OCT_LABEL ? lang.ABBR_OCT_LABEL : '';
        break;
      case '11':
        monthToPrint = lang.ABBR_NOV_LABEL ? lang.ABBR_NOV_LABEL : '';
        break;
      case '12':
        monthToPrint = lang.ABBR_DEC_LABEL ? lang.ABBR_DEC_LABEL : '';
        break;
    }

    return day + ' ' + monthToPrint + ' ' + year;
  }

  return '';
};

export const printDateEvents = (date1, date2, lang) => {
  let firstDate = printDate(date1, lang);
  let lastDate = printDate(date2, lang);

  const firstDateSplitted = firstDate?.split(' ');
  const lastDateSplitted = lastDate?.split(' ');

  if (date1 && date2) {
    if (firstDateSplitted[2] === lastDateSplitted[2]) {
      if (
        firstDateSplitted[0] === lastDateSplitted[0] &&
        firstDateSplitted[1] === lastDateSplitted[1]
      ) {
        return firstDateSplitted[0] + ' ' + firstDateSplitted[1] + ' ' + firstDateSplitted[2];
      } else {
        if (firstDateSplitted[1] === lastDateSplitted[1]) {
          return (
            firstDateSplitted[0] +
            ' - ' +
            lastDateSplitted[0] +
            ' ' +
            lastDateSplitted[1] +
            ' ' +
            firstDateSplitted[2]
          );
        } else {
          return (
            firstDateSplitted[0] +
            ' ' +
            firstDateSplitted[1] +
            ' - ' +
            lastDateSplitted[0] +
            ' ' +
            lastDateSplitted[1] +
            ' ' +
            firstDateSplitted[2]
          );
        }
      }
    } else {
      return firstDate + ' - ' + lastDate;
    }
  }
  return '';
};

export const printDayAndTime = (date, lang) => {
  if (date) {
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);
    let year = date.substring(0, 4);
    let monthToPrint = '';

    switch (month) {
      case '01':
        monthToPrint = lang.ABBR_JAN_LABEL ? lang.ABBR_JAN_LABEL : '';
        break;
      case '02':
        monthToPrint = lang.ABBR_FEB_LABEL ? lang.ABBR_FEB_LABEL : '';
        break;
      case '03':
        monthToPrint = lang.ABBR_MAR_LABEL ? lang.ABBR_MAR_LABEL : '';
        break;
      case '04':
        monthToPrint = lang.ABBR_APR_LABEL ? lang.ABBR_APR_LABEL : '';
        break;
      case '05':
        monthToPrint = lang.ABBR_MAY_LABEL ? lang.ABBR_MAY_LABEL : '';
        break;
      case '06':
        monthToPrint = lang.ABBR_JUN_LABEL ? lang.ABBR_JUN_LABEL : '';
        break;
      case '07':
        monthToPrint = lang.ABBR_JUL_LABEL ? lang.ABBR_JUL_LABEL : '';
        break;
      case '08':
        monthToPrint = lang.ABBR_AUG_LABEL ? lang.ABBR_AUG_LABEL : '';
        break;
      case '09':
        monthToPrint = lang.ABBR_SEP_LABEL ? lang.ABBR_SEP_LABEL : '';
        break;
      case '10':
        monthToPrint = lang.ABBR_OCT_LABEL ? lang.ABBR_OCT_LABEL : '';
        break;
      case '11':
        monthToPrint = lang.ABBR_NOV_LABEL ? lang.ABBR_NOV_LABEL : '';
        break;
      case '12':
        monthToPrint = lang.ABBR_DEC_LABEL ? lang.ABBR_DEC_LABEL : '';
        break;
    }

    return day + ' ' + monthToPrint + ' ' + year + ' ' + date.substring(11, 16);
    //return day + " " + monthToPrint + " " + year + " ";
  }

  return '';
};

export const formatTimeInAMPM = date => {
  const newDate = date.split(':');
  let hour = parseInt(newDate[0]);
  let minutes = parseInt(newDate[1]);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  let hours;

  if (hour != 12) {
    hours = hour % 12;
  } else {
    hours = 12;
  }
  if (hour === 0) {
    hours = 12;
  }
  if (hours <= 9) {
    hours = '0' + hours;
  }
  if (minutes <= 9) {
    minutes = '0' + minutes;
  }
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const printDayAndTimeAMPM = (date, lang) => {
  if (date) {
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);
    let year = date.substring(0, 4);
    let monthToPrint = '';

    switch (month) {
      case '01':
        monthToPrint = lang.ABBR_JAN_LABEL ? lang.ABBR_JAN_LABEL : '';
        break;
      case '02':
        monthToPrint = lang.ABBR_FEB_LABEL ? lang.ABBR_FEB_LABEL : '';
        break;
      case '03':
        monthToPrint = lang.ABBR_MAR_LABEL ? lang.ABBR_MAR_LABEL : '';
        break;
      case '04':
        monthToPrint = lang.ABBR_APR_LABEL ? lang.ABBR_APR_LABEL : '';
        break;
      case '05':
        monthToPrint = lang.ABBR_MAY_LABEL ? lang.ABBR_MAY_LABEL : '';
        break;
      case '06':
        monthToPrint = lang.ABBR_JUN_LABEL ? lang.ABBR_JUN_LABEL : '';
        break;
      case '07':
        monthToPrint = lang.ABBR_JUL_LABEL ? lang.ABBR_JUL_LABEL : '';
        break;
      case '08':
        monthToPrint = lang.ABBR_AUG_LABEL ? lang.ABBR_AUG_LABEL : '';
        break;
      case '09':
        monthToPrint = lang.ABBR_SEP_LABEL ? lang.ABBR_SEP_LABEL : '';
        break;
      case '10':
        monthToPrint = lang.ABBR_OCT_LABEL ? lang.ABBR_OCT_LABEL : '';
        break;
      case '11':
        monthToPrint = lang.ABBR_NOV_LABEL ? lang.ABBR_NOV_LABEL : '';
        break;
      case '12':
        monthToPrint = lang.ABBR_DEC_LABEL ? lang.ABBR_DEC_LABEL : '';
        break;
    }

    return day + ' ' + monthToPrint + ' ' + year + ' ' + formatTimeInAMPM(date.substring(11, 16));
  }

  return '';
};

export const getTimeByString = dateString => {
  return dateString.substring(11, 16);
};

export const getDateCard = (sessionDates, lang) => {
  let timeStart = sessionDates[0].timeStart;
  let month = timeStart.substring(5, 7);
  let day = timeStart.substring(8, 10);
  let monthToPrint = getMonthToPrint(month, lang);
  let year = timeStart.substring(0, 4);

  return day + ' ' + monthToPrint + ' ' + year;
};

export const formatDateInAMPM = date => {
  const newDate = date.split('.');
  let hour = parseInt(newDate[0]);
  let minutes = parseInt(newDate[1]);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  let hours;

  if (hour != 12) {
    hours = hour % 12;
  } else {
    hours = 12;
  }
  if (hour === 0) {
    hours = 12;
  }
  if (hours <= 9) {
    hours = '0' + hours;
  }
  if (minutes <= 9) {
    minutes = '0' + minutes;
  }
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const getHoursCard = (sessionDates, formatAMPM) => {
  let timeStart = sessionDates[0].timeStart.substring(11, 16);
  let timeFinish = sessionDates[0].timeFinish.substring(11, 16);
  timeStart = timeStart.replace(':', '.');
  timeFinish = timeFinish.replace(':', '.');

  if (formatAMPM) {
    timeStart = formatDateInAMPM(timeStart);
    timeFinish = formatDateInAMPM(timeFinish);
  }
  return timeStart + ' - ' + timeFinish;
};

export const getStartTimeCard = sessionDates => {
  return getTimeByString(sessionDates[0].timeStart);
};

const roundMinutesForLearningPath = (d) => {
  let totalMinutes = Math.ceil(d / 60);
  const hoursInMinutes = Math.floor(totalMinutes / 60) * 60;
  const minutesLeft = totalMinutes % 60;
 
  if (minutesLeft !== 0 && minutesLeft < 5) {
    totalMinutes = hoursInMinutes + 5;
  } else if (minutesLeft > 55) {  
    totalMinutes = hoursInMinutes + 60;  
  } else {
    const fiveMultiple = Math.ceil(minutesLeft / 5) * 5;
    totalMinutes = hoursInMinutes + fiveMultiple;
  }
 
  return {
    hours: Math.floor(totalMinutes / 60),
    minutes: totalMinutes % 60,
  };
};

export const printDuration = (d, lang, roundsDate, minuteLabelShort = false, course = null) => {
  d = Number(d);
  if (d === 0) return d;
  let week = Math.floor(d / 604800),
    day = week > 0 ? Math.floor((d % 604800) / 86400) : Math.floor(d / 86400);
  let h, m;

  //LEON-8791
  if (course && isLearningPath(course)) {
    const rounded = roundMinutesForLearningPath(d);
    h = rounded.hours;
    m = rounded.minutes;
  } else {
    h = day > 0 ? Math.floor((d % 86400) / 3600) : Math.floor(d / 3600),
    m = Math.floor((d % 3600) / 60);
  }

  let labelWeek = lang.DURATION_WEEK_LABEL ? lang.DURATION_WEEK_LABEL : 'w',
    labelDay = lang.DURATION_DAY_LABEL ? lang.DURATION_DAY_LABEL : 'd',
    labelHour = lang.DURATION_HOUR_LABEL ? lang.DURATION_HOUR_LABEL : 'h',
    labelMinute = minuteLabelShort
      ? 'm'
      : lang.DURATION_MIN_LABEL
        ? lang.DURATION_MIN_LABEL
        : 'min';

  let weekDisplay = week > 0 ? week + labelWeek + ' ' : '',
    dayDisplay = day > 0 ? day + labelDay + ' ' : '',
    hDisplay = h > 0 ? h + labelHour + ' ' : '',
    mDisplay = m > 0 ? m + labelMinute : '';

  let showMinute = dayDisplay ? '' : mDisplay;
  let showHour = weekDisplay ? '' : hDisplay;

  const date = roundsDate
    ? weekDisplay + dayDisplay + showHour + showMinute
    : weekDisplay + dayDisplay + hDisplay + mDisplay;

  return date;
};

export const printDurationHourFormat = (d, lang, translate, showMin, showZero, course = null) => {
  if (!d && !showZero) {
    return '';
  }

  d = Number(d);
  if (d === 0 && !showZero) return d;

  let h,m;

  //LEON-8791
  if (course && isLearningPath(course)) {
    const rounded = roundMinutesForLearningPath(d);
    h = rounded.hours;
    m = rounded.minutes;
  } else {
    h = Math.floor(d / 3600);
    m = Math.floor((d % 3600) / 60);
  }

  let labelHour = '',
    labelMinute = '';

  if (translate) {
    labelHour = translate('DURATION_HOUR_LABEL');
    labelMinute = translate('DURATION_MIN_LABEL');
  } else if (showMin === true) {
    hDisplay = h + labelHour + ' ' + m + labelMinute;
  } else {
    labelHour = lang.DURATION_HOUR_LABEL ? ` ${lang.DURATION_HOUR_LABEL}` : 'h';
    labelMinute = lang.DURATION_MIN_LABEL ? ` ${lang.DURATION_MIN_LABEL}` : 'min';
  }

  let hDisplay = '';
  if (h < 1) {
    hDisplay = m > 0 || showZero ? m + labelMinute : '';
  } else {
    if (m === 0) {
      hDisplay = h + labelHour;
    } else {
      hDisplay = h + labelHour + ' ' + m + labelMinute;
    }
  }

  return hDisplay;
};

export const getDate = timeStart => {
  return timeStart.substring(8, 10);
};

export const getMonth = timeStart => {
  return timeStart.substring(5, 7);
};

export const getYear = timeStart => {
  return timeStart.substring(0, 4);
};

export const getMonthToPrint = (month, lang) => {
  let monthToPrint = '';

  switch (month) {
    case '01':
    case '1':
      monthToPrint = lang.ABBR_JAN_LABEL ? lang.ABBR_JAN_LABEL : '';
      break;
    case '02':
    case '2':
      monthToPrint = lang.ABBR_FEB_LABEL ? lang.ABBR_FEB_LABEL : '';
      break;
    case '03':
    case '3':
      monthToPrint = lang.ABBR_MAR_LABEL ? lang.ABBR_MAR_LABEL : '';
      break;
    case '04':
    case '4':
      monthToPrint = lang.ABBR_APR_LABEL ? lang.ABBR_APR_LABEL : '';
      break;
    case '05':
    case '5':
      monthToPrint = lang.ABBR_MAY_LABEL ? lang.ABBR_MAY_LABEL : '';
      break;
    case '06':
    case '6':
      monthToPrint = lang.ABBR_JUN_LABEL ? lang.ABBR_JUN_LABEL : '';
      break;
    case '07':
    case '7':
      monthToPrint = lang.ABBR_JUL_LABEL ? lang.ABBR_JUL_LABEL : '';
      break;
    case '08':
    case '8':
      monthToPrint = lang.ABBR_AUG_LABEL ? lang.ABBR_AUG_LABEL : '';
      break;
    case '09':
    case '9':
      monthToPrint = lang.ABBR_SEP_LABEL ? lang.ABBR_SEP_LABEL : '';
      break;
    case '10':
      monthToPrint = lang.ABBR_OCT_LABEL ? lang.ABBR_OCT_LABEL : '';
      break;
    case '11':
      monthToPrint = lang.ABBR_NOV_LABEL ? lang.ABBR_NOV_LABEL : '';
      break;
    case '12':
      monthToPrint = lang.ABBR_DEC_LABEL ? lang.ABBR_DEC_LABEL : '';
      break;
  }

  return monthToPrint;
};

export const getExtendedMonthToPrint = (month, lang) => {
  const monthNumber = +month;

  const MONTHS = [
    lang.JANUARY_LABEL,
    lang.FEBRUARY_LABEL,
    lang.MARCH_LABEL,
    lang.APRIL_LABEL,
    lang.MAY_LABEL,
    lang.JUNE_LABEL,
    lang.JULY_LABEL,
    lang.AUGUST_LABEL,
    lang.SEPTEMBER_LABEL,
    lang.OCTOBER_LABEL,
    lang.NOVEMBER_LABEL,
    lang.DECEMBER_LABEL,
  ];

  return MONTHS[monthNumber - 1];
};

/* ORDER VIRTUAL CLASSROOMS */
export const orderVirtualClassrooms = virtualClassrooms => {
  //UPCOMING
  //ORDER SESSIONS: asc
  let upcoming = JSON.parse(JSON.stringify(virtualClassrooms[0]));
  upcoming.forEach(course => {
    course.courseSessions = orderSessions(course.courseSessions, 'asc');
  });

  //ORDER COURSES: asc
  upcoming.sort((a, b) => {
    if (a.courseSessions[0] && b.courseSessions[0]) {
      let dateA = new Date(a.courseSessions[0].minStartDate).getTime(),
        dateB = new Date(b.courseSessions[0].minStartDate).getTime();
      return dateA - dateB;
    }
  });

  //SCHEDULED
  //ORDER COURSES: asc
  let scheduled = JSON.parse(JSON.stringify(virtualClassrooms[1]));
  scheduled.forEach(course => {
    let newUserRelevantSessions = [];

    course.courseSessions.forEach(session => {
      course.userRelevantSessions.map(relevant => {
        if (relevant.id === session.id) {
          newUserRelevantSessions.push({ ...session, ...relevant });
        }
      });
    });

    course.userRelevantSessions = newUserRelevantSessions;
  });

  scheduled.sort((a, b) => {
    let sessionA = null;
    let sessionB = null;

    a.userRelevantSessions.map(session => {
      if (isScheduledSession(session)) {
        sessionA = session;
      }
    });

    b.userRelevantSessions.map(session => {
      if (isScheduledSession(session)) {
        sessionB = session;
      }
    });

    let dateA = new Date(sessionA ? sessionA.minStartDate : 0).getTime(),
      dateB = new Date(sessionB ? sessionB.minStartDate : 0).getTime();
    return dateA - dateB;
  });

  //ATTENDED
  //ORDER COURSES: desc
  let attended = JSON.parse(JSON.stringify(virtualClassrooms[2]));

  // attended.forEach(course => {
  //     let newUserRelevantSessions = [];

  //     course.courseSessions.forEach(session => {
  //         course.userRelevantSessions.map(relevant => {
  //             if(relevant.id === session.id){
  //                 newUserRelevantSessions.push({...session, ...relevant})
  //             }
  //         })
  //     })

  //     course.userRelevantSessions = newUserRelevantSessions
  // })

  // attended.sort((a, b) => {
  //     let sessionA = null;
  //     let sessionB = null;

  //     a.userRelevantSessions.map(session => {
  //         if(isAttendedSession(session)){
  //             sessionA = session;
  //         }
  //     })

  //     b.userRelevantSessions.map(session => {
  //         if(isAttendedSession(session)){
  //             sessionB = session;
  //         }
  //     })

  //     let dateA = new Date(sessionA.minStartDate).getTime(),
  //     dateB = new Date(sessionB.minStartDate).getTime();
  //     return dateB - dateA;
  // })

  //MISSED
  //ORDER COURSES: desc
  let missed = JSON.parse(JSON.stringify(virtualClassrooms[3]));

  // missed.forEach(course => {
  //     if(course.userRelevantSessions.length > 0) {
  //         let newUserRelevantSessions = [];

  //         course.courseSessions.forEach(session => {
  //             course.userRelevantSessions.map(relevant => {
  //                 if(relevant.id === session.id){
  //                     newUserRelevantSessions.push({...session, ...relevant})
  //                 }
  //             })
  //         })

  //         course.userRelevantSessions = newUserRelevantSessions

  //     } else {
  //         course.courseSessions = orderSessions(course.courseSessions, "desc")
  //     }
  // })

  // missed.sort((a, b) => {
  //     let dateA = a.userRelevantSessions.length > 0 ? new Date(a.userRelevantSessions[0].minStartDate).getTime() : new Date(a.courseSessions[0].minStartDate).getTime(),
  //     dateB = b.userRelevantSessions.length > 0 ? new Date(b.userRelevantSessions[0].minStartDate).getTime() : new Date(b.courseSessions[0].minStartDate).getTime();
  //     return dateB - dateA;
  // })

  let mandatory = JSON.parse(JSON.stringify(virtualClassrooms[4]));
  mandatory.forEach(course => {
    course.courseSessions = orderSessions(course.courseSessions, 'asc');
  });

  //ORDER COURSES: asc
  mandatory.sort((a, b) => {
    if (a.courseSessions[0] && b.courseSessions[0]) {
      let dateA = new Date(a.courseSessions[0].minStartDate).getTime(),
        dateB = new Date(b.courseSessions[0].minStartDate).getTime();
      return dateA - dateB;
    }
  });

  return [upcoming, scheduled, attended, missed, mandatory];
};

export const orderOnlineCourses = onlineCourses => {
  let inProgress = orderBy(onlineCourses[0], ['weight'], 'desc'),
    expiring = orderBy(onlineCourses[1], ['dayLeft'], 'asc'),
    toBegin = orderBy(onlineCourses[3], ['weight'], 'desc'),
    mandatory = orderBy(onlineCourses[4], ['weight'], 'desc');

  let overdue = JSON.parse(JSON.stringify(onlineCourses[2]));

  overdue.sort((a, b) => {
    let dateA = new Date(a.expectedCompletion).getTime(),
      dateB = new Date(b.expectedCompletion).getTime();
    return dateA - dateB;
  });

  return [inProgress, expiring, overdue, toBegin, mandatory];
};

export const getSortDateTodo = course => {
  if (!course) {
    return null;
  }

  if (isVirtualClassroom(course) && course.courseSessions?.length > 0) {
    // if the VC is scheduled --> return most recent minStartDate among scheduled sessions
    if (isScheduledCourse(course)) {
      if (course.userRelevantSessions?.length > 0) {
        let sessions = [];

        course.courseSessions.forEach(session => {
          course.userRelevantSessions.map(relevant => {
            if (relevant.id === session.id) {
              sessions.push({ ...session, ...relevant });
            }
          });
        });

        let scheduledSessions = sessions.filter(a => isScheduledSession(a));
        if (scheduledSessions.length > 0) {
          scheduledSessions = orderSessions(scheduledSessions, 'asc');
          return scheduledSessions[0].minStartDate;
        } else {
          //if no one scheduled session (should not be a real case) --> return most recent userRelevantSession
          sessions = orderSessions(sessions, 'asc');
          return sessions?.length > 0 ? sessions[0].minStartDate : null;
        }
      }
    }

    //if upcoming, attended, missed, mandatory
    course.courseSessions = orderSessions(course.courseSessions, 'asc');

    //get the most recent not passed session
    if (isUpcomingCourse(course)) {
      //get sessions in the future
      //ignore if the session is full and it doesn't permit overbooking
      const notPastSessions = course.courseSessions.filter(session =>
        canSignUpSession(session, true)
      );
      if (notPastSessions?.length > 0) {
        return notPastSessions[0].minStartDate;
      }
    }

    //get the most recent session
    return course.courseSessions[0].minStartDate;
  } else if (isLivestreamCourse(course)) {
    return getLivestreamDate(course).timeStart || '';
  } else {
    return course.expectedCompletion || '';
  }
};

export const getSortDateUpcomingEvent = (course, coursesMap) => {
  if (!course || !coursesMap) {
    return '';
  }

  if (isLearningPath(course)) {
    return getLearningPathMinStartDateVC(course, coursesMap);
  } else {
    return getSortDateTodo(course);
  }
};

export const orderUpcomingEvents = (events, coursesMap, reverseOrder = false) => {
  if (!events) {
    return [];
  }

  return events.sort((a, b) => {
    let aSortDate = getSortDateUpcomingEvent(a, coursesMap);
    let bSortDate = getSortDateUpcomingEvent(b, coursesMap);

    if (aSortDate && !bSortDate) {
      return -1;
    } else if (!aSortDate && bSortDate) {
      return 1;
    }
    if (!aSortDate) aSortDate = '';
    if (!bSortDate) bSortDate = '';

    const order = reverseOrder
      ? bSortDate.localeCompare(aSortDate)
      : aSortDate.localeCompare(bSortDate);
    if (order !== 0) {
      return order;
    } else {
      //sort by course name if date is equal
      return a.courseFullName.localeCompare(b.courseFullName);
    }
  });
};

//also valid for RECOMMENDED IN TODO:
// sort todo courses
// for online courses consider expectedCompletion
// for livestreams consider its starting time
// (VC doesn't have expectedCompletion)
// for scheduled VC consider the most recent scheduled session.minStartDate
// for other VC consider the most recent session.minStartDate among not passed sessions. If all sessions are passed --> get the most recent session.minStartDate
// if online and VC have the same date, prioritize VC
export const orderToDos = todos => {
  if (!todos) {
    return [];
  }

  return todos.sort((a, b) => {
    //put onboarding first
    let isAOnboarding = isCourseOnBoarding(a);
    let isBOnboarding = isCourseOnBoarding(b);
    //if one is onboarding and the other isn't --> give priority to onboarding
    if (isAOnboarding !== isBOnboarding) {
      return isAOnboarding ? -1 : 1;
    }

    let aSortDate = getSortDateTodo(a);
    let bSortDate = getSortDateTodo(b);

    if (aSortDate && !bSortDate) {
      return -1;
    } else if (!aSortDate && bSortDate) {
      return 1;
    }
    if (!aSortDate) aSortDate = '';
    if (!bSortDate) bSortDate = '';

    //get date to compare for online and VC
    const compare = aSortDate.localeCompare(bSortDate);
    if (compare === 0) {
      // if same date --> prioritize VC
      const aIsVC = isVirtualClassroom(a);
      const bIsVC = isVirtualClassroom(b);

      if (aIsVC && !bIsVC) {
        return 1;
      } else if (!aIsVC && bIsVC) {
        return -1;
      }
    }

    //if still equal --> sort by recommandation (affinity)
    if (compare === 0) {
      return compareCourses(a, b, SORTBY.RECOMMANDATION);
    }

    return compare;
  });
};

/* CHECK IF COURSE IS VIRTUAL */
export const isVirtualClassroom = course => {
  return (
    course?.ctype?.includes(ctype.VIRTUAL_CLASSROOM) || course?.ctype?.includes(ctype.FACE_TO_FACE)
  );
};

export const vcHasOneSessionBooked = course => {
  return (
    course?.ctype.includes(ctype.VIRTUAL_CLASSROOM) || course?.ctype.includes(ctype.FACE_TO_FACE)
  );
};

/* CHECK IF COURSE IS LIVESTREAM */
//The new livestream are the livestream with complexity c4 and ctype virtual classroom
export const isNewLivestream = course => {
  return (
    course?.ctype?.includes(ctype.VIRTUAL_CLASSROOM) && course?.complexity?.complexity === "C4"
  );
};

export const getChipLanguageForNewLivestream = (course, languages) => {
  const sessionLanguage = course?.courseSessions?.[0]?.language; // example: 'eng'

  const foundLanguage = languages?.find(lang => lang?.code === sessionLanguage);
  
  if (foundLanguage) {
    return foundLanguage?.label?.toString();
  }

  return undefined;
};


export const isLivestreamCourse = course => {
  return !!course?.ctype?.includes(ctype.LIVESTREAM);
};

export const isFaceToFace = course => {
  return course.ctype.includes(ctype.FACE_TO_FACE);
};

export const isVideoCourse = course => {
  return course.ctype.includes(ctype.VIDEO);
};

export const isAnEvent = course => {
  if (course?.complexity?.complexity === "C12") {
    return true;
  }
  return false;
}


export const isAnEmptyVirtualClassroom = course => {
  if (isVirtualClassroom(course) && course?.courseSessions?.length === 0) {
    return true;
  }
  return false;
}


export const isAnEmptyLivestream = course => {
  if (isLivestreamCourse(course) && ((course?.liveInfo?.length === 0 || !course?.liveInfo) || (course?.liveInfo?.length === 1 && (!course?.liveInfo[0].recordingUrls || course?.liveInfo[0].recordingUrls?.length === 0) && (!course?.liveInfo[0].liveUrlLink) && (!course?.liveInfo[0].trainers || course?.liveInfo[0].trainers?.length=== 0)))) {
    return true;
  }
  return false;
}

export const isChildOfAnEvent = (courseId, coursesMap) => {
  let parentLP = coursesMap?.[courseId]?.parentLP;
  const result = parentLP?.find(element => isAnEvent(coursesMap[element.courseId]));
  return result;
}


export const isSubtitledVideoCourse = course => {
  return course && isVideoCourse(course) && isTagPresent(course.tags, TAGS.isSubtitledVideo);
};

export const isPodcast = course => {
  return course.ctype.includes(ctype.PODCAST);
};

export const isVideoOrPodcast = course => {
  return isVideoCourse(course) || isPodcast(course);
};

/* CHECK IF COURSE IS ONLINE COURSE */
export const isOnlineCourse = course => {
  return course.ctype.includes(ctype.ONLINE_COURSE) || course.ctype.includes(ctype.INTERACTIVE);
};

/* CHECK IF COURSE IS LEARNING PATH */
export const isLearningPath = course => {
  return course && course.ctype && course.ctype.includes(ctype.LEARNING_PATH);
};

export const isPdfCourse = course => {
  return course?.ctype?.includes(ctype.PDF);
};

export const isExternalContentCourse = course => {
  return course?.ctype?.includes(ctype.EXTERNAL_CONTENT);
};

export const isMultiActivity = course => {
  return course?.ctype?.includes(ctype.MULTIACTIVITY);
};

export let vcHasMoreSession = course => {
  return course?.courseSessions;
}

export let vcSessionNoBooked = course => {
  let result = false;
    course?.courseSessions.map( session => {
      if(session?.status === "BOOKED"){
          result = true;
      }
    })
    return result;
}

const isPassed = session => {
  let today = new Date(),
  sessionDate = new Date(session.minStartDate);

  return session?.status === "SESSION_OVER" || 
  session?.status === "SESSION_OVER_BOOKED" ||
  session?.status === "SESSION_OVER_NO_SHOW" ||
  session?.status === "SESSION_OVER_PARTIALLY_ATTEND" ||
  session?.status === "SESSION_OVER_FULLY_ATTENDED" || 
  sessionDate < today
}


export let vcHasMoreSessionToBook = course => {
return isVirtualClassroom(course) && isMultiActivity(course) && (course?.courseSessions.every( session => (session?.status === "BOOKING_OPEN" || isPassed(session)))) && course?.userRelevantSessions?.length === 0;
}

export let vcGetSessionToBook = course => {
  return course?.courseSessions.filter( session => (session?.status === "BOOKING_OPEN" && !isPassed(session)));
}

export let multiActivityHasMoreVC = course => {
return course?.courseDetails?.[0]?.content?.length > 1;
}

export let getMultiactivityListOfVCs = course => {
  let filterByVC = [];
  course.courseDetails.map( activity => {
    if(activity.content[0].type == "facetoface"){
      filterByVC.push(activity.content[0])
    }
  })
 return filterByVC;
}

export let getSessionFilteredByActivity = (instance, course)=> {
  let sessionFiltered = [];
  //AT THE MOMENT multiactivity does not support vc multi sessions, the field facetofaceid has to be removed at februaru 2024 (homepage optimization)
  /*course?.courseSessions.map( session => {
    if(session?.faceToFaceId === instance){
        sessionFiltered.push(session);
    }
  })*/
return sessionFiltered;
}

export let getSessionFilteredByActivityToBook = (instance, course)=> {
  let sessionFiltered = [];
  //AT THE MOMENT multiactivity does not support vc multi sessions, the field facetofaceid has to be removed at februaru 2024 (homepage optimization)
 /* course?.courseSessions.map( session => {
    if(session?.faceToFaceId === instance && session?.status==="BOOKING_OPEN" && !isPassed(session)){
        sessionFiltered.push(session);
    }
  })*/
return sessionFiltered;
}


export const getCompletedCourseforLP = (learningPath, coursesMap, getOnlyNotOptional = false) => {
  let countCompleted = 0,
    countAll = 0;
  let singleCourseInLP;

  if (learningPath) {
    learningPath?.learningPath.forEach(course => {
      if (getOnlyNotOptional && !isLessonNotOptionalForLP(course)) {
        //course optional for the LP
        return;
      }

      singleCourseInLP = getCourseWithinLP(course, learningPath, coursesMap);
      countAll++;
      if (
        //coursesMap[course.courseIdMaster] &&
        singleCourseInLP?.userCourseStatus === courseStatus.COMPLETED
      )
        countCompleted++;
    });
    learningPath.countCompletedCourses = countCompleted;
    learningPath.countAllCourses = countAll;
    return learningPath;
  } else {
    return null;
  }
};

export const isMultiActivityCompletedForFeedback = course => {
  const completationArray = [];
  const notCompletationArray = [];
  if (course.courseDetails) {
    for (var courseDet of course.courseDetails) {
      for (var singleActivity of courseDet.content) {
        completationArray.push(isActivityCompleted(singleActivity));
        if (!isActivityCompleted(singleActivity)) {
          notCompletationArray.push(false);
        }
      }
    }
  }

  return (
    isMultiActivity(course) &&
    (isCompletedCourse(course) ||
      !completationArray.includes(false) ||
      !(notCompletationArray.length > 1))
  );
};

export const isLPCompletedForFeedback = lp => {
  if (lp && isCourseToBeRated(lp)) {
    if (lp.userCourseStatus === courseStatus.COMPLETED) {
      return true;
    } else if (lp.countAllCourses === lp.countCompletedCourses) {
      return true;
    } else if (lp.countAllCourses - lp.countCompletedCourses === 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isOnlyMultiActivity = course => {
  return course.ctype && course.ctype.length === 1 && isMultiActivity(course);
};

export const isL1Course = course => {
  return course?.ctype?.includes(ctype.L1);
};

export const isMandatory = course => {
  return !!course?.mandatory;
};

export const isLessonOptionalForLP = (course, coursesMap,learningPathId) => {
  if (!course || !Array.isArray(course.parentLP)) {
    return false;
  }

  if (course) {
    let learningPath = coursesMap?.[learningPathId];
      if (!learningPath?.learningPath) {
        return false;
      }
      if(isLearningPath(learningPath) && (learningPath?.complexity?.complexity === 'C6' || learningPath?.complexity?.complexity === 'C8' || learningPath?.complexity?.complexity === 'C9' ||learningPath?.complexity?.complexity === 'C10' || learningPath?.complexity?.complexity === 'C11' || learningPath?.complexity?.complexity === 'C13' )){
        return learningPath.learningPath.some(
          (item) => item?.courseId === course?.courseId && item?.optionalLabel
        );
      }
    
  }
};
export const isOptionalBadgeNeeded = (topic1, coursesMap, learningPathId) => {
  if (!topic1.topics || topic1.topics.length === 0) {
    return {
      isTopic1Optional: false,
      topic2Statuses: []
    };
  }

  let isTopic1Optional = true;

  const topic2Statuses = topic1.topics.map(topic2 => {
    if (!topic2.topics || topic2.topics.length === 0) {
      isTopic1Optional = false;
      return {
        isTopic2Optional: false,
        topic3Statuses: []
      };
    }

    // Check if ALL topic3 under this topic2 are optional
    const topic3Statuses = topic2.topics.map(topic3 => {
      const isTopic3Optional = topic3.courses?.every(course =>
        isLessonOptionalForLP(course, coursesMap, learningPathId)
      );

      // Ensure all courses in topic3 are optional and not completed
      return isTopic3Optional;
    });

    // Check if the entire topic2 is optional (all topic3 are optional)
    const isTopic2Optional = topic3Statuses.every(status => status);

    if (!isTopic2Optional) {
      isTopic1Optional = false;
    }

    return {
      isTopic2Optional,
      topic3Statuses,
    };
  });

  return {
    isTopic1Optional,
    topic2Statuses
  };
};



/** CHECK IF COURSE IS UPCOMING, SCHEDULED, ETC
* BETTER USE checkCourseState in CourseUtility for typesafe and more separation of concept
*  */
export const checkCourseState = (courseStates, state) => {
  return courseStates?.includes(state);
};


export const showBoxContinueEnroll = (course,coursesMap) => {
  if(isLearningPath(course)&& !isLpEnrolled(course)){
 return course?.learningPath?.some(item => isCompletedCourse(coursesMap[item?.parentId]));}
  return false;
};

export const showBoxCompleteEnroll = (course,coursesMap) => {
  if (course && isLearningPath(course) && !isLpEnrolled(course)) {
    return course?.learningPath?.every(item => isCompletedCourse(coursesMap[item?.parentId]));
  }
  return false;
};

export const canSignUpSession = (session, ignoreAllowOverbook = false, isVirtualClassroom = false) => {
  return (
    session &&
    (session.status === sessionStatus.BOOKING_OPEN ||
      session.status === sessionStatus.SESSION_INPROGRESS && isVirtualClassroom ||
      (session.status === sessionStatus.BOOKING_FULL &&
        (ignoreAllowOverbook || !!session?.allowOverbook)))
  );
};

/* CHECK IF USER CAN SIGN UP */
export const canSignUp = (session, course) => {
  if (course.userRelevantSessions.length > 0) {
    let isBlocked = false;
    course.userRelevantSessions.map(session => {
      if (session.id === '0' && !session.facetofaceavailable) {
        isBlocked = true;
      }
    });

    if (isBlocked) return !isBlocked;
  }

  if (isVirtualClassroom(course)) {
    return canSignUpSession(session, false, true);
  }

  return canSignUpSession(session);
};

/* CHECK IF USER CAN CANCEL BOOKING */
export const canCancelBooking = session => {
  let diff = new Date(session.minStartDate).getTime() - new Date().getTime();
  // let timeOutTime = milliseconds(2,0,0);
  let isBeforeSession = false;

  if (diff > 900000) {
    isBeforeSession = true;
  }
  return (
    (session.status === sessionStatus.BOOKED || session.status === sessionStatus.WAITLISTED) &&
    !!session?.allowCancellations &&
    isBeforeSession
  );
};

/* CHECK IF USER CAN JOIN THE SESSION */
export const canJoinSession = session => {
  return (
    session &&
    (session.status === sessionStatus.BOOKED ||
      session.status === sessionStatus.SESSION_INPROGRESS_BOOKED ||
      session.status === sessionStatus.SESSION_OVER_BOOKED) &&
    session.webExSessionLink &&
    session.webExSessionLink?.length > 0
  );
};

/* IF IN A VIRTUAL COURSE THE PLAY BUTTON IS VISIBLE */
export const canSeePlayButton = sessions => {
  let retVal = false;
  sessions?.map(session => {
    if (!retVal) {
      retVal =
        (session.status === sessionStatus.NO_SHOW ||
          session.status === sessionStatus.SESSION_OVER ||
          session.status === sessionStatus.PARTIALLY_ATTENDED ||
          session.status === sessionStatus.FULLY_ATTENDED ||
          session.status === sessionStatus.SESSION_OVER_NO_SHOW ||
          session.status === sessionStatus.SESSION_OVER_PARTIALLY_ATTEND ||
          session.status === sessionStatus.SESSION_OVER_FULLY_ATTENDED) &&
        session.webExRecordingLinks?.length > 0;
    }
  });

  return retVal;
};

/* IF IN A VIRTUAL COURSE IS VISIBLE THE ATTEND CHECK MESSAGE */
export const canSeeCheckMessage = session => {
  if (!session) {
    return false;
  }

  let diff = new Date().getTime() - new Date(session.sessionDates[0].timeFinish).getTime();
  let timeOutTime = 0;
  let isAfter = false;

  if (diff > timeOutTime) {
    isAfter = true;
  }

  // return ((session.status === sessionStatus.SESSION_OVER_WAITLISTED || session.status === sessionStatus.SESSION_OVER_BOOKED) && isTwoHoursAfter)
  return isAfter;
};

export const isWaitlisted = userRelevantSessions => {
  let isWait = false;

  if (userRelevantSessions && userRelevantSessions.length > 0) {
    userRelevantSessions.map(session => {
      if (session.status === sessionStatus.WAITLISTED) isWait = true;
    });
  }

  return isWait;
};

export const isScheduledCourse = course => {
  if (course?.courseStates?.length > 0) {
    return course.courseStates.includes(courseStatesConst.SCHEDULED);
  }

  return false;
};

export const isScheduledSession = session => {
  return (
    session.status === sessionStatus.WAITLISTED ||
    session.status === sessionStatus.BOOKED ||
    session.status === sessionStatus.SESSION_OVER_WAITLISTED ||
    session.status === sessionStatus.SESSION_OVER_BOOKED ||
    session.status === sessionStatus.SESSION_INPROGRESS_BOOKED ||
    session.status === sessionStatus.SESSION_INPROGRESS_WAITLISTED
  );
};

export const isAttendedSession = session => {
  return (
    session &&
    (session.status === sessionStatus.SESSION_OVER_FULLY_ATTENDED ||
      session.status === sessionStatus.SESSION_OVER_PARTIALLY_ATTEND)
  );

};

export const isSessionOver = session => {
  if (!session) {
    return false;
  }

  switch (session.status) {
    case sessionStatus.SESSION_OVER:
    case sessionStatus.SESSION_OVER_WAITLISTED:
    case sessionStatus.SESSION_OVER_BOOKED:
    case sessionStatus.SESSION_OVER_NO_SHOW:
    case sessionStatus.SESSION_OVER_PARTIALLY_ATTEND:
    case sessionStatus.SESSION_OVER_FULLY_ATTENDED:
      return true;
    default:
      return false;
  }
};

export const isUpcomingCourse = course => {
  if (!course) {
    return false;
  }

  return (
    isVirtualClassroom(course) &&
    (checkCourseState(course.courseStates, courseStatesConst.UPCOMING) ||
      isUpcomingInCatalog(course))
  );
};

//CHECK IF COURSE IT IS UPCOMING, BUT NOT MANDATORY
export const isUpcomingInCatalog = course => {
  if (!course) {
    return false;
  }

  if (
    !course?.mandatory &&
    !course.courseStates?.includes('scheduled') &&
    !course.courseStates?.includes('attended') &&
    !course.courseStates?.includes('missed')
  ) {
    return areThereFutureSessions(course.courseSessions);
  }
  return false;
};

//CHECK IF COURSE IT IS MISSED, BUT NOT MANDATORY
export const isMissedInCatalog = course => {
  if (course?.courseSessions?.length > 0 &&
    !course?.mandatory &&
    !course.courseStates.includes('scheduled') &&
    !course.courseStates.includes('attended') &&
    !course.courseStates.includes('missed')
  ) {
    return !areThereFutureSessions(course.courseSessions);
  }
  return false;
};

//If at least one relevant session has state: SESSION_OVER_PARTIALLY_ATTENDED, SESSION_OVER_FULLY_ATTENDED
//return ATTENDED
export const isAttendedInCatalog = course => {
  if (course && course.userRelevantSessions && course.userRelevantSessions.length > 0) {
    for (let session of course.userRelevantSessions) {
      if (isAttendedSession(session)) {
        return true;
      }
    }
  }

  return false;
};

//get the session state checking the sessions, not using course.courseStates
export const getSessionStateByCourseSessions = course => {
  //If at least one relevant session has state: SESSION_OVER_PARTIALLY_ATTENDED, SESSION_OVER_FULLY_ATTENDED
  //return ATTENDED
  if (isAttendedInCatalog(course)) {
    return courseStatesConst.ATTENDED;
  }

  //If at least one relevant session has state: WAITLISTED, BOOKED, SESSION_OVER_WAITLISTED, SESSION_OVER_BOOKED, SESSION_INPROGRESS_WAITISTED, SESSION_INPROGRESS_BOOKED
  //return SCHEDULED
  for (let session of course.userRelevantSessions) {
    if (isScheduledSession(session)) {
      return courseStatesConst.SCHEDULED;
    }
  }

  //If at least one course has a future date --> return UPCOMING
  //Otherwise return MISSED
  if (areThereFutureSessions(course.courseSessions)) {
    return courseStatesConst.UPCOMING;
  } else {
    return courseStatesConst.MISSED;
  }
};

export const isFaceToFaceAvailable = sessions => {
  let retVal = false;

  if (sessions.length === 0) return true;

  sessions.map(session => {
    if (session.faceToFaceAvailable !== undefined && session.faceToFaceAvailable) {
      retVal = true;
    }
  });

  return retVal;
};

export const isCourseCertificateCtype = course => {
  return !!course?.ctype?.includes(ctype.CERTIFICATE);
};

export const isCourseVisible = (
  course,
  considerTagNoStandAlone = true,
  considerSupportLesson = true
) => {
  if (
    !course ||
    (considerTagNoStandAlone && isNoStandalone(course)) ||
    (considerSupportLesson
      ? isSupportLessonInOnboarding(course)
      : isCourseCertificateCtype(course)) ||
    (course.endDate && new Date(course.endDate).getTime() < new Date().getTime())
  ) {
    return false;
  }
  return true;
};

export const getLanguagesPerCourse = course => {
  let languages = [];
  let comingSoonLanguages = [];

  getComingSoonLanguage(course).map(lang => {
    comingSoonLanguages.push({
      value: lang,
      label: lang,
      //courseId: childC.courseId,
      courseId: course.courseIdMaster,
      isParent: false,
      parentId: course.courseIdMaster,
    });
  });

  course.childCourses?.map(childC => {
    //isCourseVisible is no more present on children. If the child is present --> it's visible
    // if (isCourseVisible(childC)) {
    languages.push({
      value: childC.language,
      label: childC.language,
      courseId: childC.childId,
      isParent: false,
      parentId: course.courseIdMaster,
    });
    // }
  });

  // if (languages.findIndex(a => a.value === course.language) < 0) {
  //   languages.push({
  //     value: course.language,
  //     label: course.language,
  //     courseId: course.courseId,
  //     isParent: false,
  //     parentId: course.courseIdMaster,
  //   });
  // }

  course.totalLanguages = languages;
  course.languageToPrint = null;
  course.comingSoonLanguages = comingSoonLanguages;

  course.childCourses?.map(child => {
    child.totalLanguages = languages;
    child.languageToPrint = {
      value: child.language,
      label: child.language,
      courseId: child.childId,
      isParent: false,
      parentId: course.courseIdMaster,
    };

    if (course.courseId === child.childId) {
      course.languageToPrint = {
        value: child.language,
        label: child.language,
        courseId: child.childId,
        isParent: false,
        parentId: course.courseIdMaster,
      };
    }
  });

  return course;
};

/* COMBIN INFO BETWEEN COURSE-SESSIONS AND USER-RELEVANT-SESSIONS TO PRINT SESSIONS */
export const combineInfoSessions = course => {
  let { userRelevantSessions, courseSessions, courseStates } = course;

  if (courseStates.includes(courseStatesConst.UPCOMING) || isUpcomingInCatalog(course)) {
    //UPCOMING: SESSIONI DISPONIBILI FUTURE

    return courseSessions.filter(
      session =>
        new Date(session.minStartDate).getTime() > new Date().getTime() &&
        (session.status === sessionStatus.BOOKING_OPEN ||
          session.status === sessionStatus.BOOKING_FULL) &&
        session.id !== '0'
    );
  } else if (courseStates.includes(courseStatesConst.SCHEDULED)) {
    //SCHEDULED: sessione schedulata in relevant
    let scheduledSession = [];

    courseSessions.forEach(cS => {
      userRelevantSessions.map(relSession => {
        if (isScheduledSession(relSession) && relSession.id === cS.id) {
          scheduledSession.push({ ...cS, ...relSession });
        }
      });
    });

    return scheduledSession;
  } else if (courseStates.includes(courseStatesConst.ATTENDED) || isAttendedInCatalog(course)) {
    //ATTENDED: messaggi vari no sessione
    let attendedSession = [];

    courseSessions.forEach(cS => {
      userRelevantSessions.map(relSession => {
        if (isAttendedSession(relSession) && relSession.id === cS.id) {
          attendedSession.push({ ...cS, ...relSession });
        }
      });
    });

    return attendedSession;
  } else if (courseStates.includes(courseStatesConst.MISSED) || isMissedInCatalog(course)) {
    //MISSED: sessione più recente

    courseSessions.sort((a, b) => {
      if (a.minStartDate && b.minStartDate) {
        let dateA = new Date(a.minStartDate).getTime(),
          dateB = new Date(b.minStartDate).getTime();
        return dateA - dateB;
      }
    });

    let lastIndex = courseSessions.length - 1;
    return lastIndex >= 0 ? [courseSessions[lastIndex]] : [];
  }

  return [];
};

/* COMBIN INFO BETWEEN COURSE-SESSIONS AND USER-RELEVANT-SESSIONS TO PRINT SESSIONS IN MODAL */
export const getSessionsForModal = (course, userProfile = null, vcLesson = null) => {
  let { userRelevantSessions, courseSessions, courseStates } = course;
  /*if(multiActivityHasMoreVC(course) && vcLesson){
    courseSessions = getSessionFilteredByActivity (vcLesson, course)
  }*/
    if (courseStates.includes(courseStatesConst.UPCOMING) || isUpcomingInCatalog(course) || isVCLiveNow(course, userProfile) && !courseStates.includes(courseStatesConst.SCHEDULED)) {
      // UPCOMING: SESSIONI DISPONIBILI FUTURE
      return courseSessions.filter(session => {
        const isVirtual = isVirtualClassroom(course);
    
        const dateCondition = isVirtual
          ? true //if VC, allow users to sign up even when session has started
          : new Date(session.minStartDate).getTime() > new Date().getTime();
    
        return (
          dateCondition &&
          (session.status === sessionStatus.BOOKING_OPEN ||
            session.status === sessionStatus.SESSION_INPROGRESS && isVirtual ||
            session.status === sessionStatus.BOOKING_FULL)
        );
      });
    } else if (courseStates.includes(courseStatesConst.SCHEDULED) ) {
    //SCHEDULED: sessione schedulata in relevant e sessioni future disponibili
    let scheduledSession = [];
    let futureSession = [];

    courseSessions.forEach(cS => {
      userRelevantSessions.map(relSession => {
        if (isScheduledSession(relSession) && relSession.id === cS.id) {
          scheduledSession.push({ ...cS, ...relSession });
        }
      });
    });

    console.log("SCHEDULED sessions", scheduledSession);
    if (!(canSeeCheckMessage(scheduledSession[0]) || course.showJoin || isVCLiveNow(course, userProfile) && course?.courseSessions?.length > 1)) {
      futureSession = courseSessions.filter(
        session =>
          session.id !== scheduledSession[0]?.id &&
          (session.status === sessionStatus.BOOKING_OPEN ||
            session.status === sessionStatus.BOOKING_FULL) &&
          new Date(session.minStartDate).getTime() > new Date().getTime()
      );
    }

    return [...scheduledSession, ...futureSession];
  } else if (courseStates.includes(courseStatesConst.ATTENDED) || isCompletedCourse(course)) {
    //ATTENDED: messaggi vari no sessione
    let attendedSession = [];

    courseSessions.forEach(cS => {
      userRelevantSessions.map(relSession => {
        if (isAttendedSession(relSession) && relSession.id === cS.id) {
          attendedSession.push({ ...cS, ...relSession });
        }
      });
    });

    return attendedSession;
  } else if (courseStates.includes(courseStatesConst.MISSED) || isMissedInCatalog(course)) {
    //MISSED: messaggi vari no sessione

    return [];
  } else {
    console.error('ERROR: getSessionsForModal() - it is not possible to get the VC status');
    return [];
  }
};

export const printDateMMYYYY = (date, shortYear) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    year = d.getFullYear();
  if (shortYear) {
    year = year?.toString()?.substring(2);
  }

  if (month.length < 2) month = '0' + month;

  return [month, year].join('/');
};
export const printDateDDMMYYYY = (date, shortYear) => {
  let d = new Date(date),
    month = '' + numberTwoDigits(d.getMonth() + 1),
    year = d.getFullYear(),
    day = numberTwoDigits(d.getDate());
  if (shortYear) {
    year = year?.toString()?.substring(2);
  }

  return [day, month, year].join('/');
};

export const printDateTP = (dateTP, lang) => {
  let month = dateTP.slice(3, 5),
    day = dateTP.slice(0, 2),
    year = dateTP.slice(6, 10);

  return printDate(year + '/' + month + '/' + day + ' 00:00:00', lang);
};

export const printDateLong = (timestamp, lang, considerTime = true) => {
  let now = new Date(timestamp);
  let month = now.getMonth() + 1;

  let monthToPrint = getMonthToPrint(month.toString(), lang),
    day = numberTwoDigits(now.getDate()),
    year = now.getFullYear(),
    hour = numberTwoDigits(now.getHours()),
    minutes = numberTwoDigits(now.getMinutes());

  let date = day + ' ' + monthToPrint + ' ' + year;
  if (considerTime) {
    date += ' - ' + hour.toString() + ':' + minutes.toString();
  }

  return date;
};

export const printDateLivestream = (timestampStart, timestampEnd, lang) => {
  let dateStart = new Date(timestampStart);
  let month = dateStart.getMonth() + 1;

  let monthToPrint = getMonthToPrint(month.toString(), lang),
    day = numberTwoDigits(dateStart.getDate()),
    hour = numberTwoDigits(dateStart.getHours()),
    minutes = numberTwoDigits(dateStart.getMinutes());

  const dateEnd = new Date(timestampEnd);
  const hourEnd = numberTwoDigits(dateEnd.getHours());
  const minutesEnd = numberTwoDigits(dateEnd.getMinutes());

  return day + ' ' + monthToPrint + ' ' + hour + ':' + minutes + ' - ' + hourEnd + ':' + minutesEnd;
};

export const numberTwoDigits = num => {
  if (num >= 0 && num < 10) {
    return '0' + num;
  } else {
    return num;
  }
};

export const getCourseToDisplay = (course, lang) => {
  if (!course.childCourses || course.childCourses.length === 0) {
    return course;
  } else {
    let childToprint = null;
    let langToUse = lang;
    if(window.location.href.includes(queryParams.COURSE_DETAIL)) {
      langToUse = course?.language
    }
    //get child course of the language of the user
    course.childCourses.map(child => {
      //if the child is present --> it's visible
      // isCourseVisible(child) &&
      if (child.language === langToUse) {
        childToprint = child;
      }
    });

    //if a child course of that language doesnt exists
    //get course with fallback 1
    // not for learning path
    // if (!childToprint && !isLearningPath(course)) {
    //   course.childCourses.map((child) => {
    //     //if the child is present --> it's visible
    //     // isCourseVisible(child) &&
    //     if (child.fallback === 1) {
    //       childToprint = child;
    //     }
    //   });
    // }

    //if childToPrint is still null, print the parent
    return childToprint ? childToprint : course;
  }
};

export const hadMultipleSessions = course => {
 // console.log('course', course);
  if (course.courseSessions.length > 1) {
    return true;
  } else {
    return false;
  }
};

export const areThereFutureSessions = (
  sessions,
  useEndDate = false,
  userProfile = null
) => {
  if (!sessions) {
    return false;
  }

  for (let session of sessions) {
    if (isSessionInTheFuture(session, useEndDate, userProfile)) {
      return true;
    }
  }

  return false;
};

export const isSessionInTheFuture = (
  session,
  useEndDate = false,
  userProfile = null
) => {
  if (!session) {
    return false;
  }

  // current Date in UTC
  const nowUTC = moment.utc();

  let timezone = '';
  if (userProfile) {
    timezone = userProfile.timezone;
  } else {
    //get timezone for JWT
    timezone = getValueFromJWT(['userDetails', 'timezone']);
  }

  // Start Date of session 
  let sDate = timezone
    ? getLocalDateFromString(session.minStartDate, timezone)
    : moment.utc(session.minStartDate);

  if (useEndDate && session.sessionDates?.[0]?.timeFinish) {
    sDate = timezone
      ? getLocalDateFromString(session.sessionDates[0].timeFinish, timezone)
      : moment.utc(session.sessionDates[0].timeFinish);
  }

  // check if session Date is in the future wrt `nowUTC`
  return sDate.isAfter(nowUTC);
};

export const isVCInTheFuture = (
  course,
  considerMissedAndCompleted = false,
  userProfile = null,
  considerJustAttendedSessionsIfAny = false
) => {
  let sessions = [];

  if (considerJustAttendedSessionsIfAny && course) {
    const userRelevantAttendedSessions = course?.userRelevantSessions?.filter(isAttendedSession);
    if(userRelevantAttendedSessions) {
      for (const userRelevantAttendedSession of userRelevantAttendedSessions) {
        const session = course?.courseSessions?.find(a => a.id === userRelevantAttendedSession.id);
        if (session) {
          sessions.push(session);
        }
      }
    }
    
  }
  if (sessions?.length === 0) {
    sessions = course.courseSessions;
  }

  let result =
    course &&
    isVirtualClassroom(course) &&
    areThereFutureSessions(sessions, true, userProfile);
  if (!considerMissedAndCompleted) {
    result = result && !isMissedCourse(course) && !isCompletedCourse(course);
  }
  return result;
};

export const completedLinksToShow = course => {
  const certificateLinks = course?.certificateLinks ? course.certificateLinks : [];
  const feedbackLinks = course?.feedbackLinks ? course.feedbackLinks : [];
  const courseCompletionDate = course?.courseCompletionDate ? course.courseCompletionDate : '';

  return {
    feedbackLinks: feedbackLinks,
    certificateLinks: certificateLinks,
    courseCompletionDate: courseCompletionDate,
  };
};

export const isArrayFull = arrayToCheck => {
  let isArrayFull = false;

  arrayToCheck.map(singleArray => {
    if (singleArray.length > 0) isArrayFull = true;
  });

  return isArrayFull;
};

export const checkisCourseEliminated = course => {
  if (course && course.courseStates) {
    if (course.courseStates.includes('eliminated')) return true;
  }

  return false;
};

//FILTER LOGICS
export const isRecommended = course =>
  course.affinity > 0 && isCourseVisible(course) && !checkisCourseEliminated(course);
// && !isLearningPath(course);
export const isHighlighted = course => !!course?.highlight; /*&&
  course.userCourseStatus !== courseStatus.COMPLETE &&
  isCourseVisible(course) &&
  !isVideoCourse(course);*/
export const isStartedCourse = course =>
  (!isVirtualClassroom(course) &&
    !isLivestreamCourse(course) &&
    course?.userCourseStatus === courseStatus.ENROLLED) ||
  (course.courseStates?.includes(courseStatesConst.IN_PROGRESS) && !isCompletedCourse(course));
export const isCompletedCourse = course => course?.userCourseStatus === courseStatus.COMPLETED;
export const isCompletedChildCourse = child => child?.courseCompletionDate;

export const isCompletedLP = course => {
  return isLearningPath(course) && isCompletedCourse(course);
};

export const isWishlist = course => !!course?.wishlist && isCourseVisible(course);
export const isNewCondition = course => !!course?.isNew;
export const isNew = course => isNewCondition(course) && isCourseVisible(course);
export const isToBeContinued = course => isCourseVisible(course) && isStartedCourse(course);
export const isToDo = course =>
  /* !isLearningPath(course) && */ !!course?.mandatory && !isCompletedCourse(course); //course.courseStates.includes(courseStatesConst.TO_DO);
export const isOnboardingRelatedMandatory = course =>
  !!course?.onboardingRm;
export const isCourseExpiring = course =>
  course?.dayLeft && course.dayLeft <= 7 && course.dayLeft >= 0 && !isCompletedCourse(course);
export const isCourseToBeStarted = course =>
  course &&
  (course.courseStates?.includes('upcoming') ||
    (!course.courseStates?.includes('inProgress') &&
      !course.courseStates?.includes('done') &&
      !course.courseStates?.includes('attended') &&
      !course.courseStates?.includes('overdue') &&
      !isCompletedCourse(course)));

export const isLpEnrolled = course =>
  course &&
  isLearningPath(course) &&
  (course.userCourseStatus === courseStatus.ENROLLED || isCompletedCourse(course));

export const printCorrectCourseInModal = (currentCourse, course) => {
  let toPrint = null;
  if (!course) {
    return;
  }
  if (currentCourse) {
    if (course.courseIdMaster === currentCourse.courseId) {
      toPrint = course;
    } else {
      course.childCourses?.map(child => {
        if (child.childId === currentCourse.courseId && isCourseVisible(child, false)) {
          toPrint = enrichChildInfo(course, child);

          //do not loose courseDetails
          if (isMultiActivity(course) && !(toPrint?.courseDetails?.length > 0)) {
            toPrint.courseDetails = cloneDeep(currentCourse.courseDetails);
            toPrint.numActivities = cloneDeep(currentCourse.numActivities);
          }
        }
      });
    }
  }

  if (!toPrint) toPrint = course;
  return toPrint;
};

export const milliseconds = (hrs, min, sec) => {
  return (hrs * 60 * 60 + min * 60 + sec) * 1000;
};

export const checkisInArray = (arrayToCheck, courseIdMaster) => {
  for (let i = 0; i < arrayToCheck.length; i++) {
    if (arrayToCheck[i].courseIdMaster === courseIdMaster) {
      return i;
    }
  }

  return null;
};

import { isBrandSubcataloguePage, PUBLIC_URLS, USER_URLS } from '@components/link-utils';
import { getTrainerFullName, Trainer } from '@model/CoursesClass';
import { filterByBrand, filterBySections } from './filterUtility';
import { getLearningPathMinStartDateVC, getLpCourses } from './LPUtility';
import { getLocalDateFromString } from './CalendarUtility';
import {
  getLivestreamDate,
  getLivestreamRecording,
  isLivestreamFinished,
} from './LivestreamUtility';
import { SURVEY_TYPE } from '@model/Survey';
import { isPriceable, isPurchasable } from './ecommerceUtility';
import { getBrandIdFromL1Id, getL1Type } from './levelUtility';
import { isVCLiveNow, orderSessions } from './VCUtility';
import { isNoStandalone } from './CourseUtility';
import history from '../history';
import LazySVG from '@components/LazySvg';

export const isMobile = {
  Android: () => {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: () => {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: () => {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (/Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1)
    );
  },
  Opera: () => {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: () => {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: () => {
    return isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
      ? true
      : false;
  },
};

export const isFirefox = () => {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
};

export const getParentCourse = (courseId, coursesMap) => {
  if (!coursesMap) {
    return null;
  }

  //if it's not a child course
  if (coursesMap[courseId]) {
    return coursesMap[courseId];
  }

  //scan every child
  let parentResult = null;
  for (const parentId in coursesMap) {
    const parent = coursesMap[parentId];
    parent.childCourses?.map(child => {
      if (child.childId === courseId) {
        parentResult = parent;
      }
    });
  }

  return parentResult;
};

export const getParameterByName = (name, url = null) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const isTagPresent = (tags, tagToCheck) => {
  if (!tags || tags.length < 1) {
    return false;
  }

  if (!tagToCheck) {
    return false;
  }

  const lowerTags = tags.map(tag => tag.toLowerCase());
  return lowerTags.includes(tagToCheck.toLowerCase());
};

export const truncateLabel = (label, maxChars, showEllipsis = true) => {
  if (!label || label.length < 1) {
    return '';
  }

  if (label.length <= maxChars) {
    return label;
  } else {
    if (showEllipsis) {
      return label.slice(0, maxChars - 1) + '...';
    } else {
      return label.slice(0, maxChars);
    }
  }
};

export const goToCourseCatalog = (history, search = '') => {
  if (!history) {
    return;
  }

  history.push(USER_URLS.SUBCATALOGUE.URL + (search ? '?' + search : ''));
};

export const getRedirectUrl = (goTo, carousel = '', category, brand) => {
  let url = USER_URLS.BRAND_SUBCATALOGUE_PAGE.URL;

  switch (goTo) {
    case 'brandPage':
      url = USER_URLS.BRAND_PAGE.URL;
      break;
    case 'brandCataloguePage':
      url = USER_URLS.BRAND_SUBCATALOGUE_PAGE.URL;
      break;
    case 'brandTPCataloguePage':
      url = USER_URLS.BRAND_TP_SUBCATALOGUE_PAGE.URL;
      break;
    case 'carouselPage':
      url = USER_URLS.BRAND_CAROUSEL_PAGE.URL;
      break;
  }

  return url
    .replace(':category', category === 'brand' ? 'brand' : 'channel')
    .replace(':brand', brand)
    .replace(':carouselKeyTitle', carousel);
};

export const getBrandPageUrl = (category, brand) => {
  if (!brand || !category) {
    return;
  }

  let url = USER_URLS.BRAND_PAGE.URL;
  //se siamo attualmente nella subcatalogue page --> rimani lì
  if (
    isBrandSubcataloguePage(window.location?.pathname) &&
    window.location?.pathname?.includes(brand) &&
    category !== 'brand'
  ) {
    url = USER_URLS.BRAND_SUBCATALOGUE_PAGE.URL;
  }
  return url.replace(':category', category).replace(':brand', brand);
};

export const getBrandPageUrlBycOffice = (brand) => {

  if (!brand) {
    return;
  }

  let url = USER_URLS.BRAND_PAGE_BYC_OFFICE.URL;
  
  if (
    isBrandSubcataloguePage(window.location?.pathname) &&
    window.location?.pathname?.includes(brand) &&
    category !== 'brand'
  ) {
    url = USER_URLS.BRAND_SUBCATALOGUE_PAGE.URL;
  }
  return url.replace(':category', 'people-development').replace(':brand', brand);
};

export const goToBrandPage = (history, category, brand) => {
  if (!history || !brand || !category) {
    return;
  }
  history.push(getBrandPageUrl(category, brand));
};

export const getChannelLabel = (channel, l1Map) => {
  if (!channel || !l1Map || !l1Map[channel]) {
    return '';
  }

  return l1Map[channel].label;
};
export const getChannelOrder = (channel, l1Map) => {
  if (!channel || !l1Map || !l1Map[channel]) {
    return 100000;
  }

  return l1Map[channel].order;
};

export const getChannelLabel2 = (channel, lang) => {
  if (!channel || !lang) {
    return '';
  }

  const channels = {
    all: lang.ALL_CHANNELS,
    products: lang.PRODUCTS_LABEL,
    skills: lang.SKILLS_LABEL,
    company: lang.COMPANY_DNA_LABEL,
    compliance: lang.COMPLIANCE_LABEL,
  };

  let label = channels[channel] ? channels[channel] : '';
  if (!label) {
    label = lang[channel] ? lang[channel] : '';
  }
  if (!label) {
    label = channel;
  }

  return label;
};

export const getBrandChannelLabel = (brandChannel, lang) => {
  if (!brandChannel || !lang) {
    return '';
  }

  const brandChannels = {
    [BRAND_TYPE.HOUSE_BRAND]: lang.HOUSE_BRANDS_LABEL,
    [BRAND_TYPE.LICENSED_BRAND]: lang.LICENSED_BRANDS_LABEL,
    [BRAND_TYPE.THIRD_PARTY_BRAND]: lang.THIRD_PART_BRANDS_LABEL,
    [BRAND_TYPE.LENS_TECH]: lang.LENS_TECHNOLOGIES_LABEL,
    [BRAND_TYPE.LENS]: lang.LENS_TECHNOLOGIES_LABEL,
    [BRAND_TYPE.EYEWEAR]: lang.BRAND_EYEWEAR_LABEL,
  };

  return brandChannels[brandChannel] ? brandChannels[brandChannel] : '';
};

export const getCourseWithinLP = (LPCourse, lp, coursesMap) => {
  if (!LPCourse || !coursesMap) {
    return null;
  }

  if (LPCourse.parentId) {
    let course = coursesMap[LPCourse.parentId];
    let childToPrint = course;
    course?.childCourses?.map(child => {
      if (child.childId === LPCourse.courseId) {
        childToPrint = enrichChildInfo(course, child);
      }
    });
    return childToPrint;
  } else {
    if (lp && lp.courseId !== lp.courseIdMaster) {
      return enrichCourseInfo(coursesMap[LPCourse.courseId], coursesMap, lp.language);
    } else {
      return coursesMap[LPCourse.courseId];
    }
  }
};

export const isCourseInLPDisabled = (LPCourse, coursesMap) => {
  if (!LPCourse || !coursesMap) {
    return false;
  }

  const { cid, rule } = LPCourse;
  if (cid && cid.length > 0) {
    let statusCourses = [];

    cid.map(course => {
      if (!coursesMap[course]?.userCourseStatus) {
        console.warn(`${course} not found for course status`);
      }
      let status = coursesMap[course]?.userCourseStatus === courseStatus.COMPLETED;
      statusCourses.push(status);
    });

    if (rule === 'AND') {
      return statusCourses.includes(false);
    } else {
      return !statusCourses.includes(true);
    }
  }
  return false;
};

export const printHTML = (courseSummary, sanitizerOptions = { ADD_ATTR: ['target'] }) => {
  const sanitizer = dompurify.sanitize;
  return { __html: sanitizer(courseSummary, sanitizerOptions) };
};

export const printSanitizedHTML = (htmlContent, sanitizerOptions = { ADD_ATTR: ['target'] }) => {
  const sanitizer = dompurify.sanitize;
  return sanitizer(htmlContent, sanitizerOptions); // Return sanitized string
};
export const commonAllowedTags = [
  'br',
  'strong',
  'em',
  'u',
  's',
  'sub',
  'sup',
  'blockquote', 
  'ul', 
  'li', 
  'ol', 
  'h1', 
  'h2', 
  'h3', 
  'h4', 
  'h5', 
  'h6',
  'a'
]

export const removeHTMLTags = (htmlToSanitize, allowedTags = []) => {
  return dompurify.sanitize(htmlToSanitize, { ALLOWED_TAGS: allowedTags });
}

export const allowedCollectionsTags = [
  'br',
  'strong',
  'i',
  'b',
]

export const removeHTMLTagsCollections = (htmlToSanitize , allowedTags = []) => {
  return dompurify.sanitize(htmlToSanitize, { ALLOWED_TAGS: allowedTags });
}

export const printHTMLIframe = (iframe, removeStyle = true) => {
  const sanitizer = dompurify.sanitize;

  //remove style attribute
  if (removeStyle) {
    iframe = iframe.replace(/style="[^"]*"/, '');
  }

  return {
    __html: sanitizer(iframe, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder'],
    }),
  };
};

export const selectUserProfile = ({ user: { userProfile } }) => {
  if (userProfile) {
    return {
      fullName: `${userProfile.firstName} ${userProfile.lastName}`,
      role: userProfile.jobTitle,
      ...userProfile,
    };
  }
  return null;
};

export const getManagerAssignmentText = (category, lang, baseKey) => {
  const categoryKey = category ? `_${category.toUpperCase()}` : "";
  return lang[`${baseKey}${categoryKey}`] || lang[baseKey];  
};

export const getChannelsByL0ToShow = (coursesMap, lang, labelL0Map, labelL1Map) => {
  if (!coursesMap || !lang) {
    return [];
  }
  let l0Map = {};
  let l1Map = {};
  for (const courseId in coursesMap) {
    const course = coursesMap[courseId];
    let channelsCourse = [];

    let courses = [course];
    //if course is LP and its L0s are inherited by its lessons --> consider children
    let getLpLessons = false;
    if (isLearningPath(course) && course.level0List?.length > 1) {
      courses = getLpCourses(course, coursesMap);
      getLpLessons = true;
    }

    for (const courseL0 of courses) {
      if (
        (!isCourseVisible(courseL0) && !getLpLessons) ||
        !courseL0.catalogTypes ||
        courseL0.catalogTypes.length < 1 ||
        !courseL0.level0
      ) {
        continue;
      }

      const l0Id = courseL0.level0;
      //not show "brand", "Brands Eyewear" and "Brands Lenses" L0 because they're deprecated
      if (
        !l0Id ||
        l0Id === L0_IDS.BRANDS_EYEWEAR ||
        l0Id === L0_IDS.BRAND_LENSES ||
        l0Id === L0_IDS.BRAND
      ) {
        continue;
      }

      courseL0.catalogTypes.forEach(channel => {
        if (
          channel &&
          channel !== 'null' &&
          channel !== 'nochannel' &&
          channel !== 'brand' &&
          !channelsCourse.includes(channel) &&
          !l0Map[l0Id]?.l1?.includes(channel) &&
          labelL1Map?.[channel]
        ) {
          l1Map[channel] = labelL1Map?.[channel];
          channelsCourse.push(channel);
        }
      });

      if (channelsCourse.length > 0) {
        if (!l0Map[l0Id]) {
          l0Map[l0Id] = { l1: [], l0: {} };
        }

        l0Map[l0Id].l0 = labelL0Map[l0Id];

        if (!l0Map[l0Id].l0) {
          delete l0Map[l0Id];
          break;
        }

        if (l0Map[l0Id].l1?.length > 0) {
          l0Map[l0Id].l1 = [...l0Map[l0Id].l1, ...channelsCourse];
        } else {
          l0Map[l0Id].l1 = channelsCourse;
        }
      }
    }
  }

  // console.log('l0Map', l0Map);

  let l0Array = [];
  for (let l0Id in l0Map) {
    const singleL0 = l0Map[l0Id];
    let l1Array = [];
    singleL0.l1.forEach(l1 => {
      l1Array.push(l1Map[l1]);
    });
    l1Array = l1Array.sort((a, b) => a.order - b.order);

    l0Array.push({
      ...singleL0.l0,
      code: singleL0.l0.key,
      l1: l1Array,
    });
  }
  l0Array = l0Array.sort((a, b) => a.order - b.order);
  // console.log('l0Array', l0Array);

  return l0Array;
};

export const getCompletedCoursesChannels = (completedCourses, l1Map) => {
  if (!completedCourses) {
    return [];
  }

  let channels = [];
  completedCourses.forEach(course => {
    if (course.catalogTypes?.length > 0) {
      course.catalogTypes.forEach(channel => {
        if (
          channel &&
          channel !== 'null' &&
          channel !== 'nochannel' &&
          channel !== 'brand' /*&& !channels.includes(channel.key)*/
        ) {
          channels.push(channel);
        }
      });
    }
  });

  channels = channels.map(channel => {
    return { code: channel, label: getChannelLabel(channel, l1Map) };
  });
  return channels;
};

export const getChannelsToShow = (coursesMap, l1Map) => {
  if (!coursesMap) {
    return [];
  }

  let channels = [];
  for (const courseId in coursesMap) {
    const course = coursesMap[courseId];

    if (!isCourseVisible(course) || !course.catalogTypes || course.catalogTypes.length < 1) {
      continue;
    }

    course.catalogTypes.forEach(channel => {
      if (
        channel &&
        channel !== 'null' &&
        channel !== 'nochannel' &&
        channel !== 'brand' &&
        !channels.includes(channel)
      ) {
        channels.push(channel);
      }
    });
  }

  channels = channels.map(channel => {
    return { code: channel, label: getChannelLabel(channel, l1Map) };
  });
  channels.sort((a, b) => getChannelOrder(a.code, l1Map) - getChannelOrder(b.code, l1Map));
  return channels;
};

const last12WeeksRange = moment.range(moment().subtract(12, 'weeks'), moment());

const getPercentageChange = (oldNumber, newNumber) => {
  if (!oldNumber && !newNumber) {
    return 0;
  }

  if (!oldNumber) {
    return -100;
  }
  if (!newNumber) {
    return 100;
  }
  const decreaseValue = oldNumber - newNumber;
  return (decreaseValue / oldNumber) * 100;
};

export const getCompletedCoursesDurationByWeek = completedCoursesParam => {
  const now = moment();
  let completedCourses = cloneDeep(
    completedCoursesParam.filter(course => course?.courseCompletionDate)
  );

  completedCourses.forEach(course => {
    course.courseCompletionDate = course.courseCompletionDate.slice(0, 11) + '00:00:00';
  });

  const completedCoursesByWeek = groupBy(completedCourses, course =>
    now.diff(new Date(course.courseCompletionDate), 'weeks')
  );

  const completedCoursesByMonth = groupBy(completedCourses, course =>
    now.diff(new Date(course.courseCompletionDate), 'months')
  );

  // to initialize current and last month
  Array.from({ length: 2 }).map((_, index) => {
    if (!completedCoursesByMonth?.[index]) {
      completedCoursesByMonth[index] = [];
    }
  });

  const completedCoursesDurationByMonth = Object.keys(completedCoursesByMonth).map(indexMonth => {
    let duration = 0;
    completedCoursesByMonth[indexMonth].forEach(course => {
      if (isCompletedCourse(course)) {
        duration += course.duration;
      }
    });
    return duration;
  });

  const completedCoursesDurationByWeek = Array.from({ length: 12 }).map((_, index) => {
    let duration = 0;
    completedCoursesByWeek?.[index]?.forEach(course => {
      if (isCompletedCourse(course)) {
        duration += course.duration;
      }
    });
    return duration;
  });

  const completedCoursesDurationByWeekCurrentMonth = completedCoursesDurationByWeek.slice(0, 6);
  const completedCoursesDurationByWeekLastMonth = completedCoursesDurationByWeek.slice(6, 12);

  // total duration

  const completedCoursesTotalDurationCurrentMonth = completedCoursesDurationByMonth.reduce(
    (acc, duration) => duration + acc,
    0
  );

  const completedCoursesTotalDurationLastMonth = completedCoursesDurationByMonth
    .splice(1)
    .reduce((acc, duration) => duration + acc, 0);

  console.log('completedCoursesDurationByWeekCurrentMonth', {
    completedCoursesByWeek,
    completedCoursesDurationByWeekCurrentMonth,
    completedCoursesByMonth,
    completedCoursesTotalDurationCurrentMonth,
    completedCoursesTotalDurationLastMonth,
  });
  const diffPercentage = getPercentageChange(
    completedCoursesTotalDurationCurrentMonth,
    completedCoursesTotalDurationLastMonth
  );

  const max = Math.max(...completedCoursesDurationByWeekCurrentMonth);
  const min = 0;

  const durationPercentagesCurrentMonth = completedCoursesDurationByWeekCurrentMonth.map(
    duration => ((duration - min) / (max - min)) * 100
  );

  return {
    completedCoursesTotalDurationCurrentMonth,
    durationPercentagesCurrentMonth,
    diffPercentage,
  };
};

export const convertStringToDivision = string => {
  const [first, second] = string.split('/');
  return first / second || 0;
};

export const getComplexityCategoryLabel = (complexityCategory, lang) => {
  if (!complexityCategory || !lang) {
    return '';
  }

  let labelKey = '';
  switch (complexityCategory) {
    case FILTER_COMPLEXITY_CATEGORY.LESSONS:
      labelKey = 'FILTER_COMPLEXITY_CATEGORY_LESSONS';
      break;
    case FILTER_COMPLEXITY_CATEGORY.COURSES:
      labelKey = 'FILTER_COMPLEXITY_CATEGORY_COURSES';
      break;
    case FILTER_COMPLEXITY_CATEGORY.EVENTS:
      labelKey = 'FILTER_COMPLEXITY_CATEGORY_EVENTS';
      break;
    default:
      break;
  }

  return lang[labelKey] ? lang[labelKey] : complexityCategory;
};

export const getComplexityCategoryOrder = complexityCategory => {
  switch (complexityCategory) {
    case FILTER_COMPLEXITY_CATEGORY.COURSES:
      return 1;
    case FILTER_COMPLEXITY_CATEGORY.LESSONS:
      return 2;
    case FILTER_COMPLEXITY_CATEGORY.EVENTS:
      return 3;
    default:
      return 100;
  }
};

export const getDurationFilterById = id => {
  const durationObjs = Object.values(DURATION).filter(a => a.id === id);
  if (durationObjs?.length < 1) {
    return null;
  }

  return durationObjs[0];
};

export const getDurationLabel = (durationId, lang) => {
  if (!durationId || !lang) {
    return '';
  }

  const durationObj = getDurationFilterById(durationId);
  if (!durationObj) {
    return '';
  }

  return lang[durationObj.labelKey] ? lang[durationObj.labelKey] : '';
};

export const getScheduleLabel = (schedule, lang) => {
  if (!schedule || !lang) {
    return '';
  }

  const labelKey = SESSION_SCHEDULE[schedule]?.labelKey;
  if (!labelKey) {
    return schedule;
  }

  return lang[labelKey] ? lang[labelKey] : schedule;
};

export const getRangeScheduleByDateString = (dateString, timezone) => {
  if (!dateString || !timezone) {
    return [];
  }

  const dateSession = momentTimezone.tz(dateString.replace('/', '-'), timezone);

  const todayTimezone = momentTimezone().tz(timezone);
  const tomorrowTimezone = momentTimezone(todayTimezone).add(1, 'day');
  const nextMonday = momentTimezone(todayTimezone).add(1, 'week').startOf('isoWeek');
  const nextSunday = momentTimezone(todayTimezone).add(1, 'week').endOf('isoWeek');
  const nextMonthStart = momentTimezone(todayTimezone).add(1, 'month').startOf('month');
  const nextMonthEnd = momentTimezone(todayTimezone).add(1, 'month').endOf('month');

  // console.log('timezone', timezone)
  // console.log('dateString', dateString)
  // console.log('dateSession', dateSession.format())
  // console.log('todayTimezone', todayTimezone.format())
  // console.log('tomorrowTimezone', tomorrowTimezone.format())
  // console.log('nextMonday', nextMonday.format())
  // console.log('nextSunday', nextSunday.format())
  // console.log('nextMonthStart', nextMonthStart.format())
  // console.log('nextMonthEnd', nextMonthEnd.format())

  const schedules = [];

  if (dateSession.isSame(todayTimezone, 'date')) {
    schedules.push(SESSION_SCHEDULE.TODAY.id);
  } else {
    if (dateSession.isSame(tomorrowTimezone, 'date')) {
      schedules.push(SESSION_SCHEDULE.TOMORROW.id);
    }

    if (
      dateSession.isSameOrAfter(nextMonday, 'date') &&
      dateSession.isSameOrBefore(nextSunday, 'date')
    ) {
      schedules.push(SESSION_SCHEDULE.NEXT_WEEK.id);
    }

    if (
      dateSession.isSameOrAfter(nextMonthStart, 'date') &&
      dateSession.isSameOrBefore(nextMonthEnd, 'date')
    ) {
      schedules.push(SESSION_SCHEDULE.NEXT_MONTH.id);
    }
  }

  return schedules;
};

const getSessionStatus = course => {
  let retVal = '';

  if (!course) {
    return '';
  }

  const courseStates = course.courseStates ? course.courseStates : [];
  courseStates.map(c => {
    switch (c) {
      case courseStatesConst.UPCOMING:
        retVal = courseStatesConst.UPCOMING;
        break;
      case courseStatesConst.SCHEDULED:
        retVal = courseStatesConst.SCHEDULED;
        break;
      case courseStatesConst.MISSED:
        retVal = courseStatesConst.MISSED;
        break;
      case courseStatesConst.ATTENDED:
        retVal = courseStatesConst.ATTENDED;
        break;
    }
  });

  if (isUpcomingInCatalog(course)) {
    retVal = courseStatesConst.UPCOMING;
  }

  if (isMissedInCatalog(course)) {
    retVal = courseStatesConst.MISSED;
  }

  //only if courseStates is empty and the course is completed
  if (
    (!courseStates || courseStates.length === 0) &&
    (isCompletedCourse(course) || isCompletedLP(course))
  ) {
    retVal = getSessionStateByCourseSessions(course);
  }

  return retVal;
};

export const isOverdueCourse = course => {
  return course?.courseStates?.includes(courseStatesConst.OVERDUE);
};

/*export const isMissedCourseForBadge = course => {
  const bookedSessions = course.userRelevantSessions.filter(session =>
    isScheduledSession(session)
  );
  const userRelevantAttendedSessions = bookedSessions?.filter(isAttendedSession);
  return course
    ? (isVirtualClassroom(course) || isLivestreamCourse(course)) && !userRelevantAttendedSessions
    : false
}*/

export const isMissedCourseForBadge = course => {
  if (course?.userRelevantSessions && (isVirtualClassroom(course) || isLivestreamCourse(course))) {
    const userRelevantNotAttendedSessions = course.userRelevantSessions?.filter(session => !isAttendedSession(session) && isPassed(session));
    if (userRelevantNotAttendedSessions?.length > 0) {
      return true;
    }
  }

  return false;
};

export const isMissedCourse = course => {
  return course
    ? (isVirtualClassroom(course) && getSessionStatus(course) === courseStatesConst.MISSED) ||
    isMissedLivestream(course)
    : false;
};
export const isMissedLivestream = course => {
  return (
    course && isLivestreamCourse(course) && course.courseStates?.includes(courseStatesConst.MISSED)
  );
};

export const isBookedCourse = course => {
  if (course?.userRelevantSessions && isVirtualClassroom(course) && !isMissedCourse(course)) {
    const bookedSessions = course.userRelevantSessions.filter(session =>
      isScheduledSession(session)
    );
    if (bookedSessions?.length > 0) {
      return true;
    }
  }

  return false;
};

export const getSessionByCoursesMap = (coursesMap, courseId, sessionId) => {
  if (!coursesMap || !courseId || !sessionId || !coursesMap[courseId]) {
    return null;
  }

  return getSessionByCourse(coursesMap[courseId], sessionId);
};

export const getSessionByCourse = (course, sessionId) => {
  if (!course || !sessionId || !course.courseSessions) {
    return null;
  }

  for (const session of course.courseSessions) {
    if (session.id === sessionId) {
      return session;
    }
  }

  return null;
};

export const isL1Followed = (l1, userProfile) => {
  if (!l1 || !userProfile) {
    return false;
  }

  return userProfile.followedChannels?.includes(l1);
};

// export const selectThemeColor = (state) =>
//   state.utils.gradientStyle?.mainColor?.color;

export const getCourseCompatibility = course => {
  let affinity = 10;
  if (course.affinity < 0.1) {
    return 10;
  }
  if (course.affinity && !isNaN(course.affinity)) {
    affinity = Math.round(course.affinity * 100);
  }
  return affinity;
};

export const scrollCourseDetailToTop = (behavior = 'auto', query = '.modal__course-overview') => {
  //behavior can be auto or smooth

  //scroll to modal top
  //for desktop
  let element = document.querySelector(query);
  if (element) {
    element.scrollTo({ top: 0, left: 0, behavior: behavior });
  }
  //for mobile
  let elementMobile = document.querySelector(query);
  if (elementMobile) {
    elementMobile.scrollTo({ top: 0, left: 0, behavior: behavior });
  }
};

export const scrollToLeaderboard = () => {
  const sessions = document.getElementById('leaderboard-container');
  const wrapper = document.querySelector('html');
  if (sessions && wrapper) {
    // let count = sessions.offsetTop - wrapper.scrollTop - 55;
    const scrollToY = sessions.offsetTop - 60;

    //for desktop
    wrapper.scrollTo(0, scrollToY);
    //for mobile
    window.scrollTo(0, scrollToY);
  }
};

export const addSubtitleParam = (url, language) => {
  if (!url) {
    return '';
  }

  if (!language) {
    return url;
  }

  if (language === 'zh_cn') {
    language = 'zh';
  }
  if (language.includes('_')) {
    language = language.replace('_', '-');
  }

  //cc_lang_pref = 0 to disable subtitle (not used for now)
  if (url.includes('?')) {
    return url + '&cc_lang_pref=' + language;
  } else {
    return url + '?cc_lang_pref=' + language;
  }
};

export const getSubtitlesCodes = course => {
  if (!course || !course.tags) {
    return [];
  }

  return course.tags.filter(a => a.includes(TAGS.subtitle)).map(a => a.replace(TAGS.subtitle, ''));
};

export const getDefaultSubtitle = (course, languages) => {
  if (!course || !languages) {
    return null;
  }

  const subtitlesCodes = getSubtitlesCodes(course);
  if (subtitlesCodes.findIndex(a => a === course.language) > -1) {
    for (let language of languages) {
      if (language.code === course.language) {
        return language;
      }
    }
  }

  return null;
};

export const getComingSoonLanguage = course => {
  if (!course || !course.tags) {
    return [];
  }

  return course.tags
    .filter(a => a.includes(TAGS.comingSoon))
    .map(a => a.replace(TAGS.comingSoon, ''));
};

export const isExternalUser = userProfile => {
  return userProfile?.senderSystem === SENDER_SYSTEM.EXTERNAL;
};
export const isMYLUser = userProfile => {
  return userProfile?.senderSystem === SENDER_SYSTEM.LUXOTTICA;
};
export const isEssilorUser = userProfile => {
  return userProfile?.senderSystem === SENDER_SYSTEM.ESSILOR;
};
export const isBackofficeUser = userProfile => {
  return userProfile?.senderSystem === SENDER_SYSTEM.BACKOFFICE;
};
export const isMainUser = userProfile => {
  return userProfile?.userType === USER_TYPE.MAINUSER;
};
export const isSubUser = userProfile => {
  return userProfile?.userType === USER_TYPE.SUBUSER;
};

export const canSeeLeadeboard = (userProfile, ignoreDisableButton = false) => {
  return (
    userProfile &&
    (userProfile.preferredLeaderBoardPresence !== LEADERBOARD_PRESENCE.DISABLED ||
      ignoreDisableButton) &&
    !isExternalUser(userProfile)
  );
};

export const normalizeUserProfile = userProfile => {
  if (!userProfile) {
    return null;
  }

  let newUser = { ...userProfile };
  newUser.firstName = toSurnameCase(newUser.firstName);
  newUser.lastName = toSurnameCase(newUser.lastName);
  newUser.email = newUser.email ? newUser.email.toLowerCase() : newUser.email;
  return newUser;
};

export const splitArrayIntoChunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map(begin => array.slice(begin, begin + chunk_size));

export const isCourseToBeRated = course => {
  return course && !course.userRating && !isCourse0(course) && !isCourseMaster(course);
};

export const canShowRating = (course, rating = 0) => {
  return (course?.rating > 0 || rating > 0) && !isCourse0(course) && !isCourseMaster(course);
};

export const isLPToBeRated = (course, coursesMap) => {
  return (
    course &&
    isLearningPath(course) &&
    (isCompletedCourse(course) || areLPAllLessonsCompleted(course, coursesMap)) &&
    isCourseToBeRated(course)
  );
};

export const areLPAllLessonsCompleted = (lp, coursesMap) => {
  if (!lp || !isLearningPath(lp) || !coursesMap) {
    return false;
  }

  //check if all lp lessons are completed
  const lessons = getLpCourses(lp, coursesMap);
  for (const lesson of lessons) {
    if (!isCompletedCourse(lesson)) {
      return false;
    }
  }

  return true;
};

export const isCourse0 = course => {
  return course && (course.courseId?.includes('co0') || course.courseIdMaster?.includes('co0'));
};

export const getVideoPreviewUrl = course => {
  if (!course) {
    return null;
  }

  if (isTagPresent(course.tags, TAGS.hasVideoPreviewTrue)) {
    if (isQualityEnv()) {
      return (
        'https://media.leonardo.essilorluxottica.com/qu-videos/' + course.courseIdMaster + '.mp4'
      );
    } else {
      return 'https://media.leonardo.essilorluxottica.com/videos/' + course.courseIdMaster + '.mp4';
    }
  } else {
    return null;
  }
};

export const getVideoPreviewUrlCrosscast = (courseId, userProfile, volumeOn) => {
  if (!courseId) {
    return null;
  }
  if(volumeOn){
    return ("https://luxottica.player.crosscast-system.com/video/" + courseId + "-mp4?controls=0&ap=1&mute=0");
  }
  else{
    return ("https://luxottica.player.crosscast-system.com/video/" + courseId + "-mp4?controls=0&ap=1&mute=1");
  }
};



export const getVTTProgramUrl = course => {
  if (!course) {
    return null;
  }
  if (isTagPresent(course.tags, TAGS.hasSubtitlePreview)) {
    if (isQualityEnv()) {
      return (
        'https://media.leonardo.essilorluxottica.com/qu-videos/' + course.courseIdMaster + '.vtt'
      );
    } else {
      return 'https://media.leonardo.essilorluxottica.com/videos/' + course.courseIdMaster + '.vtt';
    }
  } else {
    return null;
  }
};

export const supportsTextSecurity = CSS.supports(
  `(text-security: disc) or (-webkit-text-security: disc) or (-moz-text-security: disc)`
);

export const isSafari = () => {
  var ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') != -1 && ua.indexOf('chrome') <= -1;
};

export const get15MinutesLeftFromNow = date => {
  const now = new Date().getTime();
  const diffMs = now - date.getTime(); // milliseconds between now & date
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  const is15MinutesLeft = (diffMins <= 15) & (diffMins >= 0);
  return { is15MinutesLeft, diffMs };
};

export const getTimeLiveStream = course => {
  const sessionDates = course?.courseSessions?.[0]?.sessionDates?.[0];
  const timeStart = sessionDates?.timeStart;
  const timeFinish = sessionDates?.timeFinish;
  return { timeStart, timeFinish };
};

export const isNowBetweenDates = (start, end) => {
  return moment().isBetween(start, end, undefined, '[]');
};
export const isLocalhost = () => {
  return location.hostname === 'localhost';
};
export const isQualityEnv = () => {
  return location.hostname && location.hostname.includes('qu');
};
export const isProdEnv = () => {
  return (
    location.hostname &&
    (location.hostname.includes('pr') ||
      location.hostname === 'university.luxottica.com' ||
      location.hostname === 'leonardo.essilorluxottica.com')
  );
};

export const isPP1Env = () => {
  return location.hostname && location.hostname.includes('pp1');
};


export const isPP2Env = () => {
  return location.hostname && location.hostname.includes('pp2');
};


export const isCurrentPublicPage = () => {
  const pathname = window.location.pathname;
  for (let publicUrl of Object.values(PUBLIC_URLS)) {
    if (publicUrl.URL === pathname) {
      return true;
    }
  }
  return false;
};

export const getSessionFirstTrainer = session => {
  if (!session) {
    return null;
  }

  if (session.trainers?.length > 0) {
    return session.trainers[0];
  }

  if (session.trainer) {
    let trainer = new Trainer();
    trainer.firstName = session.trainer;
    trainer.username = session.trainer;
    return trainer;
  }

  return null;
};

export const getTrainerFullNameByUsername = (trainerUsername, coursesMap) => {
  if (!trainerUsername || !coursesMap) {
    return '';
  }

  let trainerName = trainerUsername;
  Object.values(coursesMap).forEach(course => {
    course.courseSessions?.forEach(session => {
      if (session.trainer === trainerUsername) {
        trainerName = session.trainer;
        return;
      }

      const trainers = session.trainers?.filter(a => a.username === trainerUsername);
      if (trainers?.length > 0) {
        trainerName = getTrainerFullName(trainers[0]);
        return;
      }
    });

    const trainersLiveStream = course?.liveInfo?.[0]?.trainers?.filter(
      a => a.username === trainerUsername
    );
    if (trainersLiveStream?.length > 0) {
      trainerName = getTrainerFullName(trainersLiveStream[0]);
      return;
    }
  });

  return trainerName;
};

export const joinSession = (course, session, toggleRatingModal) => {
  window.open(session.webExSessionLink);

  //show rating
  if (isCourseToBeRated(course)) {
    toggleRatingModal(true, course);
  }
};

const getNumberCompletedActivities = content => {
  let counter = 0;

  content?.map(c => {
    if (c.completionState === activityState.COMPLETE) counter++;
  });

  return counter;
};

export const isActivityBlocked = (activity, section = null) =>
  !activity?.available &&
  section?.content?.length !== getNumberCompletedActivities(section?.content);

export const isActivityCompleted = activity =>
  activity &&
  (activity.completionState === activityState.COMPLETE ||
    activity.completionState === activityState.PASS);

//get next activity that can be accessed
export const getNextActivity = (
  course,
  noFallback = false,
  getCompleted = false,
  moduleId = ''
) => {
  if (!course || !course.courseDetails) {
    return null;
  }
  for (let section of course.courseDetails
    .filter(a => a.sectionName !== 'completed')
    .sort((a, b) => a.sectionPosition - b.sectionPosition)) {
    for (let activity of section.content?.sort((a, b) => a.activityPosition - b.activityPosition)) {
      if (
        (!isActivityCompleted(activity) || getCompleted) &&
        !isActivityBlocked(activity, section) &&
        activity.moduleId !== moduleId
      ) {
        return activity;
      }
    }
  }

  if (!noFallback) {
    //if all activities are completed
    return getFirstActivity(course);
  }
};

export const getFirstActivity = course => {
  if (!course) {
    return null;
  }
  let firstActivity = null;

  const courseDetails = course.courseDetails.sort((a, b) => a.sectionPosition - b.sectionPosition);

  if (courseDetails?.length > 0) {
    const content = courseDetails[0].content.sort(
      (a, b) => a.activityPosition - b.activityPosition
    );

    if (content?.length > 0) {
      return content[0];
    }
  }

  return firstActivity;
};

export const getCoursesWithinLP = (lp, coursesMap, getOnlyNotOptional = false) => {
  if (!lp || !coursesMap || !isLearningPath(lp)) {
    return [];
  }

  let lpCourses = lp.learningPath
    ?.filter(course => (getOnlyNotOptional ? isLessonNotOptionalForLP(course) : true))
    .sort((a, b) => a.sequence - b.sequence)
    .map(course => getCourseWithinLP(course, lp, coursesMap))
    .filter(course => isCourseVisible(course, false, false));

  if (lpCourses?.length > 0) {
    lpCourses.forEach(course => {
      course = addIsNotYetAvailableAttribute(course, lp, coursesMap);
    });
  }

  return lpCourses || [];
};

export const getNextLessonWithinLP = (lp, coursesMap, userProfile) => {
  if (!lp || !coursesMap || !isLearningPath(lp)) {
    return null;
  }

  //get all courses within the LP
  let lpCourses = getCoursesWithinLP(lp, coursesMap);

  //get the next course to be completed
  for (let lpCourse of lpCourses) {
    if (
      !lpCourse ||
      lpCourse.isNotYetAvailable ||
      isCompletedCourse(lpCourse) ||
      isMissedCourse(lpCourse) ||
      (isBookedCourse(lpCourse) && !lpCourse.showJoin) ||
      (isLivestreamFinished(lpCourse, userProfile) &&
        getLivestreamRecording(lpCourse, userProfile)?.length === 0)
    ) {
      continue;
    }

    return lpCourse;
  }

  //get the first course with an action
  //do not consider booked VC to not cancel the booking
  for (let lpCourse of lpCourses) {
    if (
      !lpCourse ||
      lpCourse.isNotYetAvailable ||
      isMissedCourse(lpCourse) ||
      (isBookedCourse(lpCourse) && !lpCourse.showJoin) ||
      (isLivestreamFinished(lpCourse, userProfile) &&
        getLivestreamRecording(lpCourse, userProfile) === 0)
    ) {
      continue;
    }

    return lpCourse;
  }

  //if the unique lesson is a booked VC --> return that VC
  if (
    lpCourses.length === 1 &&
    isBookedCourse(lpCourses[0]) &&
    !lpCourses[0].isNotYetAvailable &&
    !isMissedCourse(lpCourses[0])
  ) {
    return lpCourses[0];
  }

  return null;
};

export const scrollToSessions = (useScrollIntoView = false) => {
  if (useScrollIntoView) {
    const sessionSection = document.getElementById('session-section');
    if (sessionSection) {
      sessionSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        // inline: "start",
      });
      console.log('scrollIntoView', sessionSection);
      return true;
    }
    return false;
  } else {
    const sessions = document.getElementById('sessions');
    const wrapper = document.querySelector('.modal.modal__course-overview');
    if (sessions && wrapper) {
      // sessions.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      let count = sessions.offsetTop - wrapper.scrollTop - 50; // xx = any extra distance from top ex. 60
      wrapper.scrollBy({ top: count, left: 0, behavior: 'smooth' });
      return true;
    }
    return false;
  }
};



export const getUserFromTrainer = trainer => {
  return {
    ...trainer,
    firstName: trainer?.firstName,
    lastName: trainer?.lastName,
    fullName: getTrainerFullName(trainer),
  };
};

export const userRoleIsVS = role => {
  return role === 'VS';
};

export const userRoleIsKeplr = role => {
  return role === 'Keplr';
};

export const getRatingSurveyType = course => {
  if (!course) {
    return SURVEY_TYPE.RATING;
  }

  if (isLearningPath(course)) {
    return SURVEY_TYPE.RATING_LP;
  } else if (isLivestreamCourse(course)) {
    return SURVEY_TYPE.RATING_LIVESTREAM;
  } else {
    return SURVEY_TYPE.RATING;
  }
};

export const getReviewSurveyType = course => {
  if (!course) {
    return SURVEY_TYPE.REVIEW;
  }

  if (isLearningPath(course)) {
    return SURVEY_TYPE.REVIEW_LP;
  } else if (isLivestreamCourse(course)) {
    return SURVEY_TYPE.REVIEW_LIVESTREAM;
  } else {
    return SURVEY_TYPE.REVIEW;
  }
};

export const getTrainingPillId = trainingPill => {
  return trainingPill?.extendedFields?.ModelString;
};

export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
};

export const getRgbString = hex => {
  const rgb = hexToRgb(hex);
  return rgb && Object.values(rgb).join(', ');
};

export const getBrandChildren = (brand, brandsList) => {
  if (!brand || !brandsList) {
    return [];
  }

  brand = getBrandIdFromL1Id(brand);

  let brandChildren = brandsList?.map?.[brand]?.children;
  brandChildren = brandChildren?.length > 0 ? brandChildren : [brand];

  return brandChildren;
};

export const containsThreeConsecutiveLetters = (str, substr) => {
  str = typeof str === 'string' ? str.toLowerCase() : '';
  substr = typeof substr === 'string' ? substr.toLowerCase() : '';
  return (
    str.length > 2 &&
    substr.length > 2 &&
    str
      .split('')
      .some((_, index) => index + 3 <= str.length && substr.includes(str.slice(index, index + 3)))
  );
};

export const handleOnEnterKeyPress = (event, action, shouldPreventDefault, stopPropagation) => {
  if (event.key === 'Enter') {
    action?.();
    if (!action) {
      console.warn('Invalid action');
    }
    if (stopPropagation) {
      event.stopPropagation();
    }
    if (shouldPreventDefault) {
      event.preventDefault();
    }
  }
};

const getCurrencySymbol = (locale, currency) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

// convert number to currency
export const formatCurrency = (value, currency = 'USD', language = 'en', addSpace = true, currencyFormat = true) => {
  if (!language) {
    language = 'en';
  }
  //change pt_br to pt-br to have a correct locale
  language = language.replace('_', '-');

  if (typeof +value != 'number') {
    return '';
  }

  let optionsFormat = {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
  };

  try {
    //check if option currencyDisplay: 'narrowSymbol' throws an exception
    new Intl.NumberFormat('en', optionsFormat);
  } catch (e) {
    //if the browser doesn't support currencyDisplay: 'narrowSymbol' --> change currencyDisplay to 'symbol'
    optionsFormat.currencyDisplay = 'symbol';
  }

  const currencySymbol =
    new Intl.NumberFormat(language, optionsFormat)
      ?.formatToParts?.(1)
      ?.find(part => part.type === 'currency')?.value || getCurrencySymbol(language, currency);

  const valueWithCurrency = new Intl.NumberFormat(language, optionsFormat).format(+value);

  const valueWithoutCurrency = valueWithCurrency?.replace(currencySymbol, '').trim();

  return currencySymbol + (addSpace ? ' ' : '') + (currencyFormat ? valueWithoutCurrency : value);
};

export const willCourseBeRemovedSoon = (course, userProfile, variant = '1m') => {
  if (!course?.endDate || !userProfile) {
    return false;
  }

  const localEndDate = getLocalDateFromString(course.endDate, userProfile.timezone);

  let amount = 1;
  let unit = 'months';

  if (variant === '7d') {
    amount = 7;
    unit = 'days';
  }
  if (variant === '24h') {
    amount = 24;
    unit = 'hours';
  }

  const localEndDateLessSomething = moment(localEndDate.getTime()).subtract(amount, unit);

  //true if course endDate is within 1 month (or 7 days according to variant)
  return new Date().getTime() > localEndDateLessSomething.toDate().getTime();
};

export const getExpirationDateFilterKey = course => {
  if (!course || (!isPriceable(course) && course.expectedCompletion) || !course.endDate) {
    return '';
  }

  const today = new Date();

  const date = course.endDate;
  const dayDifference = moment(new Date(date)).diff(today, 'days');
  const monthDifference = moment(new Date(date)).diff(today, 'months');
  if (typeof dayDifference === 'number' && typeof monthDifference === 'number') {
    if (dayDifference <= 7) {
      return EXPIRATION_KEYS.IN7DAYS;
    }

    if (dayDifference > 7 && monthDifference < 1) {
      return EXPIRATION_KEYS.IN1MONTH;
    }

    if (monthDifference >= 1 && monthDifference < 3) {
      return EXPIRATION_KEYS.IN3MONTHS;
    }

    if (monthDifference >= 3 && monthDifference <= 12) {
      return EXPIRATION_KEYS.IN12MONTHS;
    }
  }

  return '';
};

export const getParentLPPurchasable = (courseId, coursesMap) => {
  let parentLPTemp = [];

  if (courseId && coursesMap && getParentCourse(courseId, coursesMap)) {
    for (let lpLight of getParentCourse(courseId, coursesMap).parentLP) {
      let lp = getParentCourse(lpLight.courseId, coursesMap);
      if (lp && isPurchasable(lp, true)) {
        parentLPTemp.push(lp);
      }
    }
  }

  return parentLPTemp;
};

export const mergeRefs =
  (...refs) =>
    singleRef => {
      refs = refs.filter(a => !!a);

      refs.forEach(resolvableRef => {
        if (typeof resolvableRef === 'function') {
          resolvableRef(singleRef);
        } else {
          resolvableRef.current = singleRef;
        }
      });
    };

export const loadScript = (url, callback) => {
  const isAlreadyLoaded = document.querySelector('script[src="' + url + '"]');
  if (isAlreadyLoaded) {
    callback();
    return;
  }

  let script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

export const printProductQuantity = quantity => {
  if (typeof quantity === 'string' && quantity?.endsWith('.0')) {
    return quantity.slice(0, quantity.length - 2);
  } else {
    return quantity;
  }
};

export const getPriceWithAdjustement = item => {
  if (!item) {
    return '';
  }

  let newPrice = +item.orderItemPrice;

  //apply promos
  if (item.adjustment?.length > 0) {
    item.adjustment.forEach(promo => {
      newPrice = newPrice + +promo.amount;
    });
  }

  return newPrice.toString();
};

export const getDaysDifference = (targetDate, initialDate = new Date()) => {
  const daysDifference = moment(initialDate).diff(new Date(targetDate), 'days');
  return daysDifference;
};

export const getDaysAgo = (days, initialDate) => {
  return (initialDate ? moment(initialDate) : moment()).subtract(days, 'd').toDate();
};
export const getDaysInTheFuture = (days, initialDate = null) => {
  return (initialDate ? moment(initialDate) : moment()).add(days, 'd').toDate();
};
export const isPoweredByEcpu = course => {
  if (!course) {
    return false;
  }
  return course.tags?.includes(TAGS.ecpu);
};

export const isRecognizedByABO = course => {
  if (!course) {
    return false;
  }
  return course.tags?.includes(TAGS.abo);
};

export const getVisibleFollowedBrands = (followedBrands, coursesMap) => {
  if (!followedBrands || !coursesMap) {
    return [];
  }

  //get all visible courses
  const courses = coursesMap ? Object.values(coursesMap)?.filter(a => isCourseVisible(a)) : [];

  //get followed brands with at least one course associated to that brand
  return followedBrands?.filter(a => {
    const filteredCourses = filterByBrand(courses, a);
    if (filteredCourses?.length > 0) {
      return true;
    }

    return false;
  });
};

export const getVisibleFollowedChannels = (followedChannels, l1Map) => {
  if (!followedChannels || !l1Map) {
    return [];
  }

  //get followed channels with at least one course associated to that channel
  return followedChannels?.filter(a => {
    const channelLabel = getChannelLabel(a, l1Map);
    //if the user has not at least one lesson/course with the associated channel --> do not show the channel
    if (!channelLabel) {
      return false;
    }

    return true;
  });
};
export const hasCoursePartialChildren = (course, childId) => {
  if (!course || !childId) {
    return false;
  }

  //if the course has a child without name --> it is partial
  if (course.childCourses?.length > 0) {
    if (childId || language) {
      //check for a specific child
      const child = course.childCourses.find(a => a.childId === childId);
      return !child?.courseOverviewFile;
    } else {
      for (let child of course.childCourses) {
        if (child.courseOverviewFile === undefined) {
          return true;
        }
      }
    }
  }

  return false;
};

export const getKeysFromEnriched = enrichedArray => {
  return enrichedArray?.map(a => a.key);
};

export const getChildCourseByLanguage = (course, language) => {
  if (course?.childCourses?.length > 0 && language) {
    return course.childCourses.find(a => a.language === language);
  }

  return null;
};

export const isPathAbsolute = path => {
  return /^(?:\/|[a-z]+:\/\/)/.test(path);
};

export const getFirstDayPastMonths = months => {
  var d = new Date();
  d.setMonth(d.getMonth() - months);
  d.setDate(1);
  return d;
};

export const printDayOfWeek = (day, lang) => {
  if (!lang) {
    return '';
  }

  switch (day) {
    case 0:
      return lang.SUNDAY;
    case 1:
      return lang.MONDAY;
    case 2:
      return lang.TUESDAY;
    case 3:
      return lang.WEDNESDAY;
    case 4:
      return lang.THURSDAY;
    case 5:
      return lang.FRIDAY;
    case 6:
      return lang.SATURDAY;
    default:
      return '';
  }
};

export const isToday = date => {
  if (!date) {
    return false;
  }

  const splittedDate = date.split('/')
  const year = splittedDate[0]
  const month = splittedDate[1];
  const day = splittedDate[2]?.slice(0,2);

  const today = new Date();
  return today.getFullYear() == +year && today.getMonth() + 1 == +month && today.getDate() == +day;
};

export const getTodayMidnight = (isNight = false) => {
  const today = new Date();
  return isNight ? new Date(today.setHours(23, 59, 59, 0)) : new Date(today.setHours(0, 0, 0, 0));
};

export const getCurrentDateTime = () => {
  return new Date();
}

export const getValueFromJWT = fields => {
  if (!fields || fields.length < 1) {
    return '';
  }

  const jwtCookie = getCookie(COOKIE.USER_DETAILS);

  if (jwtCookie) {
    let jwt = jwtCookie.split('.')?.[1] || '';
    let result = null;

    try {
      result = JSON.parse(atob(jwt) || {});
      // console.log('jwt', jwt)
    } catch (e) {
      // console.error('Error during jwt atob', jwtCookie);
      console.error('getValueFromJWT - atob', e);
    }

    if (!result) {
      try {
        let jwtDecoded = decodeBase64(jwt);
        jwtDecoded = jwtDecoded?.replace(/\"firstName\":\"[^\"]*\",/, '');
        jwtDecoded = jwtDecoded?.replace(/\"lastName\":\"[^\"]*\",/, '');

        result = JSON.parse(jwtDecoded || {});
      } catch (e) {
        console.error('getValueFromJWT - decodeBase64', e);
      }
    }

    if (result) {
      // console.log('jwtDecoded', result);
      for (let field of fields) {
        if (!result) {
          return '';
        }
        result = cloneDeep(result[field]);
      }
      return result;
    }
  }

  return '';
};

const decodeBase64 = s => {
  var e = {},
    i,
    b = 0,
    c,
    x,
    l = 0,
    a,
    r = '',
    w = String.fromCharCode,
    L = s.length;
  var A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  for (i = 0; i < 64; i++) {
    e[A.charAt(i)] = i;
  }
  for (x = 0; x < L; x++) {
    c = e[s.charAt(x)];
    b = (b << 6) + c;
    l += 6;
    while (l >= 8) {
      ((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a));
    }
  }
  return r;
};

export const handleCourseDetailQueryParam = (course = null, useReplace = false) => {
  let newUrl = window.location.href;
  let oldUrl = window.location.href;
  console.log('COURRENTCOURSE', course.courseId);
  let stringToCheck = window.location.search;
  let index = stringToCheck.indexOf('&');
  stringToCheck = stringToCheck.substring(1, index);
  console.log('FIND', stringToCheck, index);

  if (
    newUrl.includes(queryParams.COURSE_DETAIL + '=' + course.courseId) ||
    (course.courseId == null && newUrl.includes(stringToCheck) && window.location.search != '')
  ) {
    console.log('COURRENTCOURSE', newUrl);
    return;
  }
  //remove old parameter
  if (newUrl) {
    let changeUrl = false;

    if (newUrl.includes(queryParams.COURSE_DETAIL)) {
      changeUrl = true;
      const replaceRegex1 = queryParams.COURSE_DETAIL + '=[^&#]*(#.*)?$';
      const replaceRegex2 = queryParams.COURSE_DETAIL + '=[^&]*&';
      const replaceRegex3 = queryParams.COURSE_DETAIL_TITLE + '=[^&#]*(#.*)?$';
      const replaceRegex4 = queryParams.COURSE_DETAIL_TITLE + '=[^&]*&';
      newUrl = newUrl.replace(new RegExp(replaceRegex1), '');
      newUrl = newUrl.replace(new RegExp(replaceRegex2), '');
      newUrl = newUrl.replace(new RegExp(replaceRegex3), '');
      newUrl = newUrl.replace(new RegExp(replaceRegex4), '');
      if (newUrl.endsWith('?')) {
        newUrl = newUrl.replace('?', '');
      }
      if (newUrl.endsWith('&')) {
        newUrl = newUrl.replace('&', '');
      }
      console.log('newUrl', newUrl);
    }

    if (course) {
      //include parameter
      changeUrl = true;
      if (newUrl.endsWith('?')) {
        newUrl = newUrl.replace('?', '');
      }
      newUrl += newUrl.includes('?') ? '&' : '?';
      newUrl += queryParams.COURSE_DETAIL + '=' + course?.courseId;
      newUrl += '&' + queryParams.COURSE_DETAIL_TITLE + '=' + course?.courseFullName.toLowerCase();
    }

    if (changeUrl) {
      newUrl = newUrl.replaceAll(' ', '_');
      if (useReplace) {
        window.history.replaceState({ path: newUrl }, '', newUrl);
      } else {
        window.history.pushState({ path: newUrl, prevUrl: oldUrl }, '', newUrl);
      }
    }
  }
};
export const getL1Element = (searchL1, l1Map, L1Images, lang) => {
  if (searchL1?.includes('university-')) {
    searchL1 = searchL1.replace('university-', '');
  }
  let numFallbackImage = 0;
  let l1 = new L1Element();
  l1.type = getL1Type(searchL1);
  l1.value = searchL1;

  let l1Label = getChannelLabel(searchL1, l1Map);
  l1Label = l1Label ? l1Label : lang[l1.value];
  l1Label = l1Label ? l1Label : '';
  l1.label = l1Label;

  l1.image = L1Images[searchL1]?.img;
  l1.fallbackImage = getFallbackImage((numFallbackImage % 4) + 1);
  l1.imageContentId = L1Images[searchL1]?.imageContentId;
  l1.alt = L1Images[searchL1]?.alt;
  l1.labelMedium = lang.CARD_EXPLORE_CHANNEL?.replace('{l1}', l1.label);
  l1.showL1Label = L1Images[searchL1]?.showL1Label;

  return l1;
};

export const isUsingDefaultScrollbar = () => {
  //Safari 14 or less returns true
  return (
    window.CSS?.supports('-webkit-touch-callout', 'none') &&
    !window.CSS?.supports('translate', 'none')
  );
};

export const sanitizeCourseIdV2 = course => {
  if (!course) {
    return course;
  }

  if (!course.courseIdMaster && course.courseId) {
    course.courseIdMaster = course.courseId;
  }
  if (!course.courseId && course.courseIdMaster) {
    course.courseId = course.courseIdMaster;
  }

  return course;
};

export const getFallbackImage = (numCover = 1) => {
  return FALLBACK_IMAGE_COVER_URL + 'cover_0' + numCover.toString() + '.jpg';
};

export const convertArrayToMap = (array, keyField) => {
  if (!array || !Array.isArray(array) || !keyField) {
    return {};
  }

  let map = {};
  array.forEach(a => {
    const key = a[keyField];
    if (key) {
      map[key] = a;
    }
  });

  return map;
};

export const getActivityById = (courseDetails, moduleId) => {
  if (!courseDetails || !moduleId) {
    return null;
  }

  let activity = null;

  courseDetails.forEach(detail => {
    detail.content?.forEach(a => {
      if (a.moduleId === moduleId) {
        activity = a;
      }
    });
  });

  return activity;
};

//check if all course activities are completed
export const areAllActivitiesCompleted = courseDetails => {
  if (!courseDetails) {
    return false;
  }
  courseDetails = courseDetails.filter(a => a.sectionName !== 'completed');

  let completed = true;

  courseDetails.forEach(detail => {
    detail.content?.forEach(activity => {
      if (!isActivityCompleted(activity)) {
        completed = false;
      }
    });
  });

  return completed;
};

//get the following activity (locked or not) according to activity order
export const getFollowingActivity = (courseDetails, moduleId) => {
  if (!courseDetails || !moduleId) {
    return null;
  }
  courseDetails = courseDetails.filter(a => a.sectionName !== 'completed');

  let activity = null;

  let activities = [];

  courseDetails.forEach(detail => {
    //sort and add the section activities to the global list
    detail.content
      ?.sort((a, b) => a.activityPosition - b.activityPosition)
      .forEach(activity => {
        activities.push(activity);
      });
  });

  //find activity index
  const index = activities.findIndex(a => a.moduleId === moduleId);
  if (index >= 0) {
    //get next activity
    activity = activities[index + 1];
  }

  return activity;
};

export const getUnlockedActivities = courseDetails => {
  const activities = [];

  courseDetails.forEach(detail => {
    //sort and add the section activities to the global list
    detail.content
      ?.sort((a, b) => a.activityPosition - b.activityPosition)
      .forEach(activity => {
        if (!isActivityBlocked(activity, detail)) {
          activities.push(activity);
        }
      });
  });

  return activities;
};

export const getFollowingActivityFromActivities = (activities, moduleId) => {
  let activity = null;
  //find activity index
  const index = activities.findIndex(a => a.moduleId === moduleId);
  if (index >= 0) {
    //get next activity
    activity = activities[index + 1];
  }

  return activity;
};

export const getScrollerElement = () => {
  let wrapper = document.querySelector('html');
  return wrapper;
};

export const getScrollerElementInModal = modalClass => {
  let wrapper = null;
  wrapper = document.querySelector('.' + modalClass);

  return wrapper;
};

export const isInThePast = d => {
  const date = new Date(d);
  const today = new Date();
  return date < today;
};

export const addNDays = days => {
  var date = new Date(Date.now());
  date.setDate(date.getDate() + days);
  return date;
};

export const removeURLParameter = (url, parameter) => {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
};

export const goToUrl = url => {
  if (!url) {
    return;
  }

  const expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const regex = new RegExp(expression);
  if (regex.exec(url)) {
    //full url

    if (url.startsWith(window.location.origin)) {
      //partial url
      history.push(url.replace(window.location.origin, ''));
    } else {
      window.location.href = url;
    }
  } else {
    //partial url
    history.push(url);
  }
};

export const handleSeedRandomHeroBanner = _ => {
  const seed = sessionStorage.getItem('setSeedRandomHeroBanner');
  if (seed) return parseFloat(seed);

  sessionStorage.setItem('setSeedRandomHeroBanner', Math.random());
};

export const removeHashFromUrl = _ => {
  const uri = window.location.toString();

  if (uri.indexOf("#") > 0) {
    var clean_uri = uri.substring(0,
      uri.indexOf("#"));

    window.history.replaceState({}, document.title, clean_uri);
  }
}

export const getPDFLogoUrl = (logoUrlPng, isUserCompleted, isFullUserCompleted) => {
  if (isUserCompleted || isFullUserCompleted) {
    if (!logoUrlPng) {
      return '../../images/logo-app/leonardo.jpg'; //fallback on Leonardo logo
    } else {
      return logoUrlPng;
    }
  }
};

export const getHeaderLogoUrl = (logoUrlSvg, isUserCompleted, isFullUserCompleted, isAuthenticated) => {
  if (isAuthenticated && (isUserCompleted || isFullUserCompleted)) {
    if (!logoUrlSvg) {
      return <LeonardoIcon />; //fallback on Leonardo logo
    } else {
      return <LazySVG src={logoUrlSvg} alt="Header Logo" />;
    }
  } else if (!isAuthenticated) {
    return <LeonardoIcon />;
  }
};

export const showPrimaryOrSecondaryBtn = (shouldShowDownloadButton, userEl360, courseEl360 ) => {
  if (shouldShowDownloadButton) {
    if (userEl360 && courseEl360) {
      return 'primary'
    } else return 'secondary'
  } else return ''
};

export const areAllOnboardingRMCoursesCompleted = (onboardingRelatedMandatoryCourses) => {
  return (
    onboardingRelatedMandatoryCourses &&
    onboardingRelatedMandatoryCourses.every(course => isCompletedCourse(course))
  );
};