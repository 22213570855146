import React, { useEffect, useRef, useState } from 'react';
import CardCover from './CardCover';
import { getVideoPreviewUrlCrosscast, handleOnEnterKeyPress, isCompletedCourse, isLearningPath, printDate, scrollCourseDetailToTop } from '@utility/Api';
import { useSelector } from '@hooks/redux';
import usePreview from '@hooks/usePreview';
import { CardDisplayProps } from './CardDisplayUtils';
import WishlistIcon from '@components/WishlistIcon';
import AnimationExpand from '@components/animations/AnimationExpand';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { BadgeCourseClass } from '@utility/CourseUtility';
import RibbonCourseEvolution from '@components/UI/RibbonCourseEvolution';
import AnimationOpacity from '@components/animations/AnimationOpacity';
import clsx from '@utility/clsx';
import VolumeIcon from '@images/svg-icons/volume.svg';
import NoVolumeIcon from '@images/svg-icons/no-volume.svg';
import LockIconSmall from '@images/svg-icons/lock-icon-small.svg';
import ButtonV2 from '@components/UI/ButtonV2';
import { cmMetaData } from '@utility/previewUtils';
import { UserProfile } from '@model/User';
import { getBlockingPrograms, isProgramBlocked } from '@utility/EducationalPathsUtility';
import { CoursesMap } from '@model/CoursesClass';
import { USER_URLS } from '@components/link-utils';

type CardBigEvolutionProps = CardDisplayProps & {
  tag: BadgeCourseClass;
  labelEl360?: string;
  priceEl360?: string; //fullPrice
  isProductCarousel?: boolean
};

const CardBigEvolution = (props: CardBigEvolutionProps) => {
  const { tag = new BadgeCourseClass() } = props;
 
  const lang = useSelector(state => state.utils.lang);
  const { isDesktop, isDesktopOrTabletLandscape } = useBreakpointBoolean();
  const ref = useRef();
  const [
    previewElement,
    {},
    {},
    muted,
    setMuted,
    {},
    {},
    {},
    previewFallback,
    onVideoPreviewError,
  ] = usePreview();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [isCloseAnimationFinished, setIsCloseAnimationFinished] = useState<boolean>(true);
  const [previewTimeout, setPreviewTimeout] = useState(null);
  const [heightCard, setHeightCard] = useState(0);
  const [videourl, setvideourl] = useState(null);
  const userProfile: UserProfile = useSelector(state => state.user.userProfile);
  const [volumeOn, setVolumeOn] = useState(false);
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const isSearchPage: Boolean = window.location?.pathname?.includes(USER_URLS?.SUBCATALOGUE_SEARCH.URL);
  useEffect(() => {
    if (props.noHoverEffect) {
      if (previewTimeout) {
        clearTimeout(previewTimeout);
      }

      setShowPreview(false);
      setShowVideoPreview(false);
    }
  }, [props.noHoverEffect]);


  useEffect(() => {
    let urlVideoCrosscast = getVideoPreviewUrlCrosscast(props.course.courseIdMaster, userProfile, false);
    setvideourl(urlVideoCrosscast);
    //console.log("URL VIDEO CROSSCAST",getVideoPreviewUrlCrosscast(props.course.courseId, userProfile, false) )
  }, [props.course, userProfile]);

  let isAProgramOnCrosscast=()=>{
  //return props?.course?.complexity?.complexity === "C8" || props.course?.complexity?.complexity === "C11"|| props.course?.complexity?.complexity === "C13";
  return isLearningPath(props.course)
}

  const showPreviewOnHover = show => {
    if (isDesktop && !props.noHoverEffect) {
      //clean previous timeout
      if (previewTimeout) {
        clearTimeout(previewTimeout);
      }

      if (show) {
        //show preview after 0.5s
        const previewTimeoutTemp = setTimeout(() => {
          setHeightCard(ref?.current?.offsetHeight || 0);
          setShowPreview(show);
          setIsCloseAnimationFinished(false);

          setTimeout(() => {
            //entering hover animation is ended
            setShowVideoPreview(show);
          }, 200);
        }, 500);
        setPreviewTimeout(previewTimeoutTemp);
      } else {
        setShowPreview(show);
        setShowVideoPreview(show);

        setTimeout(() => {
          //ending hover animation is terminated
          setIsCloseAnimationFinished(true);
        }, 200);
      }
    }
  };

  const handleClickCard = () => {
    const arrivedFromLpData = JSON.parse(localStorage.getItem('arrivedFromLpData'));
		if (arrivedFromLpData?.arrivedFromLp) {
		  localStorage.removeItem('arrivedFromLpData');
		}
    props.openCourseModal();
  };

  const handleKeyDown = event => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };

  const conditionShowPreview = isDesktop && showPreview && !props.noHoverEffect;

  const handleClickIframe = e => {
    if(volumeOn === false){
      setVolumeOn(true);
      let videoWithAudio = getVideoPreviewUrlCrosscast(props?.course?.courseIdMaster, userProfile, true);
      setvideourl(videoWithAudio); //add volume
    }else{
      setVolumeOn(false)
      let videoWithAudio = getVideoPreviewUrlCrosscast(props?.course?.courseIdMaster, userProfile, false);
      setvideourl(videoWithAudio); //add volume //mute
    }
    //console.log("you changed volume!!!! ", videourl)
    e?.stopPropagation();
  };

  
  const handleClick = e => {
    setMuted(!muted);
    e?.stopPropagation();
  };


  return (
    <a
      data-id={props.courseIdMaster}
      data-element-id={props.dataElementId}
      role={'region'}
      aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace('{name}', props.courseFullName)}
      // aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace('{name}', props.courseFullName)}
      className={clsx('card-evolution small its-card hide-highlight-color', {
        'card-evolution__showing-preview': conditionShowPreview,
        'card-evolution__no-showing-preview': !conditionShowPreview,
        'close-animation-finished': isCloseAnimationFinished,
        [props.className]: props.className,
      })}
      onClick={handleClickCard}
      onKeyDown={handleKeyDown}
      tabIndex={props.tabIndex}
      onMouseOver={() => showPreviewOnHover(true)}
      onMouseLeave={() => showPreviewOnHover(false)}
      ref={ref}
      style={heightCard ? { minHeight: heightCard, height: showPreview ? '100%' : heightCard } : {}}
      {...(props.course?.contentId && cmMetaData(props.course?.contentId))}
    >
      <div className={clsx("card-evolution__container", {
        "blocked-program-container" : isProgramBlocked(props?.course, coursesMap)
      })}>
        <div className={'card-evolution__image' + (props.noHoverEffect ? ' no-hover' : '')}>
          <CardCover
            image={props.image}
            courseFullName={props.courseFullName}
            fallbackImage={props.fallbackImage}
            variant={
              isDesktopOrTabletLandscape ||
              props.className?.includes('force-desktop-in-tablet-landscape')
                ? props?.typeHarvard
                  ? 'card-evolution-desk-harvard'
                  : 'card-evolution-desk'
                : 'card-evolution-mobile'
            }
            hideShadow
            typeHarvard={props?.typeHarvard}
          />
          {!!props.valueBar && (
            <div className="card-evolution__progress">
              <AnimationExpand
                key={props.valueBar}
                initialWidth="0"
                finalWidth={props.valueBar + '%'}
                animationEase="easeOut"
              ></AnimationExpand>
            </div>
          )}
          {isAProgramOnCrosscast() && !!props.previewVideoUrl && !previewFallback && showVideoPreview && (
            <iframe width="420" height="200" className={'card-v3__background card-v3__video'} aria-label={props.courseFullName + ' video'}
            onError={onVideoPreviewError}
            src={videourl}>
            </iframe>
          )}
          {!isAProgramOnCrosscast() && !!props.previewVideoUrl && !previewFallback && showVideoPreview && (
            <video
              autoPlay
              loop
              muted={muted}
              className={'card-v3__background card-v3__video'}
              ref={previewElement}
              src={props.previewVideoUrl}
              aria-label={props.courseFullName + ' video'}
              onError={onVideoPreviewError}
            />)}

          <div className="card-evolution__icons">
            <WishlistIcon
              isWishlist={props.isWishlist}
              isLoadingWishlistLocal={props.isLoadingWishlistLocal}
              isNotYetAvailable={props.isNotYetAvailable}
              enableWishlist={props.enableWishlist}
              toggleWishlist={props.toggleWishlist}
              isStatic={props.isStatic}
              tabIndex={conditionShowPreview ? 0 : -1}
            />
            {isAProgramOnCrosscast() && showPreview && !!props.previewVideoUrl && !previewFallback && showVideoPreview && (
              <ButtonV2
                className="card-v3-overview__video-buttons card-v3-overview__volume-preview button-no-back"
                variant="icon-btn"
                handleClick={handleClickIframe}
                small
                tabIndex={conditionShowPreview ? 0 : -1}
              >
                {volumeOn ? <VolumeIcon />:<NoVolumeIcon />}
              </ButtonV2>
            )}
            {!isAProgramOnCrosscast() && showPreview && !!props.previewVideoUrl && !previewFallback && showVideoPreview && (
              <ButtonV2
                className="card-v3-overview__video-buttons card-v3-overview__volume-preview button-no-back"
                variant="icon-btn"
                handleClick={handleClick}
                small
                tabIndex={conditionShowPreview ? 0 : -1}
              >
               {muted ? <NoVolumeIcon /> : <VolumeIcon />}
              </ButtonV2>
            )}
          </div>
        </div>
        <section className="card-evolution__content" aria-label={props?.courseFullName}>
          <div className="card-evolution__type-row">
            <p className="card-evolution__type">{props.ctypeName || ''}</p>
            {props.duration && (
              <>
                <div className="card-evolution__divider" />
                <p className="card-evolution__type-duration">{props.duration}</p>
              </>
            )}
          </div>
          {props.courseFullName && <p className="card-evolution__name">{props.courseFullName}</p>}
          <div className={clsx('card-evolution__footer', {
              'blocked-program': isProgramBlocked(props?.course, coursesMap)
              })}>
            <div className='card-evolution__footer-first-row'>
              {!isProgramBlocked(props?.course, coursesMap) &&
              <>
                {(tag.label!==lang.BOOKED || (tag.label===lang.BOOKED && (window.location.href.includes('upcoming') || window.location.href.includes('profile')))) &&
                <RibbonCourseEvolution
                  course={props.course}
                  className="card-evolution__tag"
                  useBadgeLabelLive={props.useBadgeLabelLive}
                  ribbonInfo={tag}
                />}

                
        
{props.labelEl360 && isCloseAnimationFinished && (
                <span className="card-evolution__el360">{props.labelEl360}</span>
              )}
                {props.priceLabel && isCloseAnimationFinished && !props.hidePricelabel && (
                  <span className="card-evolution__price typography-price-default card-evolution__price-inline">
                    {props.priceLabel}
                  </span>
                )}

                {props.duration && !isCloseAnimationFinished && (
                  <AnimationOpacity duration={0.2}>
                    <span className="card-evolution__price typography-price-default card-evolution__price-external">
                      {props.priceLabel}
                    </span>
                  </AnimationOpacity>
                )}

                {props.course.expectedCompletion && !isCompletedCourse(props.course) && (
                  <span className="card-evolution__dueby">
                    {lang.ONBOARDINGCOURSE_DUE_BY.replace(
                      '{DATE}',
                      printDate(props.course.expectedCompletion, lang)
                    )}
                  </span>
                )}

                {props.priceEl360 && props.duration && !isCloseAnimationFinished && (
                  <AnimationOpacity duration={0.2}>
                    <span className="card-evolution__el360 card-evolution__el360-price">
                      {props.priceEl360}
                    </span>
                  </AnimationOpacity>
                )}
                </>
              }

              {isProgramBlocked(props?.course, coursesMap) && (                
                <div className='card-evolution__blocked-program'>
                  {isCloseAnimationFinished ? (
                      <span className='card-evolution__blocked-program-info'>
                        <div className='blocked-program'>
                          <LockIconSmall />
                          <p>{lang?.BLOCKED_PROGRAM_INFO_CARD}</p>
                        </div>
                      </span>
                      ) : (
                      <AnimationOpacity duration={0.2}>
                        <span className='card-evolution__blocked-program-info hover'>
                          <div className='blocked-program'>
                            <LockIconSmall />
                            {isSearchPage ? (
                              <p>{lang?.BLOCKED_PROGRAM_INFO_CARD}. {lang?.BLOCKED_PROGRAM_INFO_COURSE_NAME?.replace('{course}', getBlockingPrograms(props?.course, coursesMap)?.[0]?.courseFullName)}</p>
                            ) : (
                              <p>{lang?.BLOCKED_PROGRAM_INFO_CARD}. {lang?.BLOCKED_PROGRAM_INFO}</p>
                            )}
                          </div>
                        </span>
                      </AnimationOpacity>
                  )}
                </div>
              )}
            </div>

            {props.duration && !isCloseAnimationFinished && (
              <AnimationOpacity duration={0.2}>
                <span className="card-evolution__duration">{props.duration}</span>
              </AnimationOpacity>
            )}
          </div>
        </section>
      </div>
    </a>
  );
};

export default CardBigEvolution;
